import React from "react";
import styles from "./styles.module.css";
import Accordian from "../../../components/homeApp/accordian";
import AboutMedicinalFirst from "../../../components/aboutMedicinalFirst";
import AboutMedicinalSecond from "../../../components/aboutMedicinalSecond";
import AyushResearch from "../../../components/ayushResearch";
import MedicinalPlantsAssessment from "../../../components/MedicinalPlantsAssessment";

const details = [
  {
    title: "Indian medicinal plants database",
    component: <AboutMedicinalFirst />,
  },
  {
    title: "Database of medicinal plants",
    component: <AboutMedicinalSecond />,
  },
  {
    title: "Ayush research portal",
    component: <AyushResearch />,
  },
  {
    title: "Medicinal plants in India an assessment of their demand and supply",
    component: <MedicinalPlantsAssessment />,
  },
];

export default function AboutMedicinalPlants() {
  return (
    <main className={styles.aboutMedicinalPlants}>
      <h1>About medicinal plants</h1>
      {details.map((item, index) => (
        <Accordian key={index} title={item.title}>
          {item.component}
        </Accordian>
      ))}
    </main>
  );
}

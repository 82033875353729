import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchCancelledAuctions({ payload }) {
  try {
    const res = yield call(API.fetchSellerCancelledAuctionsData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchCancelledAuctionsSuccess(data));
  } catch (err) {
    yield put(actions.fetchCancelledAuctionsFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* labListingSaga() {
  yield takeLatest(actions.fetchCancelledAuctions.type, handleFetchCancelledAuctions);
  
}

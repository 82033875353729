import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.buyerWonAuctions || initialState;

export const selectwonauctions = createSelector([selectDomain], (state) => state.wonAuctions);




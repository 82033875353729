import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.adminAuctionsList || initialState;

export const selectauctionLlist = createSelector([selectDomain], (state) => state.adminAuctionList);
export const getAuctionStatus = createSelector([selectDomain], (state) => state.auctionStatus);




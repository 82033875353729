import styles from "./style.module.css";
import {formattedDateTime } from "../../../utils/helper"; //"../../../utils/helper";

export default function ValidateHederaBids({auction,hederaAuction,hederaAuctionBids,displayMode,misMatchData }) {    
    let bidList = auction.bids;
    let auctionBids = auction.bids;

   // console.log({'auction':auction});
    //console.log({'hederaBids':hederaAuctionBids});
    

    let arrayForSort = [];
    if(bidList.length){
        arrayForSort = [...bidList]
        arrayForSort.sort(
            (p1, p2) => 
            (p1.amount < p2.amount) ? 1 : (p1.amount > p2.amount) ? -1 : 0);
    }

    let headKeysValues = [
      //{key:'auctionIdForDisplay',value:'Auction ID'},
    ];
  
    auctionBids.forEach(element => {
      headKeysValues.push({key:element._id,value:element.createdAt})
    });

    let dataArray=[];
  const matchDBData = (auctionBids)=>{
    headKeysValues.forEach(element => {      
        let bid =  auctionBids.find(f => {
          return element.key === f._id
        });

      dataArray.push(
        {
          key : element.key,
          headKey:element.value,
          dbValue: (bid ? bid.amount : ''), 
          display:true                                    
        }
      );            
    });
  }

  const matchHederaData = (hederaBids) => {
   // let  temphederaBids = [...hederaBids];
    // temphederaBids.push({amount:150,auction:"64d33263a89a6659d0adb2ac",_id:"66d393376a981b0c68bc0a55",createdAt:'2023-08-09T13:23:03.454Z'});
    //To Match the local data to Hedera
    dataArray.forEach(element => {
      let bid =  hederaBids.find(f => {
        return element.key === f._id
      });
      element.hederaValue= (bid ? bid.amount : '');
      element.hederaHeadKey= (bid? bid.createdAt :'');
      element.display = (displayMode === 'all') ? true : (displayMode === 'matched') ? (element.dbValue === element.hederaValue) : (element.dbValue !== element.hederaValue);      
      
          //Set Global misMatch flag
          if(hederaBids.length &&  element.dbValue !== element.hederaValue){
            misMatchData(true);
          }  
    });

    //let  temphederaBids = [...hederaBids];
   // temphederaBids.push({amount:150,auction:"64d33263a89a6659d0adb2ac",_id:"66d393376a981b0c68bc0a55",createdAt:'2023-08-09T13:23:03.454Z'});
    hederaBids.forEach(hbid => {
      let matchedBid = dataArray.find(b => {
        return b.key === hbid._id
      });

      //Loop over on hedera Bids to find any unmatched bid
      if(!matchedBid){
        dataArray.push(
          {
            key : hbid._id,
            headKey:'',
            dbValue: '',
            hederaValue:hbid.amount,
            hederaHeadKey: hbid.createdAt,
            display : (displayMode === 'all') ? true : (displayMode === 'matched') ? false : true
          }
        );
       
       misMatchData(true);
      }
      
    });
}

if(auctionBids){
  matchDBData(auctionBids);  
}


if(hederaAuctionBids){
  matchHederaData(hederaAuctionBids);  
}

    return (<>
        <main className={styles.mainContent}>        
                <section>
                  {auction.labReport && (
                    <span className={styles.title}>
                      2.Bids              
                    </span>
                  )}
                </section>
                <section className={[styles.data, "param_overview"].join(' ')}>
                  <div className={styles.dataLeft}>
                    <span className={styles.key}>Date and Time</span>
                    {dataArray.map((q) => {
                      if(q.display){
                        return <span key={q.headKey}>
                          {formattedDateTime(q.headKey)}
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.headKey !== q.hederaHeadKey)) ? ' | ':''
                        }
                        {
                          (q.headKey !== q.hederaHeadKey) ?  (<strong className={styles.redfont}>   {formattedDateTime(q.hederaHeadKey)} </strong>):''
                        }
                        </span>;
                      }                      
                    })}
                  </div>
                  <div className={styles.dataRight}>
                    <span className={styles.key}>Data</span>
                    {dataArray.map((q,index)=>{
                      if(q.display){
                       return <span key={index}>
                        ₹{
                         q.dbValue
                        }
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.dbValue !== q.hederaValue)) ? ' | ':''
                        }  
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.dbValue !== q.hederaValue)) ?  (<strong className={styles.redfont}>   ₹{q.hederaValue} </strong>):''
                        }                        
                        </span>;
                      }                      
                    })
                  } 
                  </div>
                </section>
              </main>
        </>);
}







// import React, { useState} from "react";
// import { Table, Badge } from 'react-bootstrap';
// import styles from "./style.module.css";

// import { formattedDate,formattedDateTime } from "../../../utils/helper"; //"../../../utils/helper";

// const ValidateHederaBids = ({auctionDetail,hederaBids}) => { 
//     console.log({'auctionDetail':auctionDetail});  
//     let bidList = auctionDetail.bids;
//     let arrayForSort = [];
//     if(bidList.length){
//         arrayForSort = [...bidList]
//         arrayForSort.sort(
//             (p1, p2) => 
//             (p1.amount < p2.amount) ? 1 : (p1.amount > p2.amount) ? -1 : 0);
//     }
    
    
    
//   return (
//     <>
//         <Table responsive>
//             <thead style={{backgroundColor: '#F7FAFC'}}>
//                    <tr>
//                         <td className={styles.headT}>Date</td>
//                         <td className={styles.headT}>Bid</td>
//                     </tr>
//             </thead>
//             <tbody>
//                 {arrayForSort.map((bid, index) => (
//                     <tr key={index}>
//                         <td className={styles.dataT}>{formattedDateTime(bid.createdAt)}</td>
//                         <td className={styles.dataT}>₹{bid.amount}
//                         { auctionDetail.status === 'awarded' && auctionDetail.awardedBid === bid._id && 
//                             <Badge className={[styles.wonBadge].join(' ')}>
//                             Won
//                             </Badge> 
//                         }
//                        </td>                      
//                     </tr>
//                 ))}
//             </tbody>
//         </Table>   
//     </>
//   );
// };

// export default ValidateHederaBids;

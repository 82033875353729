import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handlePostNewBid({ payload }) {  
  try {
    const res = yield call(API.postNewBid, payload);      
    let data = JSON.parse(res.data);
    yield put(actions.postBidSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.postBidFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* labListingSaga() {
  yield takeLatest(actions.postBid.type, handlePostNewBid);
  
}

import styles from "./styles.module.css";
import { formattedDate } from "../../../utils/helper";

export default function HederaAuctionTraceability({auction,hederaAuction,displayMode,misMatchData }) {
  let auctionTrace =  auction.trakingDetails;
  let hederaTrace =  hederaAuction.trakingDetails;

  let headKeysValues = [
    //{key:'auctionIdForDisplay',value:'Auction ID'},
  ];

  auctionTrace.forEach(element => {
    headKeysValues.push({key:element._id,value:element.detail})
  });

  let dataArray=[];
  const matchDBData = (auctionTrace)=>{
    headKeysValues.forEach(element => {      
        let trace =  auctionTrace.find(f => {
          return element.key === f._id
        });

      dataArray.push(
        {
          key : element.key,
          headKey:element.value,
          dbValue: (trace ? trace.date : ''), 
          display:true                                    
        }
      );            
    });
  }

  const matchHederaData = (hederaTrace) => {  
    //To Match the local data to Hedera    
    dataArray.forEach(element => {
      let hTrace =  hederaTrace.find(f => {
        return element.key === f._id
      });
      element.hederaValue= (hTrace ? hTrace.date : '');
      element.hederaHeadKey= (hTrace? hTrace.detail :'');
      element.display = (displayMode === 'all') ? true : (displayMode === 'matched') ? (element.dbValue === element.hederaValue) : (element.dbValue !== element.hederaValue);           
      if(hederaAuction && Object.keys(hederaAuction).length && element.dbValue !== element.hederaValue){
        misMatchData(true);
      } 
    });

//To Match Hedera Data to Local
//let tempdata = [...hederaTrace];
//tempdata.push({_id:'66d393376a981b0c68bc0a55',detail:'test',date:'2023-09-05T00:00:00.000Z'})
hederaTrace.forEach(ht => {
    let matchedData = dataArray.find(f => {
      return f.key === ht._id;
    })

    if(!matchedData){
      dataArray.push(
        {
          key : ht._id,
          headKey:'',
          dbValue: '',
          hederaValue:ht.date,
          hederaHeadKey: ht.detail,
          display : (displayMode === 'all') ? true : (displayMode === 'matched') ? false : true
        }
      );
      console.log('trace');
     // misMatchData(true);
    }
});




}

if(auctionTrace){
  matchDBData(auctionTrace);  
}


if(hederaTrace){
  matchHederaData(hederaTrace);
}


  
    return (<>
        <main className={styles.mainContent}>        
                <section>
                  {auction.labReport && (
                    <span className={styles.title}>
                      4.Traceability              
                    </span>
                  )}
                </section>
                <section className={[styles.data, "param_overview"].join(' ')}>
                  <div className={styles.dataLeft}>
                    <span className={styles.key}>Parameter</span>
                    {dataArray.map((q) => {
                      if(q.display){
                        return <span key={q.key}>{q.headKey}
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.headKey !== q.hederaHeadKey)) ? ' | ':''
                        } 
                        {
                          (q.headKey !== q.hederaHeadKey) ?  (<strong className={styles.redfont} >   {q.hederaHeadKey} </strong>):''
                        } 
                        </span>;
                      }                      
                    })}                    
                    
                  </div>
                  <div className={styles.dataRight}>
                    <span>Data</span>
                    {dataArray.map((q,index)=>{
                      if(q.display){
                       return <span key={index}>
                        {
                         formattedDate(q.dbValue)
                        }
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.dbValue !== q.hederaValue)) ? ' | ':''
                        }  
                        {
                          (q.dbValue !== q.hederaValue) ?  ( <strong className={styles.redfont}>   {formattedDate(q.hederaValue)} </strong>):''
                        }                        
                        </span>;
                      }                      
                    })
                  } 
                  </div>
                </section>
              </main>
        </>);
}



import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Heading from "../../../components/shared/Heading";
// import Overview from "./overview";
// import Traceability from "./traceability";
// import Quality from "./quality";
import Supplier from "./supplier";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import rightArrow from "../../../../assets/dashboardImages/rightArrow.svg";
import styles from "./style.module.css";

import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions, initialState } from "./slice";
import { userDetailSaga } from "./saga";
import * as selectors from "./selectors";
import { useRadioGroup } from "@mui/material";
import { toastSuccess } from "../../../utils/toastHelper";
import Modal from "../../../components/homeApp/modal";
import Button from "../../../components/shared/button";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

const UserDetail = () => {
  const navigate = useNavigate();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userDetailSaga });

  const userDetail = useSelector(selectors.userDetail) || {};
  const userDocument = userDetail.document;

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reasonForBlock, setReasonForBlock] = useState("");
  const [reasonForBlockError, setReasonForBlockError] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const qualityData = [];
  for (let i = 0; i < 4; i++) {
    qualityData.push({
      parameter: "Parameter",
      value: "Value",
    });
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handleUserBlock = (e) => {
    e.preventDefault();
    if (reasonForBlock.length <= 3) {
      setReasonForBlockError(
        "please, provide a valid reason for blocking this user"
      );
      return;
    }
    dispatch(
      actions.blockSeller({
        id: userDocument._id,
        reason: reasonForBlock,
        onSuccess: () => {
          toastSuccess("User blocked successfully.");
          setIsModalOpen(false);
          navigate(-1);
        },
      })
    );
    // setTimeout(() => {
    //   dispatch(actions.fetchUserById(userDocument._id));
    // }, 1000);
  };

  const handleUserStatus = (status) => {

    confirmAlert({
      message: `Are you sure you want to ${status} the User?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => 
            dispatch(
              actions.updateUserStatus({
                id: userDocument._id,
                status,
                onSuccess: () => {
                  toastSuccess("User status modified successfully.");
                  dispatch(actions.fetchUserById(userDocument._id));
                  setIsModalOpen(false);
                },
              })
            )
        },
        {
          label: 'No'
        }
      ]
    });
    // if(!window.confirm(`Are you sure you want to ${status} the User?`)){
    //   return;
    // }
    // dispatch(
    //   actions.updateUserStatus({
    //     id: userDocument._id,
    //     status,
    //     onSuccess: () => {
    //       toastSuccess("User status modified successfully.");
    //       dispatch(actions.fetchUserById(userDocument._id));
    //       setIsModalOpen(false);
    //     },
    //   })
    // );
    // setTimeout(() => {
    //   dispatch(actions.fetchUserById(userDocument._id));
    // });
  };

  useEffect(() => {
    if (reasonForBlock.length > 3) {
      setReasonForBlockError("");
    }
  }, [reasonForBlock]);

  useEffect(() => {
    let url = new URL(window.location.href);
    const userId = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);

    dispatch(actions.fetchUserById(userId));
  }, [dispatch]);

  return (
    <div>
      {userDetail && userDocument && (
        <Container fluid id="auctionD">
          <Row style={{ marginBottom: "2em" }}>
            <Col lg={1}>
              <Link className={[styles.visitBtn].join(" ")}>
                <img
                  src={rightArrow}
                  onClick={() => {
                    handleBack();
                  }}
                  alt=""
                />
              </Link>
            </Col>
            <Col lg={8}>
              <Heading text={userDocument.companyName} />
              {/* <span className="auctionID">{userDocument._id} </span> */}
            </Col>
            <Col lg={3}>
              {userDocument.status === "approved" && (
                <Link
                  onClick={() => {
                    // handleUserStatus("block");
                    showModal();
                  }}
                  to={"#"}
                  className={[styles.visitBtn, "float-end"].join(" ")}
                  style={{
                    borderColor: "red",
                    color: "red",
                    marginLeft: "1em",
                  }}
                >
                  Block
                </Link>
              )}

              {isModalOpen &&
                <Modal
                  showModal={isModalOpen}
                  closeButton={true}
                  closeModal={closeModal}
                  modalStyle={{ width: "650px" }}
                >
                  <h4
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "400",
                      fontSize: "18px",
                      marginBottom: "20px",
                    }}
                  >
                    Block{" "}
                    <span style={{ fontWeight: "500" }}>
                      {userDocument.userName}
                    </span>
                  </h4>
                  <form onSubmit={handleUserBlock}>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column",
                        marginBottom: "16px",
                      }}
                    >
                      <label htmlFor="reasonForBlocking">
                        Reason For Blocking
                      </label>
                      <textarea
                        rows="5"
                        cols="5"
                        value={reasonForBlock}
                        onChange={(e) => setReasonForBlock(e.target.value)}
                        placeholder="Reason for blocking"
                        style={
                          reasonForBlockError
                            ? {
                                width: "70%",
                                outline: "none",
                                padding: "12px",
                                border: "1px solid #d00",
                                borderRadius: "6px",
                              }
                            : {
                                width: "70%",
                                outline: "none",
                                padding: "12px",
                                border: "1px solid #ddd",
                                borderRadius: "6px",
                              }
                        }
                      />
                      {reasonForBlockError ? (
                        <span style={{ color: "#d00" }}>
                          {reasonForBlockError}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="consent"
                        name="consent"
                        checked
                      />
                      <label htmlFor="consent">
                        Are you sure, you want to block{" "}
                        <span
                          style={{
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {userDocument.userName}
                        </span>
                      </label>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button solid={true} text="Block user" type="submit" />
                    </div>
                  </form>
                </Modal>
              }

               {(userDocument.status === "review") && 
                <Link
                  onClick={() => {
                    handleUserStatus("reject");
                  }}
                  to={"#"}
                  className={[styles.visitBtn, "float-end"].join(" ")}
                >Reject</Link>                
              }

              {(userDocument.status === "review" || userDocument.status === "reject" ||
                userDocument.status === "block") && 
                <Link
                  onClick={() => {
                    handleUserStatus("approved");
                  }}
                  to={"#"}
                  className={[styles.visitBtn, "float-end"].join(" ")}
                >Activate</Link>                
              }

              
              
            </Col>

            <div style={{ marginTop: "2em" }}>
              <Tabs
                defaultActiveKey="supplierDetail"
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                {/* <Tab eventKey="overview" title="Overview">
                            <Overview />
                        </Tab>
                        <Tab eventKey="auctionDetail" title="Auction details">
                            profile
                        </Tab>
                        <Tab eventKey="traceability" title="Traceability">
                            <Traceability />
                        </Tab>
                        <Tab eventKey="qualityDetail" title="Quality Details">
                            <Quality listHeading={qualityHeading} listData={qualityData} />
                        </Tab> */}
                <Tab eventKey="supplierDetail" title="User Details">
                  <Supplier userDetail={userDocument} />
                </Tab>
              </Tabs>
            </div>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default UserDetail;

import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import FileIcon from "../../../../../assets/icons/file.svg";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import "./BankDetails.css";

export default function BankDetails(props) {
  // State Variables
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);

  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [accountHolderError, setAccountHolderError] = useState("");

  // Handler Functions
  const handleBankChange = (event) => {
    setBankName(event.target.value);
    setBankNameError("");
  };

  const handleBranchChange = (event) => {
    setBranchName(event.target.value);
    setBranchNameError("");
  };

  // **Enhanced Account Number Change Handler**
  const handleAccountChange = (event) => {
    const value = event.target.value;
    // Allow only digits
    if (/^\d*$/.test(value)) {
      setAccountNumber(value);
      setAccountNumberError("");
    }
  };

  const handleIfscCodeChange = (event) => {
    setIfscCode(event.target.value.toUpperCase()); // IFSC codes are typically uppercase
    setIfscCodeError("");
  };

  const handleAccountHolderChange = (event) => {
    setAccountHolder(event.target.value);
    setAccountHolderError("");
  };

  const handleInputClick = () => {
    document.getElementById("input_file").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files;
    setFileToUpload(file);
  };

  // Validation Functions
  const checkName = (name) => {
    const pattern = /^[A-Za-z\s]+$/;
    return pattern.test(name);
  };

  // **Enhanced Account Number Validation Function**
  const checkAccountNumber = (accountNumber) => {
    const pattern = /^\d{9,18}$/; // Ensures the account number is between 9 to 18 digits
    return pattern.test(accountNumber.trim());
  };

  const checkIFSCCode = (ifscCode) => {
    const pattern = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    return pattern.test(ifscCode);
  };

  // Submit Handler
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents default form submission behavior

    let isValid = true;

    // Bank Name Validation (Optional)
    if (bankName.trim() !== "") {
      if (checkName(bankName.trim())) {
        setBankNameError("");
      } else {
        setBankNameError("Enter only letters.");
        isValid = false;
      }
    } else {
      setBankNameError("");
    }

    // Branch Name Validation (Optional)
    if (branchName.trim() !== "") {
      if (checkName(branchName.trim())) {
        setBranchNameError("");
      } else {
        setBranchNameError("Enter only letters.");
        isValid = false;
      }
    } else {
      setBranchNameError("");
    }

    // IFSC Code Validation (Optional)
    if (ifscCode.trim() !== "") {
      if (checkIFSCCode(ifscCode.trim())) {
        setIfscCodeError("");
      } else {
        setIfscCodeError(
          "Invalid IFSC code. Please enter a valid 11-character IFSC code."
        );
        isValid = false;
      }
    } else {
      setIfscCodeError("");
    }

    // **Account Number Validation (Optional)**
    if (accountNumber.trim() !== "") {
      if (checkAccountNumber(accountNumber)) {
        setAccountNumberError("");
      } else {
        setAccountNumberError(
          "Invalid account number. It should be 9 to 18 digits."
        );
        isValid = false;
      }
    } else {
      setAccountNumberError("");
    }

    // Account Holder Name Validation (Optional)
    if (accountHolder.trim() !== "") {
      if (checkName(accountHolder.trim())) {
        setAccountHolderError("");
      } else {
        setAccountHolderError("Enter only letters.");
        isValid = false;
      }
    } else {
      setAccountHolderError("");
    }

    // If all validations pass, proceed
    if (isValid) {
      props.onClickFinishbutton(
        bankName.trim(),
        branchName.trim(),
        ifscCode.trim(),
        accountNumber.trim(),
        accountHolder.trim(),
        fileToUpload
      );
      console.log("Form submitted successfully");
    }
  };

  // Styles
  const containerStyle = {
    border: "2px dashed #E2E8F0",
    backgroundColor: "#F7FAFC",
    height: "98px",
    paddingLeft: "150px",
    paddingTop: "25px",
    borderRadius: "6px",
  };

  const fileContainerStyle = {
    border: "1px solid #EDF2F7",
    borderRadius: "6px",
    height: "48px",
    paddingTop: "12px",
    paddingLeft: "15px",
    paddingRight: "15px",
  };

  const inputStyles = {
    height: "16px",
    width: "251px",
    borderRadius: "6px",
  };

  const backButtonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "#FFFFFF !important",
      border: "1px solid #E2E8F0 !important",
      boxShadow: "none !important",
    },
  };

  const verifyButtonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "green !important",
    },
  };

  // Custom Styled Buttons
  const CustomButton = withStyles(backButtonStyles)(({ classes, ...props }) => (
    <Button className={classes.root} {...props} />
  ));

  const CustomVerifyButton = withStyles(verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );

  return (
    <>
      {/* Header */}
      <div
        id="bankdetails"
        style={{
          color: "#2D3748",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
        }}
      >
        Bank Details
      </div>
      <div
        style={{
          color: "#718096",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          marginTop: "8px",
        }}
      >
        Enter your bank details
      </div>

      {/* First Row: Bank Name & Branch Name */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
        }}
        id="flexdiv"
      >
        {/* Bank Name */}
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Bank Name
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-bank-name"
              aria-describedby="bank-name-helper-text"
              placeholder="Ex. ABC Bank"
              inputProps={{
                style: inputStyles,
                maxLength: 50,
              }}
              value={bankName}
              onChange={handleBankChange}
            />
            {bankNameError && (
              <FormHelperText error id="bank-name-helper-text">
                {bankNameError}
              </FormHelperText>
            )}
          </div>
        </div>

        {/* Branch Name */}
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Branch Name
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-branch-name"
              aria-describedby="branch-name-helper-text"
              placeholder="Branch"
              inputProps={{
                style: inputStyles,
                maxLength: 50,
              }}
              value={branchName}
              onChange={handleBranchChange}
            />
            {branchNameError && (
              <FormHelperText error id="branch-name-helper-text">
                {branchNameError}
              </FormHelperText>
            )}
          </div>
        </div>
      </div>

      {/* Second Row: Account Number & IFSC Code */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
        }}
        id="flexdiv"
      >
        {/* Account Number */}
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Account Number
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-account-number"
              aria-describedby="account-number-helper-text"
              placeholder="Ex. 123456789012"
              inputProps={{
                style: inputStyles,
                maxLength: 18,
                inputMode: "numeric", // Brings up numeric keypad on mobile
              }}
              value={accountNumber}
              onChange={handleAccountChange}
            />
            {accountNumberError && (
              <FormHelperText error id="account-number-helper-text">
                {accountNumberError}
              </FormHelperText>
            )}
          </div>
        </div>

        {/* IFSC Code */}
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            IFSC Code
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-ifsc-code"
              aria-describedby="ifsc-code-helper-text"
              placeholder="Ex. ABCD0XXXXXX"
              inputProps={{
                style: inputStyles,
                maxLength: 11,
              }}
              value={ifscCode}
              onChange={handleIfscCodeChange}
            />
            {ifscCodeError && (
              <FormHelperText error id="ifsc-code-helper-text">
                {ifscCodeError}
              </FormHelperText>
            )}
          </div>
        </div>
      </div>

      {/* Account Holder Name */}
      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            color: "#2D3748",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Account Holder Name
        </div>
        <div style={{ marginTop: "8px" }} id="acc_holder">
          <OutlinedInput
            id="outlined-account-holder"
            aria-describedby="account-holder-helper-text"
            placeholder="Ex. Amit Singh"
            inputProps={{
              style: inputStyles,
              maxLength: 50,
            }}
            value={accountHolder}
            onChange={handleAccountHolderChange}
          />
          {accountHolderError && (
            <FormHelperText error id="account-holder-helper-text">
              {accountHolderError}
            </FormHelperText>
          )}
        </div>
      </div>

      {/* File Upload Section */}
      <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "20px",
        }}
      >
        Upload Canceled Cheque
      </div>
      <div style={{ marginTop: "8px" }} id="dand">
        <Box style={containerStyle}>
          <input
            type="file"
            name=""
            id="input_file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".pdf,.zip,.png,.jpeg,.jpg" // Corrected accept attribute
          />
          <div style={{ display: "flex", gap: 2 }}>
            <div
              style={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Drag and drop files or
            </div>
            <div
              style={{
                color: "#38A169",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
              onClick={handleInputClick}
            >
              Browse
            </div>
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            Supported formats: JPEG, JPG, PNG, PDF, ZIP
          </div>
        </Box>

        {/* Display Uploaded File */}
        {fileToUpload && (
          <div style={{ marginTop: "8px" }}>
            <Box style={fileContainerStyle}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", gap: 4 }}>
                  <div>
                    <img src={FileIcon} alt="file" />
                  </div>

                  <div
                    style={{
                      color: "#1A202C",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "2px",
                    }}
                  >
                    {fileToUpload[0].name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setFileToUpload(null);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div
        style={{ display: "flex", gap: 40, marginTop: "60px" }}
        id="btns_holder"
      >
        {/* Back Button */}
        <div>
          <CustomButton onClick={props.onClickBackbutton} variant="contained">
            <div
              style={{
                color: "#1A202C",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Back
            </div>
          </CustomButton>
        </div>

        {/* Finish Button */}
        <div>
          <CustomVerifyButton onClick={handleSubmit}>
            <div
              style={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Finish
            </div>
          </CustomVerifyButton>
        </div>
      </div>
    </>
  );
}

// Styles for Custom Buttons
const backButtonStyles = {
  root: {
    width: "276px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "#FFFFFF !important",
    border: "1px solid #E2E8F0 !important",
    boxShadow: "none !important",
  },
};

const verifyButtonStyles = {
  root: {
    width: "276px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "green !important",
  },
};

// Custom Styled Buttons
const CustomButton = withStyles(backButtonStyles)(({ classes, ...props }) => (
  <Button className={classes.root} {...props} />
));

const CustomVerifyButton = withStyles(verifyButtonStyles)(
  ({ classes, ...props }) => <Button className={classes.root} {...props} />
);

import React from "react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

export default function AyushResearch() {
  return (
    <div className={styles.ayushResearch}>
      <p>
        AYUSH Research Portal is an initiative of the National Institute of
        Indian Medical Heritage. It contains the research data of Ayurveda, Yoga
        and Naturopathy, Unani, Siddha, Homoeopathy along with AYUSH related
        research done by the allied sciences. It has mainly two applications.
      </p>
      <ul>
        <li>Search of research data by the User</li>
        <li>Uploading of research data by the selected institutes</li>
      </ul>
      <p>
        User can get Clinical research articles for specific disease condition
        by selecting the medical system, body system and disease (English). User
        can also get the research articles by simply clicking on the following
        categories
      </p>
      <p>
        <b>
          Clinical Research (A, B, C gradation was also given to the clinical
          research articles based on the WHO grading recommendations)
        </b>
      </p>
      <ul>
        <li>Pre-Clinical Research</li>
        <li>Drug Research</li>
        <li>Fundamental Research</li>
      </ul>
      <p>
        To upload the data in the AYUSH Research Portal, user has to login by
        entering the user name and password (which are provided by the NIIMH,
        Hyderabad to all AYUSH Research Councils & other selected institutes).
      </p>
      <p>
        To 5 - 10 years Annual Salary of the Job: 0.00 - 8.00 Lacs know more,
        visit{" "}
        <Link to="http://ayushportal.nic.in/" target="_blank" rel="noreferrer">
          Ayush Portal
        </Link>
      </p>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  linkList:{links:[],totalRecords:0},
};

const linksList = createSlice({
  name: "linksList",
  initialState,
  reducers: {
    fetchLinks(state, action) {
      state.loading = true;
    },
    fetchLinksSuccess(state, action) {
      state.linkList = action.payload; 
      state.loading = false;
    },
    fetchLinksFailure(state, action) {
      state.loading = false;
    },
    removeLink(state, action) {
      state.loading = true;
    },
    removeLinkSuccess(state, action) {
      // state.Link = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    removeLinkFailure(state, action) {
      state.loading = false;
    },
  },
 
});

export const { actions, reducer, name: sliceKey } = linksList;

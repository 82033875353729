import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.homeMandiList || initialState;

export const selectMandiList = createSelector([selectDomain], (state) => state.homeMandiList);




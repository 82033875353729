import { object } from "yup";
import styles from "./styles.module.css";

export default function ValidateHederaQuality({auction,hederaAuction,displayMode,misMatchData }) { 
  
    let auctionQuality =  auction.qualityResult;
    let hederaQuality =  hederaAuction.qualityResult;

  let headKeysValues = [
    //{key:'auctionIdForDisplay',value:'Auction ID'},
  ];

  auctionQuality.forEach(element => {
    headKeysValues.push({key:element._id,value:element.value});
  });

  let dataArray=[];
  const matchDBData = (auctionQuality)=>{
    headKeysValues.forEach(element => {
        let quality =  auctionQuality.find(f => {
          return element.key === f._id
        });

      dataArray.push(
        {
          key : element.key,
          headKey:(quality ? quality.parameter : ''), 
          dbValue: (quality ? quality.value : ''), 
          display:true                                    
        }
      );            
    });
  }

  const matchHederaData = (hederaQuality) => {

    dataArray.forEach(element => {
      let quality =  hederaQuality.find(f => {
        return element.key === f._id
      });
      element.hederaValue = (quality ? quality.value : '');
      element.hederaHeadKey= (quality? quality.parameter :'');
      element.display = (displayMode === 'all') ? true : (displayMode === 'matched') ? (element.dbValue === element.hederaValue) : (element.dbValue !== element.hederaValue);     
      if(hederaAuction && Object.keys(hederaAuction).length && element.dbValue !== element.hederaValue){
        misMatchData(true);
      }  
    });

    //let tempQuality = [...hederaQuality];
    //tempQuality.push({_id:'64c0e92f2b2a22203842ff31',parameter:'test',value:'testset'})
    hederaQuality.forEach(hq => {
      let matchedData = dataArray.find(f=> {
          return f.key === hq._id;
      });

      if(!matchedData){
        dataArray.push(
          {
            key : hq._id,
            headKey:'',
            dbValue: '',
            hederaValue:hq.value,
            hederaHeadKey: hq.parameter,
            display : (displayMode === 'all') ? true : (displayMode === 'matched') ? false : true
          }
        );
        console.log('quality');
       // misMatchData(true);
      }
      
    });

   
}

if(auctionQuality){
  matchDBData(auctionQuality);  
}


if(hederaQuality){
  matchHederaData(hederaQuality);
  
}

    return (<>
        <main className={styles.mainContent}>        
                <section>
                  {auction.labReport && (
                    <span className={styles.title}>
                      5.Quality Details              
                    </span>
                  )}
                </section>
                <section className={[styles.data, "param_overview"].join(' ')}>
                  <div className={styles.dataLeft}>
                    <span className={styles.key}>Parameter</span>
                    {dataArray.map((q) => {
                      if(q.display){
                        return <span key={q.headKey}>{q.headKey}
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.headKey !== q.hederaHeadKey)) ? ' | ':''
                        } 
                        {
                          (q.hederaHeadKey !== q.headKey) ?  (<strong className={styles.redfont}>  {q.hederaHeadKey} </strong>):''
                        }  
                        </span>;
                      }                      
                    })}
                  </div>
                  <div className={styles.dataRight}>
                    <span className={styles.key}>Data</span>
                    
                      {dataArray.map((q,index)=>{
                      if(q.display){
                       return <span key={index}>
                        {
                         q.dbValue
                        }
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.dbValue !== q.hederaValue)) ? ' | ':''
                        }  
                        {
                          (q.dbValue !== q.hederaValue) ?  (<strong className={styles.redfont}>   {q.hederaValue} </strong>):''
                        }                        
                        </span>;
                      }                      
                    })
                  } 
                  </div>
                </section>
              </main>
        </>);
}



import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchMandis({ payload }) {
  try {
    const res = yield call(API.fetchMandisListData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { body } = parsedObject;
    yield put(actions.fetchMandisSuccess(body));
  } catch (err) {
    yield put(actions.fetchMandisFailure(err?.response?.data));
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export function* mandiListingSaga() {
  yield takeLatest(actions.fetchMandis.type, handleFetchMandis);
  
}

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  buyerList:{buyers:[],totalRecords:0},
  buyerBuyerFilterStatus:"approved"
};

const buyersList = createSlice({
  name: "buyersList",
  initialState,
  reducers: {
    fetchBuyers(state, action) {
      state.loading = true;
    },
    fetchBuyersSuccess(state, action) {
      state.buyerList = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchBuyersFailure(state, action) {
      state.loading = false;
    },
    changeBuyerFilterStatus(state, action) {
      state.buyerBuyerFilterStatus = action.payload;
    }
  },
 
});

export const { actions, reducer, name: sliceKey } = buyersList;

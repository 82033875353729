import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  liveAuctions:{auctions:[],totalRecords:0},
  
  
};

const sellerLiveAuctions = createSlice({
  name: "sellerLiveAuctions",
  initialState,
  reducers: {
    fetchLiveAuctions(state, action) {
      state.loading = true;
    },
    fetchLiveAuctionsSuccess(state, action) {
      state.liveAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchLiveAuctionsFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = sellerLiveAuctions;

import React, { useEffect, useState } from "react";
import successIcon from "../../../../assets/icons/success.svg";
import uploadIcon from "../../../../assets/icons/upload-icon.svg";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Divider from "@mui/material/Divider";
import { withStyles } from "@mui/styles";
import { useAuth } from "../../../contexts/auth";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "./slice";
import { transactionDetailsSaga } from "./saga";
import { useDispatch } from "react-redux";

export default function PendingPaymentPage(props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: transactionDetailsSaga });

  const auth = useAuth();
  const dispatch = useDispatch();
  const user = auth.loggedInUser;
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const containerStyle = {
    width: "661px",
    border: "1px solid #E2E8F0",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "36px",
    paddingRight: "36px",
    paddingBottom: "27px",
    paddingTop: "27px",
  };
  const detailsContainer = {
    width: "661px",
    border: "1px solid #E2E8F0",
    borderRadius: "6px",
    padding: "28px",
  };
  const inputStyles = {
    height: "16px",
    width: "251px",
    borderRadius: "6px",
  };
  const inputUploadStyles = {
    height: "16px",
    width: "217px",
    borderRadius: "6px",
  };
  const verifyButtonStyles = {
    root: {
      width: "605px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "#38A169 !important",
    },
  };
  const CustomVerifyButton = withStyles(verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );
  const [transactionId, setTransactionid] = useState("");
  const [transactionScreenshot, setTransactionScreenshot] = useState(null);
  const [transactionFullName, setTransactionFullName] = useState("");
  const [transactionEmail, setTransactionEmail] = useState("");
  const [transactionPhone, setTransactionPhone] = useState("");

  const handleChangeTransactionId = (event) => {
    setTransactionid(event.target.value);
  };
  const handleChangeTransactionFullName = (event) => {
    setTransactionFullName(event.target.value);
  };
  const handleChangeTransactionEmail = (event) => {
    setTransactionEmail(event.target.value);
  };
  const handleChangeTransactionPhone = (event) => {
    setTransactionPhone(event.target.value);
  };
  const handleInputClick = () => {
    document.getElementById("input_file").click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files;
    setTransactionScreenshot(file);
  };

  const handleSubmitButtonClicked = () => {
    const payload = {
      data: {
        id: user._id,
        transactionId,
        transactionFullName,
        registeredEmail: transactionEmail,
        registeredPhone: transactionPhone,
        transactionScreenshot,
      },
      onSuccess: () => {
        auth.fetchLoggedInUser();
        console.log("reached");
      },
    };
    setSubmitButtonClicked(!submitButtonClicked);
    dispatch(actions.submitPaymentProof(payload));
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          //flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!submitButtonClicked && !user.transactionId ? (
          <div>
            <div>
              <img src={successIcon} alt="successIcon" />
            </div>
            <div
              style={{
                color: "#000000",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "32px",
                marginTop: "32px",
              }}
            >
              Registration Successful!
            </div>
            <div
              style={{
                color: "#2D3748",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "36px",
              }}
            >
              Thank you for registering with us!
            </div>
            <div style={{ display: "flex", gap: 4, marginTop: "44px" }}>
              <div
                style={{
                  width: "48px",
                  height: "16px",
                  borderRadius: "2px",
                  background: "#38A169",
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "16px",
                  color: "#FFFFFF",
                  padding: "0px 4px 0px 4px",
                }}
              >
                STEP1
              </div>
              <div
                style={{
                  color: "#2D3748",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "28px",
                  marginTop: "-6px",
                }}
              >
                To start using the benefits of the platform, please make a
                registartion fee of Rs 5000 via NEFT. You can find account
                details here:
              </div>
            </div>
            <div style={{ marginTop: "24px" }}>
              <Box style={containerStyle}>
                <div style={{ display: "flex", gap: 200 }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 500,
                      lineHeight: "20px",
                    }}
                  >
                    Beneficiary's name
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Agnext
                  </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <Divider />
                </div>
                <div style={{ display: "flex", gap: 128, marginTop: "15px" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 500,
                      lineHeight: "20px",
                    }}
                  >
                    Beneficiary's account number
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    1234567890
                  </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <Divider />
                </div>
                <div style={{ display: "flex", gap: 162, marginTop: "15px" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 500,
                      lineHeight: "20px",
                    }}
                  >
                    Beneficiary's bank name
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    HDFC
                  </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <Divider />
                </div>
                <div style={{ display: "flex", gap: 113, marginTop: "15px" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 500,
                      lineHeight: "20px",
                    }}
                  >
                    Beneficiary's bank branch name
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Mohali Branch
                  </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <Divider />
                </div>
                <div style={{ display: "flex", gap: 166, marginTop: "15px" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 500,
                      lineHeight: "20px",
                    }}
                  >
                    Beneficiary's IFSC code
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#2D3748",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    HF0000001
                  </div>
                </div>
              </Box>
            </div>
            <div style={{ display: "flex", gap: 4, marginTop: "44px" }}>
              <div
                style={{
                  width: "48px",
                  height: "16px",
                  borderRadius: "2px",
                  background: "#38A169",
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "16px",
                  color: "#FFFFFF",
                  padding: "0px 4px 0px 4px",
                }}
              >
                STEP2
              </div>
              <div
                style={{
                  color: "#2D3748",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "28px",
                  marginTop: "-6px",
                }}
              >
                Once you have made your payment, please fill the following form:
              </div>
            </div>
            <div style={{ marginTop: "16px" }}>
              <Box style={detailsContainer}>
                <div style={{ display: "flex", gap: 50, marginTop: "44px" }}>
                  <div>
                    <div
                      style={{
                        color: "#2D3748",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Transaction ID or UTR number
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="Ex. ABC Bank"
                        inputProps={{
                          style: inputStyles,
                        }}
                        value={transactionId}
                        onChange={handleChangeTransactionId}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#2D3748",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Screenshot of the receipt
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        startAdornment={
                          <>
                            <input
                              type="file"
                              name=""
                              id="input_file"
                              style={{ width: "40px" }}
                              //ref={fileRef}
                              onChange={handleFileChange}
                              accept={".pdf, .zip, .png, .jpeg"}
                            />
                            {/*   <div
                              style={{
                                background: "#EDF2F7",
                                width: "48px",
                                height: "48px",
                                textAlign: "center",
                                marginLeft: "-12px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{ marginTop: "15px" }}
                                src={uploadIcon}
                                alt="uploadIcon"
                              />
                            </div> */}
                          </>
                        }
                        //placeholder="Upload"
                        inputProps={{
                          style: inputUploadStyles,
                        }}
                        value={
                          transactionScreenshot && transactionScreenshot[0].name
                        }
                        onChange={handleInputClick}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", gap: 50, marginTop: "20px" }}>
                  <div>
                    <div
                      style={{
                        color: "#2D3748",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Your full name
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="Ex. 1234XXXXXX"
                        inputProps={{
                          style: inputStyles,
                        }}
                        value={transactionFullName}
                        onChange={handleChangeTransactionFullName}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#2D3748",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Registered email address
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="Ex. 1234XXXXXX"
                        inputProps={{
                          style: inputStyles,
                        }}
                        value={transactionEmail}
                        onChange={handleChangeTransactionEmail}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      color: "#2D3748",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Registered phone number
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      placeholder="Ex. 1234XXXXXX"
                      inputProps={{
                        style: inputStyles,
                      }}
                      value={transactionPhone}
                      onChange={handleChangeTransactionPhone}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "40px" }}>
                  <CustomVerifyButton onClick={handleSubmitButtonClicked}>
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "18px",
                        lineHeight: "28px",
                      }}
                    >
                      Submit Payment Proof
                    </div>
                  </CustomVerifyButton>
                </div>
              </Box>
            </div>
            <div style={{ display: "flex", gap: 4, marginTop: "44px" }}>
              <div
                style={{
                  width: "100px",
                  height: "16px",
                  borderRadius: "2px",
                  background: "#38A169",
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "16px",
                  color: "#FFFFFF",
                  padding: "0px 4px 0px 4px",
                }}
              >
                FINAL STEP
              </div>
              <div
                style={{
                  color: "#2D3748",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "28px",
                  marginTop: "-6px",
                }}
              >
                We will verify your details and approve your account within 48
                hours of receiving your payment.
              </div>
            </div>
            <div
              style={{
                color: "#718096",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "36px",
                marginTop: "56px",
              }}
            >
              If you have any questions, please do not hesitate to contact us.
              You can reach us by email at contact@agnext.in or by phone at
              +91-988-XXX-XXX.
            </div>
            <div
              style={{
                color: "#718096",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "36px",
                marginTop: "56px",
              }}
            >
              Team Herb Connect
            </div>
          </div>
        ) : (
          <div>
            <p
              style={{
                color: "#718096",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "36px",
                marginTop: "56px",
              }}
            >
              Thank You for submitting transaction details. Once your account
              has been approved by our admins you will be able to access the
              portal
            </p>
          </div>
        )}
      </div>
    </>
  );
}

import React from "react";
import hederaLogo from "../../../../assets/icons/Hedera logo.svg";
import styles from "./styles.module.css";

export default function HederaBar() {
  return (
    <div className={styles.hederaBar}>
      <img src={hederaLogo} alt="Hedera Logo" />
      <div className={styles.textContainer}>
        <span className={styles.lightText}>Powered by </span>
        <span className={styles.darkText}>Hedera Blockchain</span>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { regionSaga } from "./saga";
import { sliceKey, reducer, actions } from "./slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "./selectors";

export default function SelectCountry({ countryName, handleCountryChange }) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: regionSaga });

  const [country, setCountry] = useState({ id: "", label: countryName });

  const countries = useSelector(selectors.countries);

  const dispatch = useDispatch();

  useEffect(() => {
    let country =
      countries &&
      countries.length &&
      countries.reduce((accCountry, currCountry) => {
        if (currCountry.label === countryName) {
          accCountry = { ...currCountry };
        }
        return accCountry;
      }, {});

    if (country) setCountry(country);
    
  }, [countries, countryName]);

  useEffect(() => {
    dispatch(actions.fetchCountries());
  }, [dispatch]);

  return (
    <div>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          padding: "10px",
        }}
      ></div>
      <div>
        <Autocomplete
          value={country?.label && country}
          disablePortal
          disableClearable
          id="countries"
          options={countries || []}
          sx={{ width: "100%" }}
          onChange={handleCountryChange}
          renderInput={(params) => (
            <TextField {...params} label="Country" value={country.label} />
          )}
        />
      </div>
    </div>
  );
}

import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.module.css";
import logos from "../../../../assets/icons/RCFC_LOGO.jpg";
import openLogo from "../../../../assets/icons/Vector.svg";
import Button from "../../shared/button/index";
import Modal from "../modal";
import { useAuth } from "../../../contexts/auth";
import { Link } from "react-router-dom";
import routes from "../../../../routes";
import logo1 from "../../../../assets/icons/jadi buti 2.png";

export default function Topbar() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        includedLanguages: 'en,hi,kn,te,ta,mr,as',
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    // Remove "Powered by Google"
    const removePoweredBy = () => {
      const powe = document.querySelector(".skiptranslate.goog-te-gadget");
      if (powe) {
        if (powe.childNodes[1]) {
          powe.childNodes[1].remove();
        }
        if (powe.childNodes[2]) {
          powe.childNodes[2].remove();
        }
      }
    };

    const intervalId = setInterval(() => {
      if (document.querySelector(".skiptranslate.goog-te-gadget")) {
        removePoweredBy();
        clearInterval(intervalId);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const checkSkiptranslate = () => {
    const skiptranslateDiv = document.querySelector("div.skiptranslate");
    const root = document.getElementById("root");

    if (skiptranslateDiv && root && skiptranslateDiv.nextElementSibling === root) {
      document.body.style.top = "40px";
    } else {
      document.body.style.top = "0px";
    }
  };

  useEffect(() => {
    checkSkiptranslate();
    const observer = new MutationObserver(() => checkSkiptranslate());
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);


  const auth = useAuth();
  const user = auth.loggedInUser;

  const [openLinks, setOpenLinks] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const linkRef = useRef(null);
  const mobileMenuRef = useRef(null);  // Ref for the mobile menu
  const hamburgerRef = useRef(null);   // Ref for the hamburger button

  const getModalStyle = () => {
    const height = linkRef.current.clientHeight;
    const width = linkRef.current.clientWidth;
    const offsetTop = linkRef.current.offsetTop;
    const offsetLeft = linkRef.current.offsetLeft;
    const left = offsetLeft + width / 2;
    const top = offsetTop + height + 40;
    return {
      left: `${left}px`,
      top: `${top}px`,
      transform: "translate(-50%, 0)",
      width: `${width * 1.4}px`,
      padding: "6px",
      borderRadius: "6px",
    };
  };

  const handleClose = () => {
    setOpenLinks(false);
  };

  // Close the mobile menu when clicking anywhere on the page
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside the mobile menu and hamburger button
      if (
        isMobileMenuOpen &&
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <div className={styles.topbar}>
      <div className={styles.logo}>
        <Link to="/">
          <img className="logo1" src={logo1} alt="logos" height="54px" width="187px" style={{ marginTop: "10px" }} />
          <img className="logo2" src={logos} alt="logos" height="41px" width="42px" />
        </Link>
      </div>

      <div
        ref={hamburgerRef}  // Reference to hamburger button
        style={{ backgroundColor: "green", color: "white", height: "40px" }}
        className={`${styles.hamburger} ${isMobileMenuOpen ? styles.open : ""}`}
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        {isMobileMenuOpen ? (
          <>
            <span></span>
            <span></span>
            <span></span>
          </>
        ) : (
          "Menu"
        )}
      </div>

      <div
        ref={mobileMenuRef}  // Reference to mobile menu
        className={`${styles.right} ${isMobileMenuOpen ? styles.showMobileMenu : ""}`}
      >
        <div className={styles.links}>
        <Link
            to="/"
            className={styles.aUnset}
            onClick={() => setIsMobileMenuOpen(false)} // Auto-close menu
          >
            <span>Home</span>
          </Link>
          <Link
            to="/auctions"
            className={styles.aUnset}
            onClick={() => setIsMobileMenuOpen(false)} // Auto-close menu
          >
            <span>Auctions</span>
          </Link>
                 {/* Mandi */}
          <Link
            to="/mandi"
            className={styles.aUnset}
            onClick={() => setIsMobileMenuOpen(false)} // Auto-close menu
          >
            <span>Mandi</span>
          </Link>
           {/* Mandi */}
          <Link
            to="/about"
            className={styles.aUnset}
            onClick={() => setIsMobileMenuOpen(false)} // Auto-close menu
          >
            <span>About Us</span>
          </Link>

          <span
            style={{ position: "relative" }}
            onClick={() => {
              setOpenLinks(!openLinks);
              setIsMobileMenuOpen(false); // Auto-close menu
            }}
            ref={linkRef}
          >
            Knowledge Resources
            <button>
              <img src={openLogo} alt="open" />
            </button>
            {openLinks ? (
              <Modal
                modalStyle={getModalStyle()}
                showModal={openLinks}
                closeModal={handleClose}
                closeButton={false}
                enableBackground={true}
              >
                <div className={styles.modalLinks + " navmodal"}>
                  <Link
                    to="/knowMore/aboutMedicinalPlants"
                    onClick={() => {
                      handleClose();
                      setIsMobileMenuOpen(false); // Auto-close menu
                    }}
                  >
                    Medicinal plants
                  </Link>
                  <Link
                    to="/knowMore/agroTech"
                    onClick={() => {
                      handleClose();
                      setIsMobileMenuOpen(false); // Auto-close menu
                    }}
                  >
                    <span>Agro-techniques</span>
                  </Link>
                </div>
              </Modal>
            ) : null}
          </span>

          <p className={styles.gle} id="glc">
            <div id="google_translate_element"></div>
          </p>
        </div>

        {user ? (
          <div className={styles.buttonContainer}>
            {user.role === "seller" && (
              <Link to="/dashboard/seller/landing">
                <Button outline text="Dashboard" />
              </Link>
            )}
            {user.role === "buyer" && (
              <Link to="/dashboard/buyer/landing">
                <Button outline text="Dashboard" />
              </Link>
            )}
            {user.role === "super_admin" && (
              <Link to={routes.dashboard.admin.auctions}>
                <Button outline text="Dashboard" />
              </Link>
            )}
            <Button solid text="Logout" buttonClick={auth.logout} />
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            <Link
              className={styles.aUnset}
              to="/login?l=t"
              onClick={() => setIsMobileMenuOpen(false)} // Auto-close menu
            >
              <Button outline text="Login" />
            </Link>
            <Link
              className={styles.aUnset}
              to="/register?l=t"
              onClick={() => setIsMobileMenuOpen(false)} // Auto-close menu
            >
              <Button solid text="Register" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProfileSaga } from "../saga";
import { sliceKey, reducer, actions } from "../slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "../selectors";
import { Col, Row, Form, Alert } from "react-bootstrap";
import editIcon from "../../../../../assets/icons/edit_icon.svg";
import Button from "../../../shared/button";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";
import Box from "@mui/material/Box";
import FileIcon from "../../../../../assets/icons/file.svg";
import { Link } from "react-router-dom";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import { useAuth } from "../../../../contexts/auth";
import { toast } from "react-toastify";

const containerStyle = {
  border: "2px dashed #E2E8F0",
  backgroundColor: "#F7FAFC",
  height: "98px",
  paddingLeft: "30px",
  paddingTop: "25px",
  borderRadius: "6px",
};
const fileContainerStyle = {
  border: "1px solid #EDF2F7",
  borderRadius: "6px",
  height: "48px",
  paddingTop: "12px",
  paddingLeft: "15px",
  paddingRight: "15px",
};

const BankDetail = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myProfileSaga });

  const {
    accountNumber,
    bankName,
    branchName,
    cancelledChequeUrl,
    ifsc,
    accountHolderName,
  } = useSelector(selectors.bankDetails);
  const { role } = useSelector(selectors.accountDetails);
  const [newBankName, setNewBankName] = useState("");
  const [newBranch, setNewBranch] = useState("");
  const [newAccountNumber, setNewAccountNumber] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [newIfsc, setNewIfsc] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [newAccountHolder, setNewAccountHolder] = useState("");

  const dispatch = useDispatch();
  const auth = useAuth();

  // Error state variables
  const [errors, setErrors] = useState({
    accountHolder: "",
    bankName: "",
    branch: "",
    accountNumber: "",
    ifsc: "",
    cancelledCheque: "",
  });

  // Utility function to check if a string contains only letters and spaces
  const isLettersOnly = (str) => /^[A-Za-z\s]+$/.test(str);

  useEffect(() => {
    setNewBankName(bankName);
    setNewBranch(branchName);
    setNewIfsc(ifsc);
    setNewAccountNumber(accountNumber);
    setNewAccountHolder(accountHolderName);
  }, [bankName, branchName, ifsc, accountNumber, accountHolderName]);

  // Handler to allow only letters and spaces
  const handleLettersOnly = (e) => {
    const char = String.fromCharCode(e.which);
    if (!/[A-Za-z\s]/.test(char)) {
      e.preventDefault();
    }
  };

  const handleAccountHolderChange = (e) => {
    const value = e.target.value;
    setNewAccountHolder(value);
    if (errors.accountHolder) {
      setErrors((prev) => ({ ...prev, accountHolder: "" }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files;
    setFileToUpload(file);
    if (errors.cancelledCheque) {
      setErrors((prev) => ({ ...prev, cancelledCheque: "" }));
    }
  };
  const handleInputClick = () => {
    document.getElementById("input_file").click();
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
    // Reset errors when toggling edit mode
    setErrors({
      accountHolder: "",
      bankName: "",
      branch: "",
      accountNumber: "",
      ifsc: "",
      cancelledCheque: "",
    });
  };

  const handleIfscChange = (e) => {
    const value = e.target.value;
    setNewIfsc(value);
    if (errors.ifsc) {
      setErrors((prev) => ({ ...prev, ifsc: "" }));
    }
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value;
    setNewAccountNumber(value);
    if (errors.accountNumber) {
      setErrors((prev) => ({ ...prev, accountNumber: "" }));
    }
  };

  const handleBranchChange = (e) => {
    const value = e.target.value;
    setNewBranch(value);
    if (errors.branch) {
      setErrors((prev) => ({ ...prev, branch: "" }));
    }
  };

  const handleBankNameChange = (e) => {
    const value = e.target.value;
    setNewBankName(value);
    if (errors.bankName) {
      setErrors((prev) => ({ ...prev, bankName: "" }));
    }
  };

  // Validation functions
  const validateAccountHolder = (name) => {
    if (!name || name.trim() === "") {
      return "Account holder name is required.";
    } else if (!isLettersOnly(name.trim())) {
      return "Account holder name can contain only letters and spaces.";
    }
    return "";
  };

  const validateBankName = (name) => {
    if (!name || name.trim() === "") {
      return "Bank name is required.";
    } else if (!isLettersOnly(name.trim())) {
      return "Bank name can contain only letters and spaces.";
    }
    return "";
  };

  const validateBranch = (branch) => {
    if (!branch || branch.trim() === "") {
      return "Branch name is required.";
    } else if (!isLettersOnly(branch.trim())) {
      return "Branch name can contain only letters and spaces.";
    }
    return "";
  };

  const validateAccountNumber = (number) => {
    const regex = /^[0-9]{9,18}$/; // Adjust length as per your requirements
    if (!number || number.trim() === "") {
      return "Account number is required.";
    } else if (!regex.test(number)) {
      return "Account number must be between 9 to 18 digits.";
    }
    return "";
  };

  const validateIFSC = (ifsc) => {
    const regex = /^[A-Za-z]{4}0[A-Z0-9]{6}$/; // Standard IFSC format
    if (!ifsc || ifsc.trim() === "") {
      return "IFSC code is required.";
    } else if (!regex.test(ifsc)) {
      return "Invalid IFSC code format.";
    }
    return "";
  };

  const validateCancelledCheque = (file) => {
    if (file) {
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      if (!allowedTypes.includes(file[0].type)) {
        return "Unsupported file type. Allowed types: PDF, JPEG, PNG.";
      }
      // Optional: Check file size (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file[0].size > maxSize) {
        return "File size exceeds 5MB.";
      }
    }
    return "";
  };

  const handleSubmit = () => {
    // Initialize a new errors object
    const newErrors = {
      accountHolder: validateAccountHolder(newAccountHolder),
      bankName: validateBankName(newBankName),
      branch: validateBranch(newBranch),
      accountNumber: validateAccountNumber(newAccountNumber),
      ifsc: validateIFSC(newIfsc),
      cancelledCheque: validateCancelledCheque(fileToUpload),
    };

    // Update the error state
    setErrors(newErrors);

    // Check if there are any errors
    const isValid = Object.values(newErrors).every((error) => error === "");

    if (!isValid) {
      toast.error("Please fix the errors in the form.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    // If valid, proceed with dispatch
    const payload = {
      data: {
        id: auth.loggedInUser._id,
        bankName: newBankName,
        branchName: newBranch,
        ifsc: newIfsc,
        accountNumber: newAccountNumber,
        accountHolderName: newAccountHolder,
      },
      onSuccess: () => {
        setEditMode(false);
        const payload = {
          id: auth.loggedInUser._id,
        };
        dispatch(actions.fetchProfileDetails(payload));
        auth.updateLoggedInUser();

        toast.success("Bank details have been updated successfully", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      },
    };

    if (fileToUpload) payload.data.cancelledChecque = fileToUpload;

    dispatch(actions.updateBankDetails(payload));
  };

  if (!editMode) {
    return (
      <>
        <Col lg={10}>
          <h5>
            Bank details
            {(!bankName &&
              !branchName &&
              !ifsc &&
              !accountNumber &&
              !accountHolderName) &&
              (role === "seller" ? (
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  (Bank details mandatory to create auction){" "}
                </span>
              ) : (
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  (Bank details mandatory to bid on auction){" "}
                </span>
              ))}
          </h5>
        </Col>
        <div
          style={{
            width: "32px",
            cursor: "pointer",
            position: "absolute",
            right: "24px",
            top: "20px",
          }}
          onClick={handleEditMode}
        >
          <div
            style={{
              width: "24px",
            }}
          >
            <img src={editIcon} alt="edit" style={{ width: "100%" }} />
          </div>
        </div>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="bank_name">
            <Form.Label>
              <span className="headingText">Account holder</span>
            </Form.Label>
            <h6 style={{ textTransform: "capitalize" }}>{newAccountHolder}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="bank_name">
            <Form.Label>
              <span className="headingText">Bank</span>
            </Form.Label>
            <h6 style={{ textTransform: "capitalize" }}>{bankName}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="branch_name">
            <Form.Label>
              <span className="headingText">Branch</span>
            </Form.Label>

            <h6 style={{ textTransform: "capitalize" }}>{branchName}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="acc_number">
            <Form.Label>
              <span className="headingText">Account number</span>
            </Form.Label>

            <h6>{accountNumber}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="ifsc">
            <Form.Label>
              <span className="headingText">IFSC code</span>
            </Form.Label>
            <h6>{ifsc}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-3">
          <Form.Group controlId="c_cheque">
            <Form.Label>
              <span className="headingText">Cancelled cheque</span>
            </Form.Label>
            <div>
              {cancelledChequeUrl ? (
                <a
                  href={cancelledChequeUrl}
                  style={{ textDecoration: "none", color: "#38A169" }}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  cancel_cheque.pdf
                </a>
              ) : (
                <button
                  style={{
                    textDecoration: "none",
                    color: "#38A169",
                    background: "none",
                    border: "none",
                    outline: "none",
                  }}
                >
                  No cancelled cheque found
                </button>
              )}
            </div>
          </Form.Group>
        </Col>
      </>
    );
  }
  return (
    <>
      <Col lg={10}>
        <h5>Bank details</h5>
      </Col>
      <div
        style={{
          width: "32px",
          cursor: "pointer",
          position: "absolute",
          right: "24px",
          top: "20px",
        }}
        onClick={handleEditMode}
      >
        <div
          style={{
            width: "16px",
          }}
        >
          <img src={closeIcon} alt="close" style={{ width: "100%" }} />
        </div>
      </div>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="accountHolder">
          Account holder
        </label>
        <Form.Control
          type="text"
          name="accountHolder"
          value={newAccountHolder}
          onChange={handleAccountHolderChange}
          onKeyPress={handleLettersOnly} // Restrict input
          isInvalid={!!errors.accountHolder}
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
        />
        <Form.Control.Feedback type="invalid">
          {errors.accountHolder}
        </Form.Control.Feedback>
      </Col>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="bankName">
          Bank
        </label>
        <Form.Control
          type="text"
          name="bankName"
          value={newBankName}
          onChange={handleBankNameChange}
          onKeyPress={handleLettersOnly} // Restrict input
          isInvalid={!!errors.bankName}
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
        />
        <Form.Control.Feedback type="invalid">
          {errors.bankName}
        </Form.Control.Feedback>
      </Col>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="branch">
          Branch
        </label>
        <Form.Control
          type="text"
          name="branch"
          value={newBranch}
          onChange={handleBranchChange}
          onKeyPress={handleLettersOnly} // Restrict input
          isInvalid={!!errors.branch}
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
        />
        <Form.Control.Feedback type="invalid">
          {errors.branch}
        </Form.Control.Feedback>
      </Col>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="acc_number">
          Account Number
        </label>
        <Form.Control
          type="text"
          name="acc_number"
          value={newAccountNumber}
          onChange={handleAccountNumberChange}
          isInvalid={!!errors.accountNumber}
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
        />
        <Form.Control.Feedback type="invalid">
          {errors.accountNumber}
        </Form.Control.Feedback>
      </Col>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="ifsc">
          IFSC Code
        </label>
        <Form.Control
          type="text"
          name="ifsc"
          value={newIfsc}
          onChange={handleIfscChange}
          isInvalid={!!errors.ifsc}
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
        />
        <Form.Control.Feedback type="invalid">
          {errors.ifsc}
        </Form.Control.Feedback>
      </Col>
      <div style={{ marginTop: "8px" }}>
        <Box style={containerStyle}>
          <input
            type="file"
            name=""
            id="input_file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".pdf, image/jpeg, image/png"
          />
          <div style={{ display: "flex", gap: 2, marginLeft: "36px" }}>
            <div
              style={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Drag and drop files or
            </div>
            <div
              style={{
                color: "#38A169",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
              onClick={handleInputClick}
            >
              Browse
            </div>
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
            }}
          >
            Supported formats: JPEG, JPG, PNG, PDF
          </div>
        </Box>
        {errors.cancelledCheque && (
          <Form.Text className="text-danger">
            {errors.cancelledCheque}
          </Form.Text>
        )}
        {fileToUpload && (
          <div style={{ marginTop: "8px" }}>
            <Box style={fileContainerStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 4 }}>
                  <div>
                    <img src={FileIcon} alt="file" />
                  </div>

                  <div
                    style={{
                      color: "#1A202C",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "2px",
                    }}
                  >
                    {fileToUpload[0].name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setFileToUpload(null);
                    setErrors((prev) => ({ ...prev, cancelledCheque: "" }));
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>
      <Col lg={6}></Col>
      <Col lg={6}>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            padding: "16px 0 8px 0",
          }}
        >
          <Button
            type="submit"
            text="Update"
            solid={true}
            buttonClick={handleSubmit}
          />
        </div>
      </Col>
    </>
  );
};

export default BankDetail;

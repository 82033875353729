import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Tabs, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import rightArrow from "../../../../assets/dashboardImages/rightArrow.svg";
import styles from "./style.module.css";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions, initialState } from "./slice";
import { MandisSaga } from "./saga";
import * as selectors from "./selectors";
import { toastSuccess } from "../../../utils/toastHelper";
// import Mandi from "../../home/mandi";
const MandiEdit = () => {
  const navigate = useNavigate();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: MandisSaga });

  const Mandi = useSelector(selectors.Mandi) || {};
  // const userDocument = userDetail.document;
const location = useLocation()
// const changeMandiData = location.state?.changeMandiData;
//  console.log("mandiadd child",changeMandiData)
  // Now, you can use `changeMandiData` to update data in the parent component

  const dispatch = useDispatch();
  const [mandiData, setMandiData] = useState({});
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.debug("🚀 ~ file: index.js:35 ~ useEffect ~ mandiData:", mandiData)
  }, [mandiData]);
  useEffect(() => {
    if (Mandi?.botanicalName) {
      setFormData({
        botanicalName: Mandi?.botanicalName,
        tradeName: Mandi?.tradeName,
        parts: Mandi?.parts[0],
        // rates: Mandi?.rates,
        amritsar: Mandi.rates.amritsar,
        delhi: Mandi.rates.delhi,
        saharanpur: Mandi.rates.saharanpur,
      });
    }
    setMandiData(Mandi)
  }, [Mandi]);

  const handleChangeSelect = (event, name) => {
    if (event == null) {
      setFormData((prevData) => ({ ...prevData, [name]: null }));
    }
    if (event && event.value != "") {
      setFormData((prevData) => ({ ...prevData, [name]: event.value }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  useEffect(() => {
    let url = new URL(window.location.href);
    const linkId = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
    if (linkId != "add") {
      dispatch(actions.fetchMandiById(linkId));
    }else{
      dispatch(actions.resetMandi());
    }
  }, [dispatch]);

 
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    if (!formData.botanicalName) {
      errors.BotanicalName = "Name is required";
    }
    if (!formData.tradeName) {
      errors.tradeName = "TradeName is required";
    }
    if (!formData.parts) {
      errors.Parts = "Parts is required";
    }
    if (!formData.amritsar) {
      errors.amritsar = "Amritsar is required";
    }
    if (!formData.delhi) {
      errors.delhi = "Delhi is required";
    }
    if (!formData.saharanpur) {
      errors.saharanpur = "Delhi is Saharanpur";
    }
   
    
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    // return;
    let rates = {
      amritsar: formData.amritsar,
      delhi: formData.delhi,
      saharanpur: formData.saharanpur,
    }
    let mandiFormData ={
      botanicalName:formData.botanicalName,
      tradeName:formData.tradeName,
      parts:formData.parts,
      rates:rates,
    }
    console.debug("🚀 ~ file: index.js:110 ~ handleSubmit ~ Mandi?.name:", mandiData)
    if (mandiData?._id) {
      mandiFormData.id = mandiData?._id
      const payload = {
        data: mandiFormData,
        onSuccess: () => {
          toastSuccess("Mandi updated successfully");
          setFormData({});
          setErrors({});
          navigate("/dashboard/admin/mandi");
        },
      };
      dispatch(actions.updateMandi(payload));
    } else {
      const payload = {
        data: {
          ...mandiFormData
        },
        onSuccess: () => {
          toastSuccess("Mandi created successfully");
          setFormData({});
          setErrors({});
          navigate("/dashboard/admin/mandi");
        },
      };
      dispatch(actions.createMandi(payload));
    }
  };

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <h3 className="formheadingText"> {mandiData?._id ? "Edit" : "Add"} Mandi</h3>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="BotanicalName">
            <Form.Label>
              <span className="headingText">Botanical Name</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="botanicalName"
              value={formData.botanicalName}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.BotanicalName && "is-invalid"} ${
                errors.BotanicalName === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.BotanicalName && "invalid-feedback"} `}>
              {!!errors.BotanicalName && errors.BotanicalName}
            </small>
          </Form.Group>
        </Col>



        <Col lg={3}>
          <Form.Group controlId="tradeName">
            <Form.Label>
              <span className="headingText">	Trade Name</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="tradeName"
              value={formData.tradeName}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.tradeName && "is-invalid"} ${
                errors.tradeName === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.name && "invalid-feedback"} `}>
              {!!errors.name && errors.name}
            </small>
          </Form.Group>
        </Col>

        <Col lg={3}>
          <Form.Group controlId="Parts">
            <Form.Label>
              <span className="headingText">	Parts</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="parts"
              value={formData.parts}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.Parts && "is-invalid"} ${
                errors.Parts === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.Parts && "invalid-feedback"} `}>
              {!!errors.Parts && errors.Parts}
            </small>
          </Form.Group>
        </Col>

        <Col lg={3}>
          <Form.Group controlId="amritsar">
            <Form.Label>
              <span className="headingText">	Amritsar</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="amritsar"
              value={formData?.amritsar}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.amritsar && "is-invalid"} ${
                errors.amritsar === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.amritsar && "invalid-feedback"} `}>
              {!!errors.amritsar && errors.amritsar}
            </small>
          </Form.Group>
        </Col>
        

        <Col lg={3}>
          <Form.Group controlId="delhi">
            <Form.Label>
              <span className="headingText">	Delhi</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="delhi"
              value={formData?.delhi}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.delhi && "is-invalid"} ${
                errors.delhi === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.delhi && "invalid-feedback"} `}>
              {!!errors.delhi && errors.delhi}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="saharanpur">
            <Form.Label>
              <span className="headingText">	Saharanpur</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="saharanpur"
              value={formData?.saharanpur}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.saharanpur && "is-invalid"} ${
                errors.saharanpur === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.saharanpur && "invalid-feedback"} `}>
              {!!errors.saharanpur && errors.saharanpur}
            </small>
          </Form.Group>
        </Col>
       

        {/* <Col lg={3}>
          <Form.Group controlId="link">
            <Form.Label>
              <span className="headingText">link</span>
            </Form.Label>
            <Form.Control
              type="url"
              name="link"
              value={formData.link}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.link && "is-invalid"} ${
                errors.link === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.link && "invalid-feedback"} `}>
              {!!errors.link && errors.link}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="type">
            <Form.Label>
              <span className="headingText">Link type</span>
            </Form.Label>
            <ReactSelect
              value={{ value: formData.type, label: formData.type }}
              onChange={(e) => {
                handleChangeSelect(e, "type");
              }}
              options={[
                { value: "whatsNew", label: "What's New" },
                { value: "relatedLinks", label: "Related Links" },
              ]}
              placeholder="Select a type"
              name="type"
              className={`form-control ${errors.type && "is-invalid"} ${
                errors.type === false && "is-valid"
              }`}
            />
            <small className={`${errors.type && "invalid-feedback"} `}>
              {!!errors.type && errors.type}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="status">
            <Form.Label>
              <span className="headingText">Status</span>
            </Form.Label>
            <ReactSelect
              value={{
                value: formData.status,
                label: formData.status,
              }}
              onChange={(e) => {
                handleChangeSelect(e, "status");
              }}
              options={[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" },
              ]}
              placeholder="Select a status"
              name="status"
              className={`form-control ${errors.status && "is-invalid"} ${
                errors.status === false && "is-valid"
              }`}
            />
            <small className={`${errors.status && "invalid-feedback"} `}>
              {!!errors.status && errors.status}
            </small>
          </Form.Group>
        </Col> */}
      </Row>

      <Row>
        <Col lg={12} className="mt-5">
          <div className="float-end">
          <Button
              variant="danger"
              style={{ marginLeft: "1em" }}
              onClick={()=> navigate("/dashboard/admin/mandi")}
            >
              Back
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MandiEdit;

import React from "react";
import styles from "./styles.module.css";
import searchIcon from "../../../../assets/icons/searchIcon.svg";

export default function SearchInput({ searchString, updateSearchString }) {
  return (
    <div className={styles.searchInput}>
      <div className={styles.iconContainer}>
        <img src={searchIcon} alt="Search Icon" />
      </div>
      <input
        type="text"
        value={searchString}
        onChange={(e) => updateSearchString(e.target.value)}
      />
    </div>
  );
}

import React from "react";
import styles from "./auctionCard.module.css";
import { Card } from 'react-bootstrap';

const AuctionCard=(props)=>{
    return (
        <>
            <Card className={styles.card}>
                <Card.Body className={styles.cardBody}>
                    <Card.Subtitle className={styles.auctionTitle}>{props.title}</Card.Subtitle>
                    <Card.Title className={styles.auctionSubtitle}>{props.subtitle.toString().padStart(2, '0')}</Card.Title>
                </Card.Body>
            </Card>
        </>
    );
}
export default AuctionCard;
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  awardPendingdAuctions:{auctions:[],totalRecords:0},
};

const awardedPendingLiveAuctions = createSlice({
  name: "buyerAwardedPendingAuctions",
  initialState,
  reducers: {
    fetchAwaredPendingAuctions(state, action) {
      state.loading = true;
    },
    fetchAwaredPendingAuctionsSuccess(state, action) {
      state.awardPendingdAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchAwaredPendingAuctionsFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = awardedPendingLiveAuctions;

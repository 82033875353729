import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";

export function* handleFetchDraftedAuctions({ payload }) {
  try {
    const res = yield call(API.fetchSellerDraftedAuctionsData, payload);
    const parsedObject = JSON.parse(res.data);
    const { data } = parsedObject;
    yield put(actions.fetchDraftedAuctionsSuccess(data));
  } catch (err) {
    yield put(actions.fetchDraftedAuctionsFailure(err?.response?.data));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* labListingSaga() {
  yield takeLatest(
    actions.fetchDraftedAuctions.type,
    handleFetchDraftedAuctions
  );
}

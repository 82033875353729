import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import routes from "../../../routes";

export default function RedirectToHome() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(routes.home.landing);
  }, [navigate]);
  return <></>;
}

import React, { useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from './styles.module.css';
import {contactUsForm} from "../../../../services/apis";
import { toastError, toastSuccess } from '../../../utils/toastHelper';

const ContactForm = () => {
  const formRef = useRef(); // Create a form reference
  const [isDisabled, setIsDisabled] = useState(false); // State to track input disabling
  const [statusMessage, setStatusMessage] = useState(''); // State for submission status

  const initialValues = {
    name: '',
    phone_number: '',
    email: '',
    message: '',
  };


  

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Name can only contain letters')
      .max(50, 'Name cannot be longer than 50 characters')
      .required('Name is required'),
    phone_number: Yup.string()
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .required('Please enter your phone number'),
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Email is required'),
    // message: Yup.string().required('Message is required'),
    email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email format'
    )
    .required('Email is required'),
  message: Yup.string().required('Message is required'),

  });

  const onSubmit = async (values, { resetForm }) => {   
    // setIsDisabled(true); // Disable inputs during submission
    // setStatusMessage('Submitting...'); // Set status message
    try {
      let payload = {
        name: values.name,
        phone: values.phone_number,
        email: values.email,
        message: values.message
      }
      let res = await contactUsForm(payload)
      const data = JSON.parse(res.data);    
      if (data.status === 200) {
        toastSuccess('Thank you for contacting us. We will get back to you soon.');
      } else {
        toastError('Error submitting form. Please try again later.');
      }
      resetForm();
    }catch (err) {
      console.log(err);
    }

  };

  return (
    <div className={styles.formContainer}>
      <h2>Contact Us</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={formRef} // Add innerRef to the Formik form for formRef to work
      >
        {() => (
          <Form id="contactForm" className={styles.form} ref={formRef}>
            <div className={styles.formGroup}>
              <label htmlFor="name">Name</label>
              <Field
                type="text"
                id="name"
                name="name"
                className={styles.input}
                disabled={isDisabled}
              />
              <ErrorMessage name="name" component="p" className={styles.error} />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="phone_number">Phone Number</label>
              <Field
                type="text"
                id="phone_number"
                name="phone_number"
                className={styles.input}
                maxLength="10"
                disabled={isDisabled}
              />
              <ErrorMessage name="phone_number" component="p" className={styles.error} />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                id="email"
                name="email"
                className={styles.input}
                disabled={isDisabled}
              />
              <ErrorMessage name="email" component="p" className={styles.error} />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="message">Message</label>
              <Field
                as="textarea"
                id="message"
                name="message"
                spellCheck="false"
                className={styles.textarea}
                disabled={isDisabled}
              />
              <ErrorMessage name="message" component="p" className={styles.error} />
            </div>

            <button type="submit" className={styles.submitButton} disabled={isDisabled}>
              Submit
            </button>

            {statusMessage && (
              <div className={styles.statusMessage}>
                {statusMessage}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;

import React, {  useState } from 'react';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import SImage from '../../../shared/Image';
import styles from './sidebar.module.css';
import dasboardImg from '../../../../../assets/dashboardImages/dashboard.svg';
import proImg from '../../../../../assets/dashboardImages/profile.svg';
import liveImg from '../../../../../assets/dashboardImages/live.svg';
import closeImg from '../../../../../assets/dashboardImages/close.svg';
import downImg from '../../../../../assets/dashboardImages/downa.svg';
import cancelImg from '../../../../../assets/dashboardImages/cancel.svg';
import eyeImg from '../../../../../assets/dashboardImages/eye.svg';
import createImg from '../../../../../assets/dashboardImages/create.svg';
import draftImg from '../../../../../assets/dashboardImages/draft.svg';

const SellerDashboardSidebar = ({ isOpen, toggleSidebar }) => {
  const [showOpen, setIsOpen] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false); // For dropdown control
  
  const handleNavClick = () => {
    const BrowserWidth = window.innerWidth;
    if (BrowserWidth < 991) {
      toggleSidebar();
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown((prevState) => !prevState); // Toggle dropdown visibility
  };

  const sidebarStyles = {
    height: '100vh',
    width: '250px',
    backgroundColor: '#171923',
    position: 'fixed',
    top: '0',
    left: isOpen ? '0' : '-250px',
    transition: 'all 0.3s ease-out',
    padding: '20px',
    paddingTop: '6em',
    zIndex: '0'
  };

  const location = window.location.pathname;

  return (
    <div  style={sidebarStyles} className={`userDashboard ${!showOpen && "ml-6"}`}>
      <Nav defaultActiveKey="/dashboard/seller/landing" className={[styles.sidenav, "flex-column"].join(' ')}>
        <Link onClick={handleNavClick} className={(location === "/dashboard/seller/landing") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/landing">
          <SImage avatar={dasboardImg} pclass={styles.iconImg}/>Dashboard
        </Link>
        <Link onClick={handleNavClick} className={(location === "/dashboard/seller/profile") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/profile">
          <SImage avatar={proImg} pclass={styles.iconImg}/>My profile
        </Link>
        <Link onClick={handleNavClick} className={(location === "/dashboard/seller/create-auction") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/create-auction">
          <SImage avatar={createImg} pclass={styles.iconImg}/>Create auction
        </Link>
        <Link onClick={handleNavClick} className={(location === "/dashboard/seller/live-auctions") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/live-auctions">
          <SImage avatar={liveImg} pclass={styles.iconImg}/>Live auctions
        </Link>
        <Link onClick={handleNavClick} className={(location === "/dashboard/seller/inreview-auctions") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/inreview-auctions">
          <SImage avatar={eyeImg} pclass={styles.iconImg}/>In-review auctions
        </Link>
        <Link onClick={handleNavClick} className={(location === "/dashboard/seller/reject-auctions") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/reject-auctions">
          <SImage avatar={eyeImg} pclass={styles.iconImg}/>Rejected auctions
        </Link>
        
        <Link onClick={handleDropdownToggle} className='nav-link' to="#">
          <SImage avatar={closeImg} pclass={styles.iconImg}/>Closed auctions 
          <SImage avatar={downImg} pclass={styles.iconImg2}/>
        </Link>

        {showDropdown && (
          <div style={{ paddingLeft: '20px' }}>
            <Link onClick={handleNavClick} className={(location === "/dashboard/seller/awarded-auctions") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/awarded-auctions">Awarded auction</Link>
            <Link onClick={handleNavClick} className={(location === "/dashboard/seller/pending-awards") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/pending-awards">Pending awards</Link>
          </div>
        )}

        <Link onClick={handleNavClick} className={(location === "/dashboard/seller/cancelled-auctions") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/cancelled-auctions">
          <SImage avatar={cancelImg} pclass={styles.iconImg}/>Cancelled auctions
        </Link>
        <Link onClick={handleNavClick} className={(location === "/dashboard/seller/draft-auctions") ? 'nav-link active' : 'nav-link'} to="/dashboard/seller/draft-auctions">
          <SImage avatar={draftImg} pclass={styles.iconImg}/>Draft auctions
        </Link>
      </Nav>
    </div>
  );
};

export default SellerDashboardSidebar;

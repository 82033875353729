import React from "react";
import successIcon from "../../../../../assets/icons/success.svg";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import * as selectors from "../selectors";
import { Link } from 'react-router-dom';


export default function FormSubmittedPage(props) {
  const verifyButtonStyles = {
    root: {
      width: "120px",
      height: "32px",
      borderRadius: "6px",
      backgroundColor: "#E2E8F0 !important",
    },
  };
  const verifySaveAddressDetails = useSelector(selectors.verifySaveAddressDetails);
  const verifySaveBankDetails = useSelector(selectors.verifySaveBankDetails);
  const verifySaveLegalDetails = useSelector(selectors.verifySaveLegalDetails);
  const CustomVerifyButton = withStyles(verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );
  return (
    <>
    
      {(verifySaveAddressDetails &&
      verifySaveBankDetails &&
      verifySaveLegalDetails) ? (
        <>
          <div className={" text-center"}>
        <img src={successIcon} alt="successIcon" />
      </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "32px",
              marginTop: "32px",
            }}
          >
            Form submitted successfully!
          </div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "36px",
            }}
          >
            Thank again!
          </div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "28px",
              marginTop: "36px",
            }}
          >
            We will verify your details and approve your account very soon.
          </div>
        </>
      ) : (
        <>
          <div className={" "}>
        <img src={successIcon} alt="successIcon" />
      </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "32px",
              marginTop: "14px",
            }}
          >
            Welcome! You can explore the platform and access its features.
            However, to participate in auctions as a buyer or create auctions as
            a seller, you need to complete your KYC (Know Your Customer)
            process.
          </div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "18px",
              
              marginTop:"23px",
            }}
          >
             Please complete your KYC to unlock full access to the
             auction features.
          </div>




          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "36px",
              marginBottom:"49px",
              display:"flex",
              justifyContent:"flex-start",
            }}
          >
          <Link  to="/login">
            <p  style={{color: "white",
              background: "green",
              padding: "1px 7px 2px 7px",
              borderRadius: "6px",
              position: "absolute",
              marginTop:"27px",
              fontSize: "17px",
           } } >Complete KYC</p>
          </Link> 
          </div>
        </>
      )}


      {/* <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "28px",
          marginTop: "24px",
        }}
      >
        If you made any mistakes while submitting the form, you can resubmit it
        by clicking the button below. You can also refresh the page to access
        the form again
      </div>
      <div style={{ marginTop: "24px" }}>
        <CustomVerifyButton onClick={props.backToRegistrationPage}>
          <div
            style={{
              color: "#1A202C",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Re-Submit
          </div>
        </CustomVerifyButton>
      </div> */}
    </>
  );
}

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js';
import styles from "./style.module.css";
Chart.register(ArcElement);

const BuyerDoughnutChart = () => {
  const awardAuction = 7;
  const data = {
    labels: [ 'Won', 'Lost'],
    datasets: [
      {
        data: [20, 10],
        backgroundColor: ['#38A169','#CBD5E0'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
        title: {
          display: false,
        },
        legend: {
          padding: '0px 0px 0px 10px',
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            pointStyle:'circle',
            usePointStyle: true,
            textAlign: 'left',
            boxWidth: 5,
            boxHeight: 5
          },
        },
      },
      responsive: true,
    cutout: '70%',
    height: 300,
  };

  return (
    <div className={styles.doughnutChartContainer}>
      <div className={styles.doughnutChartContent} style={{position: 'relative', height:'40vh', width:'20vw'}}>
        <Doughnut data={data} options={options} />
        <div className={styles.centerText}><div className={styles.hText}>{awardAuction}</div> <div className={styles.subtext}>Awarded auctions</div></div>
      </div>
    </div>
  );
};

export default BuyerDoughnutChart;

import React, { useState} from "react";
import { Table, Badge } from 'react-bootstrap';
import styles from "./style.module.css";
import PlaceBid from "../PlaceBidInputButton";
import { formattedDate,formattedDateTime } from "../../../utils/helper";

const MyBid = ({listHeading, listData, auctionDetail}) => {    
    let arrayForSort = [];
    if(listData.length){
        arrayForSort = [...listData]
        arrayForSort.sort(
            (p1, p2) => 
            (p1.amount < p2.amount) ? 1 : (p1.amount > p2.amount) ? -1 : 0);
    }
    
    
    
  return (
    <>
        <Table responsive>
            <thead style={{backgroundColor: '#F7FAFC'}}>
                {listHeading.map((auction, index) => (
                    <tr key={index}>
                        <td className={styles.headT}>{auction.date}</td>
                        <td className={styles.headT}>{auction.mybid}</td>
                    </tr>
                    ))}
            </thead>
            <tbody>
                {arrayForSort.map((bid, index) => (
                    <tr key={index}>
                        <td className={styles.dataT}>{formattedDateTime(bid.createdAt)}</td>
                        <td className={styles.dataT}>₹{bid.amount}
                        { auctionDetail.status === 'awarded' && auctionDetail.awardedBid === bid._id && 
                            <Badge className={[styles.wonBadge].join(' ')}>
                            Won
                            </Badge> 
                        }
                       </td>                      
                    </tr>
                ))}
            </tbody>
        </Table>   
    </>
  );
};

export default MyBid;

import React from "react";
import styles from "./styles.module.css";

export default function Dropdown({ name, options, updateOption }) {
  return (
    <div className={styles.dropdownContainer}>
      <select
        className={styles.dropdown}
        name={name}
        id={`${name}-select`}
        onChange={updateOption}
      >
        <option value="">{`${name} by`}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchBuyerAuctionDetail({ payload }) {
  
  try {
    const res = yield call(API.fetchBuyerAuctionDetailData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.homeAuctionDetailSuccess(data));
  } catch (err) {
    yield put(actions.homeAuctionDetailFailure(err?.response?.data));
  }
}


export function* handleAwardAuction({ payload }) {  
  try {
    const res = yield call(API.awardAuction, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchAuctionDetail(payload.auctionId));
    yield put(actions.awardAuctionSuccess(data));
  } catch (err) {
    yield put(actions.awardAuctionFailure(err?.response?.data));
  }
}

export function* handleCloseBidOnAuction({ payload }) {  
  try {
    
    const res = yield call(API.closeBidsOnAuction, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchAuctionDetail(payload));
    yield put(actions.closeBidOnAuctionSuccess(data));
  } catch (err) {
    yield put(actions.closeBidOnAuctionFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* labListingSaga() {
  yield takeLatest(actions.fetchAuctionDetail.type, handleFetchBuyerAuctionDetail);
  yield takeLatest(actions.awardAuction.type, handleAwardAuction);
  yield takeLatest(actions.closeBidOnAuction.type, handleCloseBidOnAuction);
  
}

import React, {useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import * as selectors from "../selectors";
import { Col, Row, Form } from "react-bootstrap";
import { myProfileSaga } from "../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions } from "../slice";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import attachImg from "../../../../../assets/dashboardImages/attach.svg";
import editIcon from "../../../../../assets/icons/edit_icon.svg";
import FileIcon from "../../../../../assets/icons/file.svg";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";
import Button from "../../../shared/button";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import { useAuth } from "../../../../contexts/auth";
import { toast } from "react-toastify";

const containerStyle = {
  border: "2px dashed #E2E8F0",
  backgroundColor: "#F7FAFC",
  height: "98px",
  paddingTop: "25px",
  borderRadius: "6px",
};
const fileContainerStyle = {
  border: "1px solid #EDF2F7",
  borderRadius: "6px",
  height: "48px",
  paddingTop: "12px",
  paddingLeft: "15px",
  paddingRight: "15px",
};
const profileData = {
  aadharNumber: "124355983465",
  gst: "24AAACC1206D1ZM",
  pan: "ABCTY1234D",
};

const LegalDoc = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myProfileSaga });
  // const [certLabel, setCertLabel] = useState("legal_cert.pdf");

  const { aadharNumber, gst, pan, aadharUrl, gstUrl, panUrl } = useSelector(
    selectors.legalDetails
  );
  const { role } = useSelector(
    selectors.accountDetails
  );

  const [newaadharNumber, setAadharNumber] = useState("");
  const [newgstNumber, setGstNumber] = useState("");
  const [newpan, setPanNumber] = useState("");
  const [aadharToUpload, setAadharToUplopad] = useState(null);
  const [gstToUpload, setGstToUplopad] = useState(null);
  const [panToUpload, setPanToUplopad] = useState(null);
  const [aadharNumberError, setAadharNumberError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [panNumberError, setPanNumberError] = useState("");
  const [aadharToUploadError, setAadharToUplopadError] = useState(null);
  const [gstToUploadError, setGstToUplopadError] = useState(null);
  const [panToUploadError, setPanToUplopadError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  const auth = useAuth();

  useEffect(() => {
    setAadharNumber(aadharNumber);
    setGstNumber(gst)
    setPanNumber(pan);
   
  }, [aadharNumber, gst, pan, aadharUrl, gstUrl, panUrl]);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleAadharChange = (event) => {
    setAadharNumber(event.target.value);
    setAadharNumberError("");
  };

  const handleGstChange = (event) => {
    setGstNumber(event.target.value);
    setGstNumberError("");
  };
  const handlePanChange = (event) => {
    setPanNumber(event.target.value);
    setPanNumberError("");
  };

  const handleInputClick = (id) => {
    document.getElementById(id).click();
  };

  const checkAadharNumber = (newaadharNumber) => {
    const pattern = /^\d{12}$/;
    return pattern.test(newaadharNumber);
  };
  const checkGSTNumber = (newgstNumber) => {
    const pattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/;
    return pattern.test(newgstNumber);
  };

  const checkPanNumber = (newpan) => {
    const pattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return pattern.test(newpan);
  };

  const handleSubmit = () => {
    if (newaadharNumber.trim() === "") {
      setAadharNumberError("Aadhar number is required.");
       return;
    } else {
      if (checkAadharNumber(newaadharNumber.trim())) {
        setAadharNumberError("");
      } else {
        setAadharNumberError(
          "Invalid Aadhar number. Please enter a 12-digit number."
        );
        return;
      }
    }
    if (newgstNumber.trim() === "") {
      //setGstNumberError("GST number is required.");
      // return;
      /*Commenting the above changes as per requirement raised by Sahil on 05/01/2024 
      to accomodete onboarding of Farmer without gst as a mandatory field*/
    } else {
      if (checkGSTNumber(newgstNumber.trim())) {
        setGstNumberError("");
      } else {
        setGstNumberError(
          "Invalid GST number. Please enter a valid GST number."
        );
        return;
      }
    }
    if (newpan.trim() === "") {
      setPanNumberError("PAN number is required.");
      return;
    } else {
      if (checkPanNumber(newpan.trim())) {
        setPanNumberError("");
      } else {
        setPanNumberError(
          "Invalid PAN number. Please enter a valid PAN number."
        );
        return;
      }
    }
    // if (
    //   aadharUrl === null &&
    //   gstUrl === null &&
    //   panUrl === null
    // ) {
    //   setAadharToUplopadError(
    //     "Any one of the above Certificates is required."
    //   );
    //   return;
    // }
    // else {
    //   setAadharToUplopadError();
    // }
    if (
      newaadharNumber !== "" ||
      newgstNumber !== "" ||
      newpan !== "" ||
      aadharToUpload !== null ||
      gstToUpload !== null ||
      panToUpload !== null
    ) {
     
      const payload = {
        data: {
          id: auth.loggedInUser._id,
          aadharNumber:newaadharNumber,
          gst: newgstNumber,
          pan: newpan,
        },
        
        onSuccess: () => {
          setEditMode(false);
          const payload = {
            id: auth.loggedInUser._id,
          };
          dispatch(actions.fetchProfileDetails(payload));
          auth.updateLoggedInUser();
          toast.success("Legal Documents has been updated successfully", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
        
      };
  
      if (aadharToUpload) payload.data.aadharCertificate = aadharToUpload;
      if (panToUpload) payload.data.panCertificate = panToUpload;
      if (gstToUpload) payload.data.gstCertificate = gstToUpload;
  
      dispatch(actions.saveLegalDetails(payload));
   

    }
    
  };


  
  // const handleCertFileChange = (event) => {
  //   const file = event.target.files[0];

  //   if (event.target.files[0].name != null) {
  //     setCertLabel(event.target.files[0].name);
  //   }
  // };
  
  if (editMode) {
    return (
      <>
      <Col lg={12}>
        <h5>Legal documents</h5> 
        <div
        style={{
          width: "32px",
          cursor: "pointer",
          position: "absolute",
          right: "24px",
          top: "20px",
        }}
        onClick={handleEditMode}
      >
        <div
          style={{
            width: "16px",
          }}
        >
          <img src={closeIcon} alt="edit" style={{ width: "100%" }} />
        </div>
      </div>
      </Col>

      <Col lg={4} className="mt-2">
        <Form.Group controlId="aadhar_number">
          <Form.Label>
            <span className="headingText">Aadhaar number</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="aadhar_number"
            value={newaadharNumber}
            
            onChange={handleAadharChange}
          />
          {aadharNumberError && (
            <FormHelperText error>{aadharNumberError}</FormHelperText>
            
          )}
           
        </Form.Group>
        <div style={{ marginTop: "12px", textAlign: "center" }}>
          {aadharUrl ? (
            <a
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
              href={aadharUrl}
              target="_blank"
              rel="noreferrer"
            >
              aadhar.pdf
            </a>
          ) : (
            <div
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
            >
              <Box style={containerStyle}>
        <input
          type="file"
          name=""
          id="input_file_aadhar"
          style={{ display: "none" }}
          //ref={fileRef}
          onChange={(event) => {
            setAadharToUplopad(event.target.files);
          }}
          accept={".pdf" /*, .zip, .png, .jpeg*/}
        />
        <div
          style={{
            display: "flex",
            gap: 2,
            marginLeft: "65px",
          }}
        >
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Drag and drop files or
          </div>
          <div
            style={{
              color: "#38A169",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleInputClick("input_file_aadhar")}
          >
            Browse
          </div>
        </div>
        <div
          style={{
            color: "#000000",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "8px",
          }}
        >
          Supported formats: JPEG, JPG, PNG, PDF
        </div>
      </Box>
      {aadharToUpload && (
        <div style={{ marginTop: "8px" }}>
          <Box style={fileContainerStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: 4 }}>
                <div>
                  <img src={FileIcon} alt="file" />
                </div>

                <div
                  style={{
                    color: "#1A202C",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginTop: "2px",
                  }}
                >
                  {aadharToUpload[0].name}
                </div>
              </div>
              <div
                onClick={() => {
                  setAadharToUplopad(null);
                  setAadharToUplopadError(null);
                }}
              >
                <img
                  src={deleteIcon}
                  alt="deleteIcon"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </Box>
        </div>
      )}
              aadhar file not found
            </div>
          )}
        </div>
      </Col>
      <Col lg={4} className="mt-2">
        <Form.Group controlId="gst">
          <Form.Label>
            <span className="headingText">GST number</span>
          </Form.Label>
          <Form.Control type="text" name="gst" value={newgstNumber} onChange={handleGstChange} />
          {gstNumberError && (
            <FormHelperText error>{gstNumberError}</FormHelperText>
          )}
        </Form.Group>
        <div style={{ marginTop: "12px", textAlign: "center" }}>
          {gstUrl ? (
            <a
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
              href={gstUrl}
              target="_blank"
              rel="noreferrer"
            >
              gst.pdf
            </a>
          ) : (
            <div
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
            >
             <Box style={containerStyle}>
        <input
          type="file"
          name=""
          id="input_file_gst"
          style={{ display: "none" }}
          //ref={fileRef}
          onChange={(event) => {
            setGstToUplopad(event.target.files);
            setGstToUplopadError(null);
          }}
          accept={".pdf" /*, .zip, .png, .jpeg*/}
        />
        <div
          style={{
            display: "flex",
            gap: 2,
            marginLeft: "65px",
          }}
        >
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Drag and drop files or
          </div>
          <div
            style={{
              color: "#38A169",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleInputClick("input_file_gst")}
          >
            Browse
          </div>
        </div>
        <div
          style={{
            color: "#000000",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "8px",
          }}
        >
          Supported formats: JPEG, JPG, PNG, PDF
        </div>
      </Box>   
      {gstToUpload && (
        <div style={{ marginTop: "8px" }}>
          <Box style={fileContainerStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: 4 }}>
                <div>
                  <img src={FileIcon} alt="file" />
                </div>

                <div
                  style={{
                    color: "#1A202C",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginTop: "2px",
                  }}
                >
                  {gstToUpload[0].name}
                </div>
              </div>
              <div
                onClick={() => {
                  setGstToUplopad(null);
                }}
              >
                <img
                  src={deleteIcon}
                  alt="deleteIcon"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </Box>
        </div>
      )}
              gst file not found
            </div>
          )}
        </div>
      </Col>
      <Col lg={4} className="mt-2">
        <Form.Group controlId="pan">
          <Form.Label>
            <span className="headingText">PAN number</span>
          </Form.Label>
          <Form.Control type="text" name="pan" value={newpan}
            onChange={handlePanChange} />
            {panNumberError && (
            <FormHelperText error>{panNumberError}</FormHelperText>
          )}
        </Form.Group>
        <div style={{ marginTop: "12px", textAlign: "center" }}>
          {panUrl ? (
            <a
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
              href={panUrl}
              target="_blank"
              rel="noreferrer"
            >
              pan.pdf
            </a>
          ) : (
            <div
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
            >
              <Box style={containerStyle}>
          <input
            type="file"
            name=""
            id="input_file_pan"
            style={{ display: "none" }}
            //ref={fileRef}
            onChange={(event) => {
              setPanToUplopad(event.target.files);
              setPanToUplopadError(null);
            }}
            accept={".pdf" /*, .zip, .png, .jpeg*/}
          />
          <div
            style={{
              display: "flex",
              gap: 2,
              marginLeft: "65px",
            }}
          >
            <div
              style={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Drag and drop files or
            </div>
            <div
              style={{
                color: "#38A169",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleInputClick("input_file_pan")}
            >
              Browse
            </div>
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
            }}
          >
            Supported formats: JPEG, JPG, PNG, PDF
          </div>
        </Box>

        {panToUpload && (
          <div style={{ marginTop: "8px" }}>
            <Box style={fileContainerStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 4 }}>
                  <div>
                    <img src={FileIcon} alt="file" />
                  </div>

                  <div
                    style={{
                      color: "#1A202C",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "2px",
                    }}
                  >
                    {panToUpload[0].name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setPanToUplopad(null);
                    setPanToUplopadError(null);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Box>
          </div>
        )}
              pan file not found
            </div>
          )}
        </div>
      </Col>

      <Col lg={12}>
      {aadharToUploadError && (
            <FormHelperText error>{aadharToUploadError}</FormHelperText>
            
          )}
        <div
          style={{
            width: "100%",
            textAlign: "right",
            padding: "16px 0 8px 0",
          }}
        >
          <Button
            type="submit"
            text="Update"
            solid={true}
            buttonClick={handleSubmit}
          />
        </div>
      </Col>
      </>
    );
  }

return (
  <>
    <Col lg={12}>
      <h5>
        Legal documents
        {!aadharNumber && !gst && !pan && (role === "seller" ? (
          <span
            className="text-danger"
            style={{
              fontSize: "12px",
            }}
          >
            {" "}
            (Legal Document mandatory to create auction){" "}
          </span>
        ) : (
          <span
            className="text-danger"
            style={{
              fontSize: "12px",
            }}
          >
            {" "}
            (Legal Document mandatory to bid on auction){" "}
          </span>
        ))}
      </h5>
    </Col>
    <div
      style={{
        width: "32px",
        cursor: "pointer",
        position: "absolute",
        right: "24px",
        top: "20px",
      }}
      onClick={handleEditMode}
    >
      <div
        style={{
          width: "16px",
        }}
      >
        <img src={editIcon} alt="edit" style={{ width: "100%" }} />
      </div>
    </div>

    <Col lg={4} className="mt-2">
      <Form.Group controlId="aadhar_number">
        <Form.Label>
          <span className="headingText">Aadhaar number</span>
        </Form.Label>

        <h6 style={{ textTransform: "capitalize" }}>{aadharNumber}</h6>
      </Form.Group>
      <div style={{ marginTop: "12px", textAlign: "center" }}>
        {aadharUrl ? (
          <a
            style={{
              textDecoration: "none",
              color: "#38A169",
              background: "none",
              border: "none",
              outline: "none",
            }}
            href={aadharUrl}
            target="_blank"
            rel="noreferrer"
          >
            aadhar.pdf
          </a>
        ) : (
          <div
            style={{
              textDecoration: "none",
              color: "#38A169",
              background: "none",
              border: "none",
              outline: "none",
            }}
          >
            aadhar file not found
          </div>
        )}
      </div>
    </Col>
    <Col lg={4} className="mt-2">
      <Form.Group controlId="gst">
        <Form.Label>
          <span className="headingText">GST number</span>
        </Form.Label>

        <h6 style={{ textTransform: "capitalize" }}>{gst}</h6>
      </Form.Group>
      <div style={{ marginTop: "12px", textAlign: "center" }}>
        {gstUrl ? (
          <a
            style={{
              textDecoration: "none",
              color: "#38A169",
              background: "none",
              border: "none",
              outline: "none",
            }}
            href={gstUrl}
            target="_blank"
            rel="noreferrer"
          >
            gst.pdf
          </a>
        ) : (
          <div
            style={{
              textDecoration: "none",
              color: "#38A169",
              background: "none",
              border: "none",
              outline: "none",
            }}
          >
            gst file not found
          </div>
        )}
      </div>
    </Col>
    <Col lg={4} className="mt-2">
      <Form.Group controlId="pan">
        <Form.Label>
          <span className="headingText">PAN number</span>
        </Form.Label>

        <h6 style={{ textTransform: "capitalize" }}>{pan}</h6>
      </Form.Group>
      <div style={{ marginTop: "12px", textAlign: "center" }}>
        {panUrl ? (
          <a
            style={{
              textDecoration: "none",
              color: "#38A169",
              background: "none",
              border: "none",
              outline: "none",
            }}
            href={panUrl}
            target="_blank"
            rel="noreferrer"
          >
            pan.pdf
          </a>
        ) : (
          <div
            style={{
              textDecoration: "none",
              color: "#38A169",
              background: "none",
              border: "none",
              outline: "none",
            }}
          >
            pan file not found
          </div>
        )}
      </div>
    </Col>
    {/* <Col lg={3} className="mt-2">
      <Form.Group controlId="sdo">
        <Form.Label>
          <span className="headingText">Registration certificate</span>
        </Form.Label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              style={{ borderRadius: "4px 0px 0px 4px" }}
            >
              <img src={attachImg} style={{ padding: "10px 2px" }} alt="" />
            </span>
          </div>
          <div className="custom-file form-control">
            <label
              className="custom-file-label"
              style={{ borderRadius: "0px 4px 4px 0px" }}
            >
              <input
                type="file"
                style={{ visibility: "hidden", width: "100%" }}
                className="custom-file-input"
                onChange={handleCertFileChange}
              />
              <span style={{ position: "absolute", top: "", left: "1.5em" }}>
                {certLabel}
              </span>
            </label>
          </div>
        </div>
      </Form.Group>
    </Col> */}
  </>
);
};
export default LegalDoc;

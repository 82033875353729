import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  draftedAuctions:{auctions:[],totalRecords:0},
  
  
};

const sellerDraftedAuctions = createSlice({
  name: "sellerDraftedAuctions",
  initialState,
  reducers: {
    fetchDraftedAuctions(state, action) {
      state.loading = true;
    },
    fetchDraftedAuctionsSuccess(state, action) {
      state.draftedAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchDraftedAuctionsFailure(state, action) {
      state.loading = false;
    },
    clearStore(state,action){
      state.loading = false;
      state.error = null;
      state.draftedAuctions = initialState.draftedAuctions;
      
      }
  },
});

export const { actions, reducer, name: sliceKey } = sellerDraftedAuctions;

import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state) => state?.region || initialState;

export const countries = createSelector([selectDomain], (state) => {
  return (
    state.countries &&
    state.countries.document &&
    state.countries.document.map((country) => {
      return { label: country.name, id: country._id };
    })
  );
});

export const states = createSelector([selectDomain], (state) => {
  return (
    state.states &&
    state.states.document &&
    state.states.document.map((state) => {
      return { label: state.name, id: state._id };
    })
  );
});

export const cities = createSelector([selectDomain], (state) => {
    return (
      state.cities &&
      state.cities.document &&
      state.cities.document.map((city) => {
        return { label: city.name, id: city._id };
      })
    );
  });
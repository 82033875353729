import React from "react";
import styles from "./styles.module.css";

export default function Pagination({
  numPages,
  page,
  handlePageSelect,
  handlePrev,
  handleNext,
}) {
  if (numPages <= 1) return <></>;
  if (numPages <= 7) {
    let middlePages = [];
    for (let i = 1; i <= numPages; i++) {
      middlePages.push(i);
    }
    return (
      <div className={styles.pagination}>
        <div onClick={handlePrev} className={styles.prev}>
          <span>Prev</span>
        </div>
        {middlePages.map((pageNumber, index) => (
          <div
            style={
              page + 1 === pageNumber
                ? { backgroundColor: "#38A169", color: "#fff" }
                : {}
            }
            key={index}
            className={styles.middle}
            onClick={() => handlePageSelect(pageNumber)}
          >
            <span>{pageNumber}</span>
          </div>
        ))}
        <div onClick={handleNext} className={styles.next}>
          <span>Next</span>
        </div>
      </div>
    );
  }

  if (numPages > 7) {
    let middlePages = [];
    let n;
    if (page < 3) {
      n = 2;
    }
    if (page >= 3) {
      n = page;
    }
    if (page > numPages - 4) {
      n = numPages - 3;
    }
    for (let i = n; i <= n + 2; i++) {
      middlePages.push(i);
    }
    const extending = <div className={styles.extending}>...</div>;
    const last = (
      <div
        style={
          page + 1 === numPages
            ? { backgroundColor: "#38A169", color: "#fff" }
            : {}
        }
        className={styles.middle}
        onClick={() => handlePageSelect(numPages)}
      >
        <span>{numPages}</span>
      </div>
    );
    const first = (
      <div
        style={page === 0 ? { backgroundColor: "#38A169", color: "#fff" } : {}}
        className={styles.middle}
        onClick={() => handlePageSelect(1)}
      >
        <span>1</span>
      </div>
    );
    return (
      <div className={styles.pagination}>
        <div onClick={handlePrev} className={styles.prev}>
          <span>Prev</span>
        </div>
        {first}
        {page >= 3 ? extending : null}
        {middlePages.map((pageNumber, index) => (
          <div
            style={
              page + 1 === pageNumber
                ? { backgroundColor: "#38A169", color: "#fff" }
                : {}
            }
            key={index}
            className={styles.middle}
            onClick={() => handlePageSelect(pageNumber)}
          >
            <span>{pageNumber}</span>
          </div>
        ))}
        {page <= numPages - 4 ? extending : null}
        {last}
        <div onClick={handleNext} className={styles.next}>
          <span>Next</span>
        </div>
      </div>
    );
  }
}

import React from "react";
import styles from "./styles.module.css";
import { useAuth } from "../../../contexts/auth";
import { Link } from "react-router-dom";

export default function Privacypolicy() {
  const auth = useAuth();
  return (
    <>
      <main className={styles.about}>
        <h1>Privacy Policy for Jadi Buti Bazar</h1>
        <h2>1. Introduction</h2>
        <p>
          Jadi Buti Bazar values the privacy and trust of its users.
          This Privacy Policy outlines how we collect, use, disclose, and protect your
          personal information when you use our online e-auction platform for the trade of raw herbs
          and their derivatives.
          By accessing or using the Jadi Buti Bazar platform, you agree to the terms of this Privacy Policy.
        </p>
        <h2>2. Information We Collect</h2>
        <p>
          <ul><li>	<strong>Personal Information:</strong> When you register on the platform, create an auction, or participate in an auction, we may collect personal information, including but not limited to your name, contact details, email address, business information, and payment details.</li>
            <li>	<strong>Transaction Information:</strong> We collect information related to the transactions you perform on the platform, including auction details, bids placed, products listed, and any communication related to the transaction.</li>
            <li>	<strong>	Device Information:</strong> We may collect information about the devices you use to access the platform, such as IP addresses, browser type, operating system, and device identifiers.</li>
            <li>	<strong>	Usage Data:</strong> We collect data on how you interact with our platform, including pages viewed, links clicked, and other actions taken within the platform.</li>
            <li>	<strong>	Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance your experience on the platform, understand usage patterns, and improve our services. You can control cookies through your browser settings.</li></ul>

        </p>

        <h2>3. How We Use Your Information</h2>
        <p>
          <ul><li>	<strong>	To Provide and Improve Our Services: </strong> We use your personal information to operate, maintain, and enhance the functionality of the platform, including creating auctions, facilitating transactions, and providing customer support.</li>
            <li>	<strong>	To Communicate with You: </strong> We may use your contact information to send you updates, notifications, and other communications related to your use of the platform. This may include promotional messages if you have opted in to receive them.</li>
            <li>	<strong>	For Security and Fraud Prevention:  </strong>We use your information to protect the security and integrity of our platform, detect and prevent fraudulent activities, and enforce our terms of service.</li>
            <li>	<strong>	To Comply with Legal Obligations: </strong> We may use your information to comply with applicable laws, regulations, and legal processes, including responding to legal requests from authorities.</li>
            <li>	<strong>	For Analytics and Research: </strong> We use aggregated and anonymized data to analyze usage trends, conduct research, and improve our platform's performance and user experience.</li>
          </ul>

        </p>


        <h2>4. How We Share Your Information</h2>
        <p>
          <ul> <li>	<strong>	With Other Users:</strong> Your auction details, including product information, quality reports, and traceability details, will be visible to other users on the platform. Your contact information may also be shared with other users when necessary to complete a transaction.</li>
            <li>	<strong>	With Third-Party Service Providers: </strong> We may share your information with third-party service providers who assist us in operating the platform, processing payments, conducting quality assessments, and providing customer support. These providers are obligated to protect your information and only use it for the purposes for which it was shared.</li>
            <li>	<strong>	With Legal Authorities: </strong> We may disclose your information to law enforcement or other authorities if required by law or in response to legal processes such as subpoenas or court orders.</li>
            <li>	<strong>	In Business Transactions: </strong> If Jadi Buti Bazar is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction. We will notify you of any such change in ownership or control of your personal information.</li>

          </ul>

        </p>

        <h2>4. How We Share Your Information</h2>
        <p>
          <ul> <li>	<strong>	With Other Users:</strong> Your auction details, including product information, quality reports, and traceability details, will be visible to other users on the platform. Your contact information may also be shared with other users when necessary to complete a transaction.</li>
            <li>	<strong>	With Third-Party Service Providers: </strong> We may share your information with third-party service providers who assist us in operating the platform, processing payments, conducting quality assessments, and providing customer support. These providers are obligated to protect your information and only use it for the purposes for which it was shared.</li>
            <li>	<strong>	With Legal Authorities: </strong> We may disclose your information to law enforcement or other authorities if required by law or in response to legal processes such as subpoenas or court orders.</li>
            <li>	<strong>	In Business Transactions: </strong> If Jadi Buti Bazar is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction. We will notify you of any such change in ownership or control of your personal information.</li>

          </ul>

        </p>

        <h2>5. Data Security</h2>
        <p>
          <ul> <li>	<strong>	Protection Measures:</strong> We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and regular security assessments.</li>
            <li>	<strong>	Limitations: </strong> While we strive to protect your information, no security system is foolproof. We cannot guarantee the absolute security of your data transmitted over the internet and stored on our servers.</li>


          </ul>

        </p>
        <h2>6. Your Rights and Choices</h2>
        <p>
          <ul> <li>	<strong>	Access and Correction:</strong> You have the right to access and update your personal information on the platform. You can do so by logging into your account and making the necessary changes.</li>
            <li>	<strong>	Data Deletion: </strong>You can request the deletion of your personal information by contacting us at the provided contact information. Please note that certain information may be retained for legal, security, or operational reasons.</li>
            <li>	<strong>	Opt-Out of Communications: </strong> You can opt out of receiving promotional communications from us by following the unsubscribe instructions in the emails we send you or by contacting us directly. You will still receive transactional communications related to your use of the platform.</li>
            <li>	<strong>	Cookie Preferences: </strong>You can manage your cookie preferences through your browser settings. However, disabling cookies may affect your ability to use certain features of the platform.</li>


          </ul>

        </p>

        <h2>7. International Data Transfers</h2>
        <p>
          Jadi Buti Bazar operates globally, and your personal information may be transferred to and processed in countries outside your home country. These countries may have different data protection laws than your country of residence. We ensure that any such transfers comply with applicable data protection regulations and that your information is protected.
        </p>

        <h2>8. Children’s Privacy</h2>
        <p>
          Jadi Buti Bazar is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected information from a child under 18, we will take steps to delete such information promptly.
        </p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on the platform and updating the effective date. Your continued use of the platform after any changes to this policy constitutes your acceptance of the revised policy.
        </p>


        <h2>10. Governing Law</h2>
        <p>
          This Privacy Policy shall be governed by and construed in accordance with the laws of land.  Any disputes arising out of or related to this policy shall be subject to the exclusive jurisdiction of the courts located in Mandi (Himachal Pradesh) courts
        </p>

        <h2>11. Contact Information</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at:</p>


        <p>
          <ul><li><strong>[Name of the organization:-</strong> Regional cum Facilitation Centre, Northern Region-1,National Medicinal Plants Board,Ministry Ayush, GOI] </li>
            <li><strong> Address:-</strong> [RCFC-Northern Region-1/ क्षेत्रीय सुगमता केंद्र उत्तर भारत-1 NMPB, Ministry AYUSH GOI /राष्ट्रीय औषध पादप बोर्ड,  1st Floor, CoEDG Building, Research Institute in Indian Systems of Medicine (RIISM), Joginder Nagar-175015 Mandi HP] </li></ul> </p>


        <p><strong> Email id:- </strong>
        <li> rcfcnorth@gmail.com </li>  <li>doctorchandan@gmail.com </li>  <li>avikasubba94@gmail.com</li>
         
        <strong> Contact Person:-</strong> 
          <li>	Dr. Arun Chandan(Regional Director)-+91 7018001026 </li>
            <li>	Avika Subba (Consultant-Marketing)- +91-8968619467</li></p>
































      </main>
    </>
  );
}

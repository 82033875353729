import React, { useState } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./customTable.module.css";
import PlaceBid from "../../../components/dashboardApp/PlaceBidInputButton";
import { Link } from "react-router-dom";

const BuyerCancelTable = ({
  liveAuctionHeading,
  filteredData,
  bids,
  auctionDetailLink,
}) => {
  return (
    <>
      <Table responsive>
        <thead style={{ backgroundColor: "#F7FAFC" }}>
          {liveAuctionHeading.map((auction, index) => (
            <tr key={index}>
              <td className={styles.headT}>{auction.auctionId}</td>
              <td className={styles.headT}>{auction.auction}</td>
              <td className={styles.headT}>{auction.createdDate}</td>
              <td className={styles.headT}>{auction.nob}</td>
              <td className={styles.headT}>{auction.quantity}</td>
              <td className={styles.headT}>{auction.asked}</td>
              <td className={styles.headT}>{auction.highbid}</td>
              <td className={styles.headT}>{auction.mybid}</td>
              {/* <td className={styles.headT}>{auction.placebid}</td> */}
            </tr>
          ))}
        </thead>
        <tbody>
          {filteredData.map((auction, index) => (
            <tr key={index}>
              <td className={styles.dataT}>{auction.id}</td>

              <td className={styles.dataT}>
                <Link
                  to={auctionDetailLink + "?id=" + auction.id}
                  className={styles.greenT}
                >
                  {auction.auction}
                </Link>
              </td>
              <td className={styles.dataT}>{auction.createdDate}</td>
              <td className={styles.dataT}>
                <span>{auction.nob}</span>
              </td>
              <td className={styles.dataT}>
                <span>{auction.quantity}</span>
              </td>
              <td className={styles.dataT}>
                <span>₹{auction.asked}</span>
              </td>
              <td className={styles.dataT}>
                <span>₹{auction.highbid}</span>
              </td>
              <td className={styles.dataT}>
                <span>₹{auction.mybid}</span>
              </td>
              {/* <td className={styles.dataT}><span>{auction.highbid+''+index}</span>  <sup><Badge className={styles.badgeHigh}>{auction.hightext +''+(index+1)}</Badge></sup></td> */}
              {/* <td className={styles.dataT}><span>{auction.mybid+''+index}</span></td>
                        { bids && <td style={{ padding: "0.8em 1.5em"}}><PlaceBid placeholderText="Enter price" auctionID={index} buttonText="Bid" /></td>} */}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default BuyerCancelTable;

import { useEffect } from "react";
import { useLocation } from "react-router";

export default function ScrollToTop({ children }) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
}

/*
wrap app component inside this component and wrap this component inside browser-router component
*/

import React from "react";
import styles from "./styles.module.css";
import { useEffect } from "react";
import fbLogo from "../../../../assets/icons/facebook.svg";
import twitterLogo from "../../../../assets/icons/twitter.svg";
import socialLogo from "../../../../assets/icons/social.svg";
import threadLogo from "../../../../assets/icons/Threads_(app).png"
import youtubeLogo from "../../../../assets/icons/youtube.png"
import facebookLogo from "../../../../assets/icons/fbic.png"
import twitLogo from "../../../../assets/icons/twic.png"
import instaLogo from "../../../../assets/icons/instaic.png"
import { Link } from "react-router-dom";



export default function Footer() {
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en,hi,kn,te,ta,mr,as",
  //       autoDisplay: false,
  //       includedLanguages: 'en,hi,kn,te,ta,mr,as',
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
  return (
    <><div className={styles.footer}>
      <div className={styles.footerMain}>
        <div className={styles.social}>
          {/* <a href="https://www.facebook.com/rcfc.north/" target="_blank" rel="noreferrer">
            <img src={fbLogo} alt="facebook logo" /> </a> */}
            

            <a href="https://www.youtube.com/@rcfcnr1nmpb442" target="_blank" rel="noreferrer">
          <img style={{height:"40px", width:"40px",}} src={youtubeLogo} alt="social logo" />
          </a>
          <a href="https://twitter.com/rcfcnorth" target="_blank" rel="noreferrer">
            <img style={{height:"35px", width:"35px",}} src={twitLogo} alt="twitter logo" /></a>

            <a href="https://www.facebook.com/rcfc.north/" target="_blank" rel="noreferrer">
            <img style={{height:"40px", width:"40px",}} src={facebookLogo} alt="facebook logo" /> </a>
            <a href="https://www.threads.net/@rcfc_north_1?hl=en" target="_blank" rel="noreferrer">
          <img style={{height:"27px", width:"27px",}} src={threadLogo} alt="social logo" />
          </a>
            <a href="https://www.instagram.com/rcfc_north_1/" target="_blank" rel="noreferrer">
          <img style={{height:"50px", width:"50px",}}  src={instaLogo} alt="social logo" />
          </a>
         
         
        </div>
        <ul className={styles.policies}>
          <li> <Link to="/disclaimer" style={{textDecoration:"none", color:"#D1D1D3"}} className={styles.aUnset} > Disclaimer </Link> </li>
          <li>Market place</li>
          <li>FAQ</li>
          <li><Link to="/contact" style={{textDecoration:"none", color:"#D1D1D3"}} className={styles.aUnset} > Contact </Link></li>
          <li> <Link to="/privacypolicy" style={{textDecoration:"none", color:"#D1D1D3"}} className={styles.aUnset} >Privacy policy </Link> </li>
        </ul>
        <div className={styles.address}>
          <p className={styles.addressText}>
            {/* National Medicinal Plants Board, Ministry of AYUSH, Indian Red Cross
    Society (IRCS), Annexe Building, First & Second Floor, 1 Red Cross
    Road, New Delhi-110001, Ph.No.-011-23721840 */}
            Centre of Excellence RIISM, Joginder Nagar, Himachal Pradesh 175015
          </p>
        </div>
      </div>
      <div className={styles.copyright}>
        <p className={styles.left}>
          &copy;{new Date().getFullYear()} JadiButiBazar
        </p>
        <p className={styles.right}>Powered by Agnext Technologies</p>
        {/* <p className={styles.right}><div id="google_translate_element"></div></p>  */}
      </div>
      
    </div></>
  );
}


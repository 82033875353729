import React from "react";
import styles from "./styles.module.css";
import backIcon from "../../../../assets/icons/backward.svg";

export default function QualityDetails({ close, auction }) {
  let data = auction.auctionDetail.qualityResult;
  console.log({ qualityResultdata: data });
  const dateStr = auction.auctionDetail.testDate;
  const date = new Date(dateStr);

  const options = { day: "numeric", month: "long", year: "numeric" };
  const labDate = date.toLocaleDateString("en-US", options);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <button onClick={close}>
            <img src={backIcon} style={{ height: "16px" }} alt="go back" />
          </button>
          <h2>Quality details</h2>
        </div>
        <div className={styles.right}></div>
      </div>
      <main className={styles.mainContent}>
        <section className={styles.title}>
          <span className={styles.key}>
            Quality by{" "}
            <span className={styles.value}>
              {auction.auctionDetail.testLabName}
            </span>
          </span>
          <span className={styles.key}>
            Quality date <span className={styles.value}>{labDate}</span>
          </span>
        </section>
        <section>
          {auction.auctionDetail.labReport && (
            <span className={styles.key}>
              Lab Report{" "}
              <a
                href={auction.auctionDetail.labReport}
                className={styles.value}
                target="_blank"
                rel="noreferrer"
              >
                Download Report
              </a>
            </span>
          )}
        </section>
        <section className={styles.data}>
          <div className={styles.dataLeft}>
            <span className={styles.title}>Parameter</span>
            {data.map((q) => {
              return <span key={q._id}>{q.parameter}</span>;
            })}
          </div>
          <div className={styles.dataRight}>
            <span className={styles.title}>Value</span>
            {data.map((qty) => {
              return <span>{qty.value}</span>;
            })}
          </div>
        </section>
      </main>
    </>
  );
}

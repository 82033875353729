import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  userDetail:{},
};

const userDetailForAdmin = createSlice({
  name: "userDetailForAdmin",
  initialState,
  reducers: {
    fetchUserById(state, action) {
      state.loading = true;
    },
    fetchUserByIdSuccess(state, action) {
      state.userDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchUserByIdFailure(state, action) {
      state.loading = false;
    },
    updateUserStatus(state, action) {
      state.loading = true;
    },
    updateUserStatusSuccess(state, action) {
      state.userDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    updateUserStatusFailure(state, action) {
      state.loading = false;
    },
    blockSeller(state, action) {
      state.loading = true;
    },
    blockSellerSuccess(state, action) {
      state.userDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    blockSellerFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = userDetailForAdmin;

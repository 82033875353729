import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";

export default function PersonalDetails(props) {
  const [selectType, setSelectType] = useState("");
  const [branchName, setBranchName] = useState(props.email);
  const [personName, setPersonName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(props.mobile);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [personError, setPersonError] = useState("");
  const backButtonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "#FFFFFF !important",
      border: "1px solid #E2E8F0 !important",
      boxShadow: "none !important",
    },
  };

  const verifyButtonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "green !important",
    },
  };
  const CustomButton = withStyles(backButtonStyles)(({ classes, ...props }) => (
    <Button className={classes.root} {...props} />
  ));

  const CustomVerifyButton = withStyles(verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );
  const selectChange = (event) => {
    setSelectType(event.target.value);
    setCompanyError('');
  };
  const onChangeBranchName = (event) => {
    setBranchName(event.target.value);
    setEmailError('');
  };
  const onChagePersonName = (event) => {
    setPersonName(event.target.value);
    setPersonError('');
  };
  const onChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
    setPhoneError('');
  };
  const checkPhoneNumber = (phoneNumber) => {
    const pattern = /^\d{10}$/;
    return pattern.test(phoneNumber);
  };
  const checkName = (name) => {
    const pattern = /^[A-Za-z\s]+$/;
    return pattern.test(name);
  };
  const handleSubmit = (event) => {
    if (selectType.trim() === '') {
      setCompanyError('Name of Firm is required.')
    }else{      
      if(checkName(selectType.trim())){          
        setCompanyError("");
      }else{          
        setCompanyError("Enter only Characters.");
        return;
      }
    }
    // if (branchName.trim() === '') {
    //   setEmailError('Email is required.')
    // }else{
    //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   if (!emailPattern.test(branchName)) {
    //     setEmailError('Invalid email');
    //     return;
    //   }
    // }
    if (personName.trim() === '') {
      setPersonError('Name is required.')
    }else{      
      if(checkName(personName.trim())){          
        setPersonError("");
      }else{          
        setPersonError("Enter only Characters.");
        return;
      }
    }
    if (phoneNumber.trim() === '') {
      setPhoneError('Mobile number is required.')
      return;
    }else{
      if(checkPhoneNumber(phoneNumber.trim())){          
        setPhoneError("");
      }else{
        console.log('not');          
        setPhoneError("10 digits Mobile number.");
        return;
      }
    }
    if(selectType.trim() !== '' ||  personName.trim() !== '' || phoneNumber.trim() !== ''){
      props.clickedNextButton(selectType, branchName, personName, phoneNumber);
    }
  };
  const inputStyles = {
    height: "20px",
    width: "250px",
  };
  return (
    <>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
        }}
      >
        Personal details
      </div>
      <div
        style={{
          color: "#718096",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          marginTop: "8px",
        }}
      >
        Enter you personal details
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
          flexWrap:"wrap"
        }}
      >
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >{`Name of the firm`}</div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. ABC Pvt"
              inputProps={{
                style: inputStyles,
                maxLength: 50
              }}
              value={selectType}
              onChange={selectChange}
            />
            {companyError && <FormHelperText error>{companyError}</FormHelperText>}
          </div>
        </div>
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Contact Person's Email
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. demo@email.com"
              inputProps={{
                style: inputStyles,
              }}
              value={branchName}
              onChange={onChangeBranchName}
            />
            {emailError && <FormHelperText error>{emailError}</FormHelperText>}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
           flexWrap:"wrap"
        }}
      >
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Contact person's name
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. Amit Singh"
              inputProps={{
                style: inputStyles,
                maxLength: 50,
                pattern: "[a-z][A-Z]"
              }}
              value={personName}
              onChange={onChagePersonName}
            />
            {personError && <FormHelperText error>{personError}</FormHelperText>}
          </div>
        </div>
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Contact person's phone number
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. 99XXXXXXXX"
              inputProps={{
                style: inputStyles,
                maxLength: 10,
              }}
              value={phoneNumber}
              onChange={onChangePhoneNumber}
            />
            {phoneError && <FormHelperText error>{phoneError}</FormHelperText>}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: 40, marginTop: "40px", justifyContent:"center", flexWrap:"wrap" }}>
        <div>
          <CustomButton onClick={props.clickedBackButton} variant="contained">
            <div
              style={{
                color: "#1A202C",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Back
            </div>
          </CustomButton>
        </div>
        <div>
          <CustomVerifyButton onClick={handleSubmit}>
            <div
              style={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Next
            </div>
          </CustomVerifyButton>
        </div>
      </div>
    </>
  );
}
import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import styles from "./style.module.css";
import attachImg from "../../../../../assets/dashboardImages/attach.svg";
import {
  sliceKey,
  reducer,
  actions,
} from "../../../../components/shared/select/slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "../../../../components/shared/select/selectors";
import { regionSaga } from "../../../../components/shared/select/saga";
import SelectState from "../../../../components/shared/select/selectState";
import SelectCity from "../../../../components/shared/select/selectCity";
import { useDispatch, useSelector } from "react-redux";
import { formControlLabelClasses } from "@mui/material";

const CollectionMethodForm = ({
  formData,
  handleInputChange,
  handleFileChange,
  previousStep,
  saveCollectionDetails,
  handleStateInputChange,
  handleDistrictInputChange
}) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: regionSaga });

  const countries = useSelector(selectors.countries);

  const getCountryId = useCallback(() => {
    let country = {};
    countries.length &&
      countries.forEach((country) => {
        if (country.label === "India") {
          country = { ...country };
        }
      });
    return country;
  }, [countries]);

  const [country, setCountry] = React.useState({ label: "India", id: "6139b09a34b4734fa66607ff" });
  const [selectedOption, setSelectedOption] = useState("cultivated");
  const [reportLabel, setReportLabel] = useState("Upload");
  const [legalLabel, setLegalLabel] = useState("Upload");
  const [forestLabel, setForestLabel] = useState("Upload");
  const [bmcLabel, setbmcLabel] = useState("Upload");
  const [panchLabel, setPanchLabel] = useState("Upload");
  const [freeLabel, setFreeLabel] = useState("Upload");
  const [additionalLabel, setAdditionalLabel] = useState("Upload");
  const [isChecked, setIsChecked] = useState(false);
  const [patwariError, setPatwariError] = useState(null);
  const [additionalError, setAdditionalError] = useState(null);
  const [villageError, setVillageError] = useState(null);
  const [tehsilError, setTehsilError] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [districtError, setDistrictError] = useState(null);
  const [retError, setRetError] = useState(null);
  const [forestError, setForestError] = useState(null);
  const [bmcError, setBMCError] = useState(null);
  const [feeError, setFeeError] = useState(null);
  const [panchayatError, setPanchayatError] = useState(null);
  const [legalError, setLegalError] = useState(null);
  const [state, setState] = useState({ label: "", id: "" });
  const [city, setCity] = useState({ label: "", id: "" });

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(actions.fetchCountries());
  }, [dispatch])

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    handleInputChange(event);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value == "wildly_picked") {
      setAdditionalLabel("Upload");
      setForestLabel("Upload");
      setFreeLabel("Upload");
      setPanchLabel("Upload");
      setbmcLabel("Upload");
    } else {
      setReportLabel("Upload");
      setAdditionalLabel("Upload");
      setLegalLabel("Upload");
    }
    handleInputChange(event);
  };

  const renderFileName = (file) => {
    const fileName = file;
    const maxChars = 10; // Maximum number of characters to display
    let displayFileName = fileName;

    if (fileName.length > maxChars) {
      const startingChars = fileName.substring(0, maxChars);
      const extension = fileName.slice(fileName.lastIndexOf(".") + 1);
      displayFileName = `${startingChars}...${extension}`;
    }
    return displayFileName;
  }

  const handleReportFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPatwariError(null);
      const fileName = renderFileName(file.name);
      
      setReportLabel(fileName);
    }
    handleFileChange("reportPatwari", file);
  };

  const handleAdditionalFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAdditionalError(null);
      const fileName = renderFileName(file.name);
      setAdditionalLabel(fileName);
    }
    handleFileChange("additionalDoc", file);
  };

  const handleLegalFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileName = renderFileName(file.name);
      setLegalLabel(fileName);
    }
    handleFileChange("legalFile", file);
  };

  const handlebmsFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setBMCError(null);
      const fileName = renderFileName(file.name);
      setbmcLabel(fileName);
    }
    handleFileChange("bmc", file);
  };

  const handlePanchFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setPanchayatError(null);
      const fileName = renderFileName(file.name);
      setPanchLabel(fileName);
    }
    handleFileChange("panchayatPermit", file);
  };

  const handleFreeFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFeeError(null);
      const fileName = renderFileName(file.name);
      setFreeLabel(fileName);
    }
    handleFileChange("fdr", file);
  };
  const handleForestFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setForestError(null);
      const fileName = renderFileName(file.name);
      setForestLabel(fileName);
    }
    handleFileChange("forestPermission", file);
  };

  const stateOptions = [
    { value: "", label: "Select State" },
    { value: "Himachal", label: "Himachal" },
    // Add more state options here
  ];
  const districtOptions = [
    { value: "", label: "Select District" },
    { value: "Kangra", label: "Kangra" },
  ];

  const handleNext = (e) => {
    if (formData.type == undefined || formData.type == "cultivated") {
      if (!formData.reportPatwari) {
        setPatwariError("Please select report");
      } else {
        setPatwariError(null);
      }
      if (!formData.state) {
        setStateError("Please select state");
      } else {
        setStateError(null);
      }
      if (!formData.village) {
        setVillageError("Please enter the village");
      } else {
        setVillageError(null);
      }
      if (!formData.tehsil) {
        setTehsilError("Please enter the tehsil");
      } else {
        setTehsilError(null);
      }
      if (!formData.district) {
        setDistrictError("Please enter the district");
      } else {
        setDistrictError(null);
      }
      if (!formData.legalFile && isChecked) {
        setLegalError("Please select report");
      } else {
        setLegalError(null);
      }
      if (
        (formData.reportPatwari &&
          formData.state &&
          formData.village &&
          formData.tehsil &&
          formData.district &&
          !isChecked) ||
        (formData.reportPatwari &&
          formData.state &&
          formData.village &&
          formData.tehsil &&
          formData.district &&
          isChecked &&
          formData.legalFile)
      ) {
        saveCollectionDetails();
      }
    } else {
      if (!formData.fdr) {
        setFeeError("Please select report");
      } else {
        setFeeError(null);
      }
      if (!formData.forestPermission) {
        setForestError("Please select additional file");
      } else {
        setForestError(null);
      }
      if (!formData.bmc) {
        setBMCError("Please select state");
      } else {
        setBMCError(null);
      }
      if (!formData.panchayatPermit) {
        setPanchayatError("Please enter the village");
      } else {
        setPanchayatError(null);
      }
      if (
        (formData.fdr &&
          formData.bmc &&
          formData.forestPermission &&
          formData.panchayatPermit &&
          !isChecked) ||
        (formData.fdr &&
          formData.bmc &&
          formData.forestPermission &&
          formData.panchayatPermit &&
          isChecked &&
          formData.legalFile)
      ) {
        saveCollectionDetails();
      }
    }
    // e.preventDefault()
  };

  const handleStateChange = (event) => {
    if(event.length > 0){
      setState(event[0]);
      setStateError(null);
      handleStateInputChange(event[0].label);
    }    
  };

  const handleCityChange = (event) => {
    if(event.length > 0){
      setCity(event[0]);
      setDistrictError(null);
      handleDistrictInputChange(event[0].label);
    }    
    
  };

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <h3 className={styles.formheadingText}>Method of collection</h3>
      <Row>
        <Col lg={12} className="mb-2">
          <h5>Select type</h5>
          <label style={{ marginRight: "1em" }}>
            <input
              type="radio"
              name="type"
              value="cultivated"
              checked={selectedOption === "cultivated"}
              onChange={handleOptionChange}
            />
            Cultivated
          </label>
          <label>
            <input
              type="radio"
              name="type"
              value="wildly_picked"
              checked={selectedOption === "wildly_picked"}
              onChange={handleOptionChange}
            />
            Wildly picked
          </label>
        </Col>
      </Row>
      {selectedOption === "cultivated" && (
        <>
          <Row>
            <Col lg={8}>
              <Form.Group>
                <Form.Label>
                  Report of patwari / Revenue Authority / Girdawari (any one)
                </Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{ borderRadius: "4px 0px 0px 4px" }}
                    >
                      <img src={attachImg} style={{ padding: "10px 2px" }} />
                    </span>
                  </div>
                  <div
                    className={`custom-file form-control ${
                      patwariError && "is-invalid"
                    } ${patwariError === false && "is-valid"}`}
                    style={{ zIndex: "unset" }}
                  >
                    <label
                      className="custom-file-label"
                      style={{ borderRadius: "0px 4px 4px 0px", width: "100%" }}
                    >
                      <input
                        type="file"
                        required={
                          formData.type === undefined ||
                          formData.type === "cultivated"
                            ? "required"
                            : false
                        }
                        name="reportPatwari"
                        className={`custom-file-input`}
                        style={{ visibility: "hidden", width: "100%" }}
                        onChange={handleReportFileChange}
                      />
                      <span
                        style={{ position: "absolute", top: "11px", left: "1.5em" }}
                      >
                        {reportLabel}
                      </span>
                    </label>
                  </div>
                </div>
                <small className={`${patwariError && "invalid-feedback"} `}>
                  {!!patwariError && patwariError}
                </small>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Any additional document</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{ borderRadius: "4px 0px 0px 4px" }}
                    >
                      <img src={attachImg} style={{ padding: "10px 2px" }} />
                    </span>
                  </div>
                  <div
                    className={`custom-file form-control '} ${
                      additionalError === false && "is-valid"
                    }`}
                    style={{ zIndex: "unset" }}
                  >
                    <label
                      className="custom-file-label"
                      style={{ borderRadius: "0px 4px 4px 0px", width: "100%" }}
                    >
                      <input
                        type="file"
                        name="additionalDoc"
                        style={{ visibility: "hidden", width: "100%" }}
                        className={`custom-file-input`}
                        onChange={handleAdditionalFileChange}
                      />
                      <span
                        style={{ position: "absolute", top: "11px", left: "1.5em" }}
                      >
                        {additionalLabel}
                      </span>
                    </label>
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mt-5 mb-2">
              <span>Land details</span>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="districtSelect">
                <Form.Label>Village</Form.Label>
                <Form.Control
                  type="text"
                  name="village"
                  value={formData.village}
                  onChange={handleInputChange}
                  required={
                    formData.type === undefined ||
                    formData.type === "cultivated"
                      ? "required"
                      : false
                  }
                  className={`custom-file-input ${
                    villageError && "is-invalid"
                  } ${villageError === false && "is-valid"}`}
                />
                <small className={`${villageError && "invalid-feedback"} `}>
                  {!!villageError && villageError}
                </small>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="districtSelect">
                <Form.Label>Tehsil</Form.Label>
                <Form.Control
                  type="text"
                  name="tehsil"
                  value={formData.tehsil}
                  onChange={handleInputChange}
                  required={
                    formData.type === undefined ||
                    formData.type === "cultivated"
                      ? "required"
                      : false
                  }
                  className={`custom-file-input ${
                    tehsilError && "is-invalid"
                  } ${tehsilError === false && "is-valid"}`}
                />
                <small className={`${tehsilError && "invalid-feedback"} `}>
                  {!!tehsilError && tehsilError}
                </small>
              </Form.Group>
            </Col>
            <Col lg={3}>
            
                <Form.Label>State</Form.Label>
                <SelectState
                  country={country}
                  state={state}
                  bootstrap={true}
                  stateError={stateError}
                  handleStateChange={handleStateChange}
                />                
              
            </Col>
            <Col lg={3}>
              <Form.Group controlId="districtSelect">
                <Form.Label>District</Form.Label>
                <SelectCity
                  state={state}
                  city={city}
                  bootstrap={true}
                  districtError={districtError}
                  handleCityChange={ handleCityChange }
                />                
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      {selectedOption === "wildly_picked" && (
        <>
          <Row>
            <Col lg={3}>
              <Form.Group controlId="districtSelect">
                <Form.Label>Forest permission permit</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{ borderRadius: "4px 0px 0px 4px" }}
                    >
                      <img src={attachImg} style={{ padding: "10px 2px" }} />
                    </span>
                  </div>
                  <div
                    className={`custom-file form-control ${
                      forestError && "is-invalid"
                    } ${forestError === false && "is-valid"}`}
                    style={{ zIndex: "unset" }}
                  >
                    <label
                      className="custom-file-label"
                      style={{ borderRadius: "0px 4px 4px 0px", width: "100%" }}
                    >
                      <input
                        type="file"
                        required={
                          formData.type !== undefined ||
                          formData.type !== "cultivated"
                            ? false
                            : "required"
                        }
                        name="forestPermission"
                        className={`custom-file-input`}
                        style={{ visibility: "hidden", width: "100%" }}
                        onChange={handleForestFileChange}
                      />
                      <span
                        style={{ position: "absolute", top: "11px", left: "1.5em" }}
                      >
                        {forestLabel}
                      </span>
                    </label>
                  </div>
                </div>
                <small className={`${forestError && "invalid-feedback"} `}>
                  {!!forestError && forestError}
                </small>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="districtSelect">
                <Form.Label>BMC</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{ borderRadius: "4px 0px 0px 4px" }}
                    >
                      <img src={attachImg} style={{ padding: "10px 2px" }} />
                    </span>
                  </div>
                  <div
                    className={`custom-file form-control ${
                      bmcError && "is-invalid"
                    } ${bmcError === false && "is-valid"}`}
                    style={{ zIndex: "unset" }}
                  >
                    <label
                      className="custom-file-label"
                      style={{ borderRadius: "0px 4px 4px 0px", width: "100%" }}
                    >
                      <input
                        type="file"
                        required={
                          formData.type !== undefined ||
                          formData.type !== "cultivated"
                            ? false
                            : "required"
                        }
                        name="bmc"
                        className={`custom-file-input`}
                        style={{ visibility: "hidden", width: "100%" }}
                        onChange={handlebmsFileChange}
                      />
                      <span
                        style={{ position: "absolute", top: "11px", left: "1.5em" }}
                      >
                        {bmcLabel}
                      </span>
                    </label>
                  </div>
                </div>
                <small className={`${bmcError && "invalid-feedback"} `}>
                  {!!bmcError && bmcError}
                </small>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="stateSelect">
                <Form.Label>Panchayat permit</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{ borderRadius: "4px 0px 0px 4px" }}
                    >
                      <img src={attachImg} style={{ padding: "10px 2px" }} />
                    </span>
                  </div>
                  <div
                    className={`custom-file form-control ${
                      panchayatError && "is-invalid"
                    } ${panchayatError === false && "is-valid"}`}
                    style={{ zIndex: "unset" }}
                  >
                    <label
                      className="custom-file-label"
                      style={{ borderRadius: "0px 4px 4px 0px", width: "100%" }}
                    >
                      <input
                        type="file"
                        required={
                          formData.type !== undefined ||
                          formData.type !== "cultivated"
                            ? false
                            : "required"
                        }
                        name="panchayatPermit"
                        className={`custom-file-input`}
                        style={{ visibility: "hidden", width: "100%" }}
                        onChange={handlePanchFileChange}
                      />
                      <span
                        style={{ position: "absolute", top: "11px", left: "1.5em" }}
                      >
                        {panchLabel}
                      </span>
                    </label>
                  </div>
                </div>
                <small className={`${panchayatError && "invalid-feedback"} `}>
                  {!!panchayatError && panchayatError}
                </small>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="districtSelect">
                <Form.Label>Fee Deposit receipt</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{ borderRadius: "4px 0px 0px 4px" }}
                    >
                      <img src={attachImg} style={{ padding: "10px 2px" }} />
                    </span>
                  </div>
                  <div
                    className={`custom-file form-control ${
                      feeError && "is-invalid"
                    } ${feeError === false && "is-valid"}`}
                    style={{ zIndex: "unset" }}
                  >
                    <label
                      className="custom-file-label"
                      style={{ borderRadius: "0px 4px 4px 0px", width: "100%" }}
                    >
                      <input
                        type="file"
                        required={
                          formData.type !== undefined ||
                          formData.type !== "cultivated"
                            ? false
                            : "required"
                        }
                        name="fdr"
                        style={{ visibility: "hidden", width: "100%" }}
                        className={`custom-file-input`}
                        onChange={handleFreeFileChange}
                      />
                      <span
                        style={{ position: "absolute", top: "11px", left: "1.5em" }}
                      >
                        {freeLabel}
                      </span>
                    </label>
                  </div>
                </div>
                <small className={`${feeError && "invalid-feedback"} `}>
                  {!!feeError && feeError}
                </small>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="districtSelect">
                <Form.Label>Additional document</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{ borderRadius: "4px 0px 0px 4px" }}
                    >
                      <img src={attachImg} style={{ padding: "10px 2px" }} />
                    </span>
                  </div>
                  <div
                    className={`custom-file form-control `}
                    style={{ zIndex: "unset" }}
                  >
                    <label
                      className="custom-file-label"
                      style={{ borderRadius: "0px 4px 4px 0px", width: "100%" }}
                    >
                      <input
                        type="file"
                        name="additionalDoc"
                        style={{ visibility: "hidden", width: "100%" }}
                        className={`custom-file-input`}
                        onChange={handleAdditionalFileChange}
                      />
                      <span
                        style={{ position: "absolute", top: "11px", left: "1.5em" }}
                      >
                        {additionalLabel}
                      </span>
                    </label>
                  </div>
                </div>
                <small className={`${additionalError && "invalid-feedback"} `}>
                  {!!additionalError && additionalError}
                </small>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col lg={6} className="mt-5">
          <Form.Group>
            <Form.Label>
              Subject to RET ( Rare, Endangered, Threatened)?
            </Form.Label>
          </Form.Group>
          <label>
            <input
              type="checkbox"
              name="ret"
              value={true}
              checked={isChecked}
              onChange={(event) => handleCheckboxChange(event)}
            />
            Yes
          </label>
        </Col>
        {isChecked && (
          <Col lg={6} className="mt-5">
            <Form.Group>
              <Form.Label>Legal procurement certificate (LPC)</Form.Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ borderRadius: "4px 0px 0px 4px" }}
                  >
                    <img src={attachImg} style={{ padding: "10px 2px" }} />
                  </span>
                </div>
                <div
                  className={`custom-file form-control ${
                    legalError && "is-invalid"
                  } ${legalError === false && "is-valid"}`}
                  style={{ zIndex: "unset" }}
                >
                  <label
                    className="custom-file-label"
                    style={{ borderRadius: "0px 4px 4px 0px", width: "100%" }}
                  >
                    <input
                      type="file"
                      name="legalFile"
                      required={!isChecked ? false : "required"}
                      style={{ visibility: "hidden", width: "100%" }}
                      className={`custom-file-input`}
                      onChange={handleLegalFileChange}
                    />
                    <span
                      style={{ position: "absolute", top: "", left: "1.5em" }}
                    >
                      {legalLabel}
                    </span>
                  </label>
                </div>
              </div>
              <small className={`${legalError && "invalid-feedback"} `}>
                {!!legalError && legalError}
              </small>
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={12} className="mt-5">
          <div className="float-end">
            <Button variant="secondary" onClick={previousStep}>
              Previous
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={(e) => handleNext(e)}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CollectionMethodForm;

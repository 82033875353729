import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import styles from "./style.module.css";
import { useNavigate } from "react-router";
import { useAuth } from "../../../../contexts/auth";
import { toastWarning } from "../../../../utils/toastHelper";
import ReactSelect from "react-select";

const HerbalDetailForm = ({
  formData,
  handleInputChange,
  saveHerbalDetails,
  Plants,
  setFormData
}) => {
  const [herbNameError, setHerbNameError] = useState(null);
  const [herbNewTradeError, setHerbNewTradeError] = useState(null);
  const [useablePartError, setUseablePartError] = useState(null);
  const [valueAddedProductError, setValueAddedProductError] = useState(null);
  const [quantityError, setQuantityError] = useState(null);
  const [askedPriceError, setAskedPriceError] = useState(null);
  const [packSizeError, setPackSizeError] = useState(null);
  const [ptypeError, setPTypeError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  const [formValidated, setFormValidated] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [submitDisabled, setsubmitDisabled] = useState(null);
  const [numericError, setNumericError] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const auth = useAuth();

const [plantsData, setPlantsData] = useState({herbName:[],usableParts:[]});

useEffect(() => {
  if (Plants?.body) {
  
    let allHerbName = []
    Plants?.body.forEach(element => {
      let tradeName =element.tradeNames.join(",");
      allHerbName.push({label:`${element.name} / ${tradeName}`,value:`${element.name}`})
      // element.tradeNames.forEach(tradeName => {
      //   allHerbName.push({
      //     label: tradeName,
      //     value: tradeName,
      //   })
      // })
    });
    allHerbName.push({label:"Others",value:"Others"})
    setPlantsData((prevData) => ({ ...prevData, herbName:allHerbName }))
  }
  
}, [Plants]);
// Custom filter function
  const [errorContainer,seterrorContainer] = useState({
    herbName:'',
    usableParts:'',
    quantity:'',
    askedPrice:'',
    packSize:'',
    ptype:'',
    description:''
  });

  const packagingTypes = [
    { value: "", label: "Select Packaging Type" },
    { value: "Gunny bags", label: "Gunny bags" },
    { value: "PP bags", label: "PP bags" },
    { value: "Loose", label: "Loose" },
    { value: "Others", label: "Others" },
  ];
  const valueAddedProduct =[{value:"Aroma/ Perfumery",label:"Aroma/ Perfumery"},
    {value:"Cosmetics (Soap, shampoo,gel, cream etc)",label:"Cosmetics (Soap, shampoo,gel, cream etc)"},
    {value:"Essential oils",label:"Essential oils"},
    {value:"Extracts",label:"Extracts"},
    {value:"Food Products",label:"Food Products"},
    {value:"Herbal formulation",label:"Herbal formulation"},
    {value:"Incense sticks",label:"Incense sticks"},
    {value:"Powder",label:"Powder"}]
  const maxlengthofTextBox = (event) => {
    const { value } = event.target;
    setCount(value.length);
    handleInputChange(event);
  };
  const findNumericAndDecimal = (event) => {
    const { value } = event.target;
    if (value === "" || /^\d+(\.\d+)?$/.test(value)) {
      if (event.target.name === "packSize") {
        setPackSizeError("");
      }
      if (event.target.name === "quantity") {
        setQuantityError("");
      }
      if (event.target.name === "askedPrice") {
        setAskedPriceError("");
      }
      handleInputChange(event);
    } else {
      if (event.target.name === "packSize") {
        setPackSizeError("Please enter a numeric value.");
      }
      if (event.target.name === "quantity") {
        setQuantityError("Please enter a numeric value.");
      }
      if (event.target.name === "askedPrice") {
        setAskedPriceError("Please enter a numeric value.");
      }
    }
  };

  const handleNextButton = (event) => {

   
    if (!formData.herbName) {
      setHerbNameError("Please enter a herb name.");
      seterrorContainer({herbName:"Please enter a herb name."});
    } else {
     
      setHerbNameError(null);
    }
   
    if (formData.herbName === "Others") {
      if (!formData.newHerbName) {
        setHerbNameError("Please enter a herb name.");
        seterrorContainer({herbName:"Please enter a herb name."});
      }

      if (!formData.newTradeName) {
        setHerbNewTradeError("Please enter a Trade name.");
      }else{
        setHerbNewTradeError(null)
      }
      let check = Plants?.body.find((element) => element.name === formData.newHerbName)
      if (check) {
        return  setHerbNameError("New herb name already exists.")
      }
   }
    if (!formData.usableParts) {
      setUseablePartError("Please enter a Usable Parts.");
      seterrorContainer({usableParts:"Please enter a useable parts."});
    } else {
      setUseablePartError(null);
    }
    if (!formData.valueAddedProduct) {
      setValueAddedProductError("Please enter a variety.");
      seterrorContainer({valueAddedProduct:"Please enter a variety."});
    } else {
      setValueAddedProductError(null);
    }
    if (!formData.quantity) {
      setQuantityError("Please enter a quantity.");
    } else {
      setQuantityError(null);
    }
    if (!formData.askedPrice) {
      setAskedPriceError("Please enter an ask price.");
    } else {
      setAskedPriceError(null);
    }
    if (!formData.packSize) {
      setPackSizeError("Please enter a packaging size.");
    } else {
      setPackSizeError(null);
    }
    if (!formData.ptype || formData.ptype === "") {
      setPTypeError("Please select a packaging type.");
    } else {
      setPTypeError(null);
    }
    if (formData.ptype === "Others") {
      if (!formData.otherPackageType || formData.otherPackageType === "") {
        setPTypeError("Please select a packaging type.");
      } else {
        setPTypeError(null);
      }
    }
    if (!formData.description) {
      setDescriptionError("Please enter a grade description.");
    } else if (count > 500) {
      setDescriptionError("Description should be of 500 characters only");
    } else {
      setDescriptionError(null);
    }

    // if(count > 500){
    //   setDescriptionError("Description should be of 500 characters only");
    // } else {
    //   setDescriptionError(null);
    // }
    // setErrorMessage();
    if (
      formData.herbName &&
      formData.quantity &&
      formData.askedPrice &&
      formData.packSize &&
      formData.ptype &&
      formData.description &&
      count <= 500
    ) {
      // Form is valid, perform submission
      saveHerbalDetails();
    }
  };

  const localhandleOnblur = (event) => {
    setErrorMessage(event.target.id);
  };
 const handleChangeSelect = (event,name) => {
   if (event == null) {
    setFormData((prevData) => ({ ...prevData, [name]: null }));
   }
   if (event &&event.value != "") {
    setFormData((prevData) => ({ ...prevData, [name]: event.value }));
    seterrorContainer((prevData) => ({ ...prevData, [name]: "" }));
  }
 }

const handleUseablePartData = (event) => {
  setFormData((prevData) => ({ ...prevData, usableParts: "" }));
  if (event && event.value != "") {
    if (event.value == "Others") {
   

    } else {
      let usablePartsData = Plants?.body.find(
        (plant) =>
          plant.name == event.value || plant.tradeNames.includes(event.value)
      );
      let usableParts = usablePartsData.usableParts.map((usablePart) => ({
        label: usablePart,
        value: usablePart,
      }));
      setPlantsData((prevData) => {
        return { ...prevData, usableParts };
      });
    }
  }
}
  const setErrorMessage = (id) => {    
    switch (id) {
      case "herbName":
        if (!formData.herbName) {
          setHerbNameError("Please enter a herb name.");
        } else {
          setHerbNameError(false);
        }
        break;
      case "quantity":
        if (!formData.quantity) {
          setQuantityError("Please enter a quantity.");
        } else {
          setQuantityError(null);
        }
        break;
      case "askedPrice":
        if (!formData.askedPrice) {
          setAskedPriceError("Please enter an ask price.");
        } else {
          setAskedPriceError(null);
        }
        break;

      case "packSize":
        if (!formData.packSize) {
          setPackSizeError("Please enter a packaging size.");
        } else {
          setPackSizeError(null);
        }
        break;
      case "ptype":
        if (!formData.ptype || formData.ptype === "") {
          setPTypeError("Please select a packaging type.");
        } else {
          setPTypeError(null);
        }
        break;
        case "description":
          if (!formData.description) {
            setDescriptionError("Please enter a grade description.");
          } else if (count > 500) {
            setDescriptionError("Description should be of 500 characters only");
          } else {
            setDescriptionError(null);
          }
        break;
    }
    
  };

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <h3 className="formheadingText">Herb details</h3>
      <Form noValidate validated={formValidated}>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="herbName">
              <Form.Label>
                <span className="headingText">Herb Name</span>
              </Form.Label>

              <ReactSelect
                value={{ value: formData.herbName, label: formData.herbName }}
                onChange={(e) => {
                  handleChangeSelect(e, "herbName");
                  // handleVerityData(e)
                  handleUseablePartData(e);
                }}
                options={plantsData.herbName}
                placeholder="Select a herbName..."
                isClearable
                isSearchable
                name="herbName"
                className={`form-control ${herbNameError && "is-invalid"} ${
                  herbNameError === false && "is-valid"
                }`}
              />
              {formData.herbName === "Others" ? (
                <div>
                  <div className="mt-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter new herb name"
                    name="newHerbName"
                    onChange={handleInputChange}
                    onBlur={localhandleOnblur}
                    className={`form-control ${herbNameError && "is-invalid"} ${
                      herbNameError === false && "is-valid"
                    }`}
                  />
                      <small className={`mt-3, mb-3 ${herbNameError && "invalid-feedback"} `}>
                    {!!herbNameError && herbNameError}
                  </small>
                  </div>
              
                  <div className="mt-3 , mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter new Botanical Name"
                      name="newTradeName"
                      onChange={handleInputChange}
                      onBlur={localhandleOnblur}
                      className={` px-2 form-control ${herbNewTradeError && "is-invalid"} ${
                        herbNewTradeError === false && "is-valid"
                      }`}
                    />
                      <small className={`${herbNewTradeError && "invalid-feedback"} `}>
                {!!herbNewTradeError && herbNewTradeError}
              </small>
                  </div>
                </div>
              ):(
                <small className={`mt-3, mb-3 ${herbNameError && "invalid-feedback"} `}>
                {!!herbNameError && herbNameError}
              </small>
              )}
            
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="usableParts">
              <Form.Label>
                <span className="headingText">Usable Parts</span>
              </Form.Label>
              {formData.herbName === "Others" ? (
                <Form.Control
                  type="text"
                  placeholder="Enter usable parts"
                  name="usableParts"
                  onChange={handleInputChange}
                  onBlur={localhandleOnblur}
                  className={`form-control ${
                    useablePartError && "is-invalid"
                  } ${useablePartError === false && "is-valid"}`}
                />
              ) : (
                <ReactSelect
                  value={{
                    value: formData.usableParts,
                    label: formData.usableParts,
                  }}
                  onChange={(e) => {
                    handleChangeSelect(e, "usableParts");
                  }}
                  options={plantsData.usableParts}
                  placeholder="Select a useable Parts..."
                  isClearable={true}
                  isSearchable
                  name="usableParts"
                  className={`form-control ${
                    useablePartError && "is-invalid"
                  } ${useablePartError === false && "is-valid"}`}
                />
              )}

              <small className={`${useablePartError && "invalid-feedback"} `}>
                {!!useablePartError && useablePartError}
              </small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="valueAddedProduct">
              <Form.Label>
                <span className="headingText">Variety</span>
              </Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Enter Variety..."
                  name="valueAddedProduct"
                  onChange={handleInputChange}
                  onBlur={localhandleOnblur}
                  className={`form-control ${
                    valueAddedProductError && "is-invalid"
                  } ${valueAddedProductError === false && "is-valid"}`}
                />
              <small
                className={`${valueAddedProductError && "invalid-feedback"} `}
              >
                {!!valueAddedProductError && valueAddedProductError}
              </small>
            </Form.Group>
          </Col>
          
        </Row>
        <Row>
        <Col lg={4}>
            <Form.Group controlId="quantity">
              <Form.Label>
                <span className="headingText">Total quantity(Kg)</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="quantity"
                value={formData.quantity}
                onChange={findNumericAndDecimal}
                onBlur={localhandleOnblur}
                maxLength={10}
                className={`form-control ${quantityError && "is-invalid"} ${
                  quantityError === false && "is-valid"
                }`}
                required
              />
              <small className={`${quantityError && "invalid-feedback"} `}>
                {!!quantityError && quantityError}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="askedPrice">
              <Form.Label>
                <span className="headingText">Ask price(Kg)</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="askedPrice"
                value={formData.askedPrice}
                onChange={findNumericAndDecimal}
                onBlur={localhandleOnblur}
                maxLength={10}
                className={`form-control ${askedPriceError && "is-invalid"} ${
                  askedPriceError === false && "is-valid"
                }`}
                required
              />
              <small className={`${askedPriceError && "invalid-feedback"} `}>
                {!!askedPriceError && askedPriceError}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="packSize">
              <Form.Label>
                <span className="headingText">Packaging size(Kg/Pack)</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="packSize"
                value={formData.packSize}
                onChange={findNumericAndDecimal}
                onBlur={localhandleOnblur}
                maxLength={10}
                className={`form-control ${packSizeError && "is-invalid"} ${
                  packSizeError === false && "is-valid"
                }`}
                required
              />
              <small className={`${packSizeError && "invalid-feedback"} `}>
                {!!packSizeError && packSizeError}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="ptype">
              <Form.Label>
                <span className="headingText">Packaging type</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="ptype"
                value={formData.ptype}
                onChange={handleInputChange}
                onBlur={localhandleOnblur}
                className={`form-control ${ptypeError && "is-invalid"} ${
                  ptypeError === false && "is-valid"
                }`}
                required
              >
                {packagingTypes.map((packagingType) => (
                  <option key={packagingType.value} value={packagingType.value}>
                    {packagingType.label}
                  </option>
                ))}
              </Form.Control>
         
              {formData.ptype === "Others" && (
                <div className="mt-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Other Package Type"
                    name="otherPackageType"
                    onChange={handleInputChange}
                    onBlur={localhandleOnblur}
                    className={`form-control ${ptypeError && "is-invalid"} ${
                      ptypeError === false && "is-valid"
                    }`}
                  />
              
                </div>
              )}
                   <small className={`${ptypeError && "invalid-feedback"} `}>
                {!!ptypeError && ptypeError}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Form.Group controlId="description">
              <Form.Label>
                <span className="headingText">Grade description</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={maxlengthofTextBox}
                onBlur={localhandleOnblur}
                className={`form-control ${descriptionError && "is-invalid"} ${
                  descriptionError === false && "is-valid"
                }`}
                required
              />
              <div
                className={`float-end pt-1 ${count > 500 && "text-danger"} `}
              >
                {count}/500 characters
              </div>
              <small className={`${descriptionError && "invalid-feedback"} `}>
                {!!descriptionError && descriptionError}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mt-5">
            <div className="float-end">
              <Button variant="success" onClick={handleNextButton}>
                Next
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default HerbalDetailForm;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  RejectedAuctions:{auctions:[],totalRecords:0},
  
  
};

const sellerRejectedAuctions = createSlice({
  name: "sellerRejectedAuctions",
  initialState,
  reducers: {
    fetchRejectedAuctions(state, action) {
      state.loading = true;
    },
    fetchRejectedAuctionsSuccess(state, action) {
      state.RejectedAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchRejectedAuctionsFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = sellerRejectedAuctions;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import styles from "./styles.module.css";
import herb from "../../../../assets/homeImages/herb.png";
import LiveAuctionCard from "../../../components/homeApp/liveAuctionCard/index";
import ButtonViewAll from "../../../components/homeApp/buttonViewAll";
import circleIcon from "../../../../assets/icons/Ellipse.svg";
import shiningIcon from "../../../../assets/icons/newShining.svg";
import linkIcon from "../../../../assets/icons/FiLink.svg";
import { Link } from "react-router-dom";
import LandingCarousel from "../../../components/homeApp/landingCarousel";
import empoweringFarmersImage from "../../../../assets/homeImages/Empowering_Farmers.jpg";
import traceabilityImage from "../../../../assets/homeImages/Traceability_and_transparency.jpg";
import sustainableImage from "../../../../assets/homeImages/Sustainable_and_ethical.jpg";
import qualityImage from "../../../../assets/homeImages/Quality_and_convenience.jpg";
import saphedMusli from "../../../../assets/homeImages/safed_musli.svg";
import moringa from "../../../../assets/homeImages/moringa.svg";
import rosemary from "../../../../assets/homeImages/rosemary.svg";
import basil from "../../../../assets/homeImages/basil.svg";
import tulsi from "../../../../assets/homeImages/tulsi.svg";
import shatavari from "../../../../assets/homeImages/shatavari.svg";
// import ayushMinistryLogo from "../../../../assets/icons/ministryOfAyush.svg";
// import indiaGovLogo from "../../../../assets/icons/indiaGov.svg";
// import myGovLogo from "../../../../assets/icons/myGov.svg";
// import dataGovLogo from "../../../../assets/icons/dataGov.svg";
// import makeInIndiaLogo from "../../../../assets/icons/makeInIndia.svg";
// import nationalMedicinalPlantBoard from "../../../../assets/icons/nationalMedicinalPlantsBoard.svg";
// new images for image-slider
import digitalindia from "../../../../assets/icons/logoslide/digitalindia.svg"
import mygov from "../../../../assets/icons/logoslide/mygov.svg";
import echarka from "../../../../assets/icons/logoslide/echarka.svg";
import enam from "../../../../assets/icons/logoslide/enam.svg";
import ministryofayush from "../../../../assets/icons/logoslide/ministryofayush.svg";
import nmpb from "../../../../assets/icons/logoslide/nmpb.svg";
import skillindialogo from "../../../../assets/icons/logoslide/skillindialogo.svg";
import startuphimachal from "../../../../assets/icons/logoslide/startuphimachal.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import routes from "../../../../routes";
import { reverse } from "named-urls";

import { toast } from "react-toastify";
import { useAuth } from "../../../contexts/auth";
import { el } from "date-fns/locale";


// import rawHerbPdf from "/pdf/Raw_Herbs_List.pdf";

// mocking backend data
const images = [];
for (let i = 0; i < 8; i++) {
  images.push({ url: herb, herbName: "herb name", price: 12000, unit: "kg" });
}

const logosSlides =[
{
image:ministryofayush,
url:"https://ayush.gov.in/"
},
{
image:nmpb,
url:"https://nmpb.nic.in/"
},
{
image:echarka,
url:"https://echarak.in/echarak/"
},
{
image:enam,
url:"https://www.enam.gov.in/web/"
},
{
image:skillindialogo,
url:"https://www.skillindiadigital.gov.in/home"
},
{
image:startuphimachal,
url:"https://www.startupindia.gov.in"
},
{
image:mygov,
url:"https://www.mygov.in/"
},
{
image:digitalindia,
url:"https://www.digitalindia.gov.in/"
},

]

// const officialLogos = [
//   ayushMinistryLogo,
//   indiaGovLogo,
//   myGovLogo,
//   dataGovLogo,
//   makeInIndiaLogo,
//   nationalMedicinalPlantBoard,
// ];

var settings = {
  className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    speed: 3000,
    dots:true,
    autoplay: true,
    pauseOnHover: true,
    cssEase: "linear",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
};

var settingslogo = {
  
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    speed: 3000,
    dots:true,
    autoplay: true,
    pauseOnHover: true,
    cssEase: "linear",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
};
const defaultImage = herb;

const herbImages = [
  { imgUrl: saphedMusli, herbName: "Safed Musli" },
  { imgUrl: moringa, herbName: "Moringa" },
  { imgUrl: rosemary, herbName: "Rosemary" },
  { imgUrl: basil, herbName: "Basil" },
  { imgUrl: tulsi, herbName: "Tulsi" },
  { imgUrl: shatavari, herbName: "Shatavari" },
];

// const newInformation = [
//   // { title: "Commodity available- Chamomile", link: "/pdf/Commodity available- Chamomile.pdf" },
//   // { title: "Commodity available- Harad", link: "/pdf/Commodity available- Harad.pdf" },
//   // { title: "Commodity available- yellow turmeric-1", link: "/pdf/Commodity available- yellow turmeric-1.pdf" },
//   // { title: "Commodity available-black turmeric and black ginger", link: "/pdf/Commodity available-black turmeric and black ginger.pdf" },
//   // { title: "QPM available for Atis--1", link: "/pdf/QPM available for Atis--1.pdf" },
//   // { title: "QPM available for Kuth-Shimla and chamba", link: "/pdf/QPM available for Kuth-Shimla and chamba.pdf" },
// ];

// const relatedLinks = [
//   // { title: "Raw Herb List", link: "/pdf/Raw_Herbs_List.pdf" },
//   // { title: "The best way to have these herbs to reduce bloating", link: "#" },
//   // { title: "Best herb seeds to plant in spring", link: "#" },
//   // { title: "Boost Your Brain Power And Fight Diabetes", link: "#" },
//   // { title: "Pelicans' Guard Herb Jones Snubbed From NBA", link: "#" },
//   // { title: "Lion Order: Free The Herb", link: "#" },
   
// ];

const carouselItems = [
  {
    image: empoweringFarmersImage,
    heading: "Empowering Farmers, Expanding Horizons",
    subHeading:
      "JadiButiBazar is committed to empowering farmers by providing them with a fair and transparent platform to sell their herbs.",
  },
  {
    image: traceabilityImage,
    heading: "Traceability and Transparency",
    subHeading:
      "JadiButiBazar uses state-of-the-art technology, including blockchain, to ensure traceability and transparency throughout the herb supply chain. This gives buyers confidence in the quality and authenticity of the herbs they purchase.",
  },
  {
    image: sustainableImage,
    heading: "Sustainable and Ethical",
    subHeading:
      "JadiButiBazar is committed to promoting sustainable and ethical practices in the herb trade. This helps to safeguard the environment and ensure that herbs are produced in a responsible way.",
  },
  {
    image: qualityImage,
    heading: "Quality and Convenience",
    subHeading:
      "JadiButiBazar provides end-to-end traceability and quality assurance for all herbs sold on the platform. This gives buyers peace of mind knowing that they are getting high-quality herbs that have been produced in a responsible way.",
  },
];

export default function Landing() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();
  const auth = useAuth();
const [relatedLinks, setRelatedLinks] = useState([])
const [newInformation, setNewInformation] = useState([])
  useEffect(() => {
    dispatch(actions.fetchLiveAuctions());
    dispatch(actions.fetchLinks());
  }, [dispatch]);

  const recentLiveAuctions = useSelector(selectors.selectrecentauctions) || {
    auctions: [],
    totalRecords: 0,
  };
  const homeLinks = useSelector(selectors.selectLinkList) || [];

  useEffect(() => {
    console.log("home link",homeLinks)
    if (homeLinks.totalRecords > 0) {
      let relatedLinksData = [];
      let newInformationData = [];
      homeLinks.Links.forEach((link) => {
        if (link.status) {
          if (link.type === "whatsNew") {
            newInformationData.push({ title: link.name, link: link.link });
          } else if (link.type === "relatedLinks") {
            relatedLinksData.push({ title: link.name, link: link.link });
          }
        }
      });

      setRelatedLinks(relatedLinksData);
      setNewInformation(newInformationData);
    }
  }, [homeLinks]);
  let liveAuctions = recentLiveAuctions.auctions;
  return (
    <>
      <main>
        <LandingCarousel carouselItems={carouselItems} />
      </main>

      {/* <section className={styles.liveAuctions}>
        <div className={styles.sectionHeader}>

      
         <h2>Recent live auctions</h2>
      
        

          <Link
            to={"/auctions"}
            style={{ color: "unset", textDecoration: "none" }}
          >
            {liveAuctions.length !== 0 && <ButtonViewAll />}
          </Link>
        </div>
        <Slider {...settings}>

          {liveAuctions.map((herb, index) => (
            <div className={styles.card}>
              <Link
                to={reverse(`${routes.home.auctions.auctionDetails}`, {
                  id: herb._id,
                })}
              >
                <div className={styles.imageContainer}>
                  <img src={herb.images[0].imageUrl} alt={herb.herbName} style={{ width: "100%" }} />
                  <span className={styles.liveBadge}>Live</span>
                </div>
                <div className={styles.herbDetails}>
                  <h3>{herb.herbName}</h3>
                  <p>
                    Ask Price{" "}
                    <span>
                      ₹{herb.askedPricePerUnit}/{herb.quantityUnit}
                    </span>
                  </p>
                </div>


              </Link>

            </div>
          ))}


        </Slider>


        {liveAuctions.length === 0 && (
          <div className={styles.centerText}>No Live Auction available.</div>
        )}
      </section> */}


      <section className={styles.liveAuctions}>
  <div className={styles.sectionHeader}>
  
    <h2 className={styles.pulsatingHeader}>Live Auctions</h2>

    <Link
      to={"/auctions"}
      style={{ color: "unset", textDecoration: "none" }}
    >
      {liveAuctions.length !== 0 && <ButtonViewAll />}
    </Link>
  </div>

  <Slider {...settings}>
    {liveAuctions.map((herb, index) => (
      <div className={styles.card} key={index}>
        <Link
          to={reverse(`${routes.home.auctions.auctionDetails}`, {
            id: herb._id,
          })}
        >
          <div className={styles.imageContainer}>
            <img
              src={herb.images[0].imageUrl}
              alt={herb.herbName}
              style={{ width: "100%" }}
            />
            <span className={styles.liveBadge}>Live</span>
          </div>
          <div className={styles.herbDetails}>
            <h3>{herb.herbName}</h3>
            {auth && auth.loggedInUser && (
            <p>
              Ask Price{" "}
              <span>
                ₹{herb.askedPricePerUnit}/{herb.quantityUnit}
              </span>
            </p>
            )}
          </div>
        </Link>
      </div>
    ))}
  </Slider>

  {liveAuctions.length === 0 && (
    <div className={styles.centerText}>No Live Auction available.</div>
  )}
</section>


      {/* <section className={styles.liveAuctions}>
        <div className={styles.sectionHeader}>
          <h2>Recent live auctions</h2>
          <Link
            to={"/auctions"}
            style={{ color: "unset", textDecoration: "none" }}
          >
            {liveAuctions.length !== 0 && <ButtonViewAll />}
          </Link>
        </div>

        {liveAuctions.length !== 0 && (
          <div className={styles.liveAuctionCards}>
            {liveAuctions.map((herb, index) => (
              <LiveAuctionCard
                url={herb}
                herbName={herb.herbName}
                price={herb.askedPricePerUnit}
                unit={herb.quantityUnit}
                key={herb._id}
                auctionId={herb._id}
              />
            ))}
          </div>
        )}
        {liveAuctions.length === 0 && (
          <div className={styles.centerText}>No Live Auction available.</div>
        )}
      </section> */}
      {homeLinks.totalRecords > 0 && (
         <section className={styles.information}>
         <div className={styles.informationLeft}>
           <div className={styles.infoHeader}>
             <img src={shiningIcon} alt="new information icon" />
             <h2>What's new</h2>
           </div>
           <ul>
             {newInformation.map((info, index) => (
               <li key={index}>
                 <img src={circleIcon} alt="list icon" />
                 <Link to={info.link} rel="noreferrer" target="_blank">
                   {info.title}
                 </Link>
               </li>
             ))}
           </ul>
         </div>
         <div className={styles.informationRight}>
           <div className={styles.infoHeader}>
             <img src={linkIcon} alt="related links icon" />
             <h2>Related links</h2>
           </div>
           <ul>
             {relatedLinks.map((info, index) => (
               <li key={index}>
                 <img src={circleIcon} alt="list icon" />
                 <Link to={info.link} rel="noreferrer" target="_blank">
                   {info.title}
                 </Link>
               </li>
             ))}
           </ul>
         </div>
       </section>
      )}
     
      {/* <section className={styles.liveAuctions}>
        <div className={styles.sectionHeader}>
          <h2>Herbs</h2>
          <a href="/pdf/Raw_Herbs_List.pdf" target="_blank" rel="noreferrer">
            <ButtonViewAll />
          </a>
        </div>
        <div className={styles.herbList}>
          {herbImages.map((herb, index) => (
            <div key={index} className={styles.herbCard}>
              <div className={styles.herbImage}>
                <img src={herb.imgUrl} alt={herb.herbName} />
              </div>
              <p>{herb.herbName}</p>
            </div>
          ))}
        </div>
      </section> */}
      
      {/* <section className={styles.logoList + " container overflow-auto gap-5 ps-5 pe-5 "}> */}

        {/* <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
              {logosSlides.map((herb, index) => (
          <div className="carousel-item ">
          <a href={herb.url} target="_blank"> <img src={herb.image} alt="official ministry logo" /></a>
          </div>
      ))}
        </div>
      </div> */}
        {/* {logosSlides.map((herb, index) => (
          <div key={index} className={styles.logoContainer}>
            <a href={herb.url} target="_blank"> <img src={herb.image} alt="official ministry logo" /></a>
          </div>
        ))} */}
      {/* </section> */}
      <section className={styles.logoList +" container overflow-auto gap-5 ps-5 pe-5 bottomslider"} >
      <Slider {...settingslogo} >
      {logosSlides.map((herb, index) => (
          <div key={index} className={styles.logoContainer}>
            <a href={herb.url} target="_blank"> <img src={herb.image} alt="official ministry logo" /></a>
          </div>
        ))}

      </Slider>
      </section>

  
    </>
  );
}

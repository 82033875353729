import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state) => state?.draftAuctionCreation || initialState;

export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);

export const selectMessage = createSelector(
  [selectDomain],
  (state) => state.message
);

export const selectAuctionId = createSelector(
  [selectDomain],
  (state) => state.auctionId
);

export const selectSellerAuction = createSelector([selectDomain], (state) => state.auctionDetails);

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Badge, Dropdown } from "react-bootstrap";
import styles from "./Suppliers.module.css";
import Heading from "../../../components/shared/Heading";
import SearchIcon from "../../../../assets/dashboardImages/searchBlack.svg";
import CustomTable from "../../../components/dashboardApp/customTable";
import TableFooter from "../../../components/dashboardApp/tableFooter";
import { useNavigate } from "react-router-dom";
import AdminCustomTable from "../../../components/dashboardApp/adminCustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions, initialState } from "./slice";
import { suppliersListingSaga } from "./saga";
import * as selectors from "./selectors";
import UsersCustomTable from "../../../components/dashboardApp/adminCustomTable/userTable";
const Suppliers = () => {
  const navigate = useNavigate();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: suppliersListingSaga });
  const dispatch = useDispatch();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [fromPage, setFromPage] = useState(1);
  const [dropDownValue, setDropDownValue] = useState("approved");
  const getSupplierFilterStatus = useSelector(selectors.getSupplierFilterStatus) || initialState.buyerSupplierFilterStatus;
  useEffect(() => {
        setDropDownValue(getSupplierFilterStatus);
  }, [getSupplierFilterStatus])
  const handleActionStatusChange = (status) => {
    dispatch(actions.changeSupplierFilterStatus(status));
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    // Perform any other actions based on the new items per page value
  };
  useEffect(() => {}, [dropDownValue]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const dbUsersList = useSelector(selectors.selectbuyerLlist) || initialState;

  const allSupplierList = [];
  const supplierAllHeading = [
    {
      supplierID: "Supplier ID",
      supplierName: "Supplier Name",
      phoneNumber: "Phone Number",
      regDate: "Registration Date",
      noOfAuctions: "No of Auctions",
      participatedAuctions: "Participated Auctions",
      status: "Status",
    },
  ];
  if (dbUsersList.users && dbUsersList.users.length) {
    const dbusers = dbUsersList.users;
    for (let i = 0; i < dbusers.length; i++) {
      allSupplierList.push({
        supplierID: dbusers[i]["_id"],
        supplierName: dbusers[i]["companyName"],
        regDate: dbusers[i]["createdAt"],
        noOfAuctions: "11",
        participatedAuctions: "4",
        supplierEmail: dbusers[i]["email"],
        supplierNumber: dbusers[i]["phone"],
        status: dbusers[i]["status"], //supplierStatus[Math.floor(Math.random() * (1 - 0 + 1)) + 0],
      });
    }
  }

  const totalCount = dbUsersList.totalRecords;
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSupplierClick = (supplierId) => {
    navigate(supplierId);
  };

  useEffect(() => {
    const payload = {
      name: searchQuery,
      status: dropDownValue,
      page: currentPage - 1,
      limit: itemsPerPage,
    };
    dispatch(actions.fetchSuppliers(payload));
  }, [dispatch, searchQuery, dropDownValue, currentPage, itemsPerPage]);

  return (
    <>
      <section>
        <Container fluid>
          <Row>
            <Col lg={7} className={styles.marginBottom36}>
              <h2>Suppliers</h2>
            </Col>
            {/* priority is slow of customize view */}
            <Col lg={2} className={styles.marginBottom36}>
              <Dropdown>
              <Dropdown.Toggle
                  style={{ textTransform: "capitalize" }}
                  variant="light"
                  id="dropdown-basic"
                >
                  {dropDownValue}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ textTransform: "capitalize" }}
                    onClick={(e) => handleActionStatusChange("approved")}
                  >
                    approved
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ textTransform: "capitalize" }}
                    onClick={(e) => handleActionStatusChange("review")}
                  >
                    review
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ textTransform: "capitalize" }}
                    onClick={(e) => handleActionStatusChange("draft")}
                  >
                    draft
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ textTransform: "capitalize" }}
                    onClick={(e) => handleActionStatusChange("reject")}
                  >
                    reject
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ textTransform: "capitalize" }}
                    onClick={(e) => handleActionStatusChange("block")}
                  >
                    block
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col lg={3} className={styles.marginBottom36}>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span
                    className={["input-group-text", styles.basic_addon].join(
                      " "
                    )}
                    id="basic-addon1"
                  >
                    <img src={SearchIcon} alt="" />
                  </span>
                </div>
                <input
                  type="text"
                  id={styles.search}
                  className="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
              {/* <input type="text"  value={searchQuery} onChange={handleSearchInputChange} /> */}
            </Col>
          </Row>
          <Row>
            <Col lg={12} className={styles.cardTable}>
              <UsersCustomTable
                heading={supplierAllHeading}
                filteredData={allSupplierList}
                bids={true}
                onRowClick={handleSupplierClick}
                dropDownValue={dropDownValue}
                type={"supplier"}
              />
            </Col>
          </Row>

          {pageCount > 1 ? (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              currentPage={currentPage}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              totalCount={totalCount}
              fromCount={fromPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </section>
    </>
  );
};

export default Suppliers;

import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchMandiById({ payload }) {
  try {
    const res = yield call(API.fetchMandiData, payload);    
    const parsedObject = JSON.parse(res.data);    
   
    const { body } = parsedObject;
    yield put(actions.fetchMandiByIdSuccess(body));
  } catch (err) {
    yield put(actions.fetchMandiByIdFailure(err?.response?.data));
  }
}


export function* handleCreateMandi({ payload }) {
  try {
    const res = yield call(API.createMandiData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { body } = parsedObject;
    yield put(actions.createMandiSuccess(body));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.createMandiFailure(err?.response?.data));
  }
}

export function* handleUpdateMandi({ payload }) {
  try {
   
    const res = yield call(API.updateMandiData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { body } = parsedObject;
    yield put(actions.updateMandiSuccess(body));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.updateMandiFailure(err?.response?.data));
  }
}

export function* handleDeleteMandi({ payload }) {
  try {
    const res = yield call(API.removeMandiData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { data } = parsedObject;
    yield put(actions.removeMandiSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.removeMandiFailure(err?.response?.data));
  }
}





/**
 * Root saga manages watcher lifecycle
 */
export function* MandisSaga() {
  yield takeLatest(actions.fetchMandiById.type, handleFetchMandiById);
  yield takeLatest(actions.createMandi.type, handleCreateMandi);
  yield takeLatest(actions.updateMandi.type, handleUpdateMandi);
  yield takeLatest(actions.removeMandi.type, handleDeleteMandi);
}

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  lostAuctions:{auctions:[],totalRecords:0},
};

const lostAuctions = createSlice({
  name: "buyerLostAuctions",
  initialState,
  reducers: {
    fetchLostAuctions(state, action) {
      state.loading = true;
    },
    fetchLostAuctionsSuccess(state, action) {
      state.lostAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchLostAuctionsFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = lostAuctions;

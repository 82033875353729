import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Tabs, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import rightArrow from "../../../../assets/dashboardImages/rightArrow.svg";
import styles from "./style.module.css";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions, initialState } from "./slice";
import { LinksSaga } from "./saga";
import * as selectors from "./selectors";
import { toastSuccess } from "../../../utils/toastHelper";
const LinkEdit = () => {
  const navigate = useNavigate();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: LinksSaga });

  const Link = useSelector(selectors.Link) || {};
  // const userDocument = userDetail.document;

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (Link?.name) {
      setFormData({
        name: Link?.name,
        link: Link?.link,
        type: Link?.type,
        status: Link.status ? "Active" : "Inactive",
      });
    }
  }, [Link]);

  const handleChangeSelect = (event, name) => {
    if (event == null) {
      setFormData((prevData) => ({ ...prevData, [name]: null }));
    }
    if (event && event.value != "") {
      setFormData((prevData) => ({ ...prevData, [name]: event.value }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  useEffect(() => {
    let url = new URL(window.location.href);
    const linkId = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
    if (linkId != "add") {
      dispatch(actions.fetchLinkById(linkId));
    }else{
      dispatch(actions.resetLink());
    }
  }, [dispatch]);

  function isUrlValid(url) {
    const regex = /^(http:\/\/|https:\/\/)/;
    return regex.test(url);
}
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.link) {
      errors.link = "Link is required";
    }
   
    if(!isUrlValid(formData.link)){
      errors.link = "Invalid URL format, https:// or http:// is required";
    }
    if (!formData.type) {
      errors.type = "Type is required";
    }
    if (!formData.status) {
      errors.status = "Status is required";
    }
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    // return;
    if (Link?.name) {
      const payload = {
        data: {
          id: Link?._id,
          ...formData,
          status: formData.status == "Active" ? true : false,
        },
        onSuccess: () => {
          toastSuccess("Link updated successfully");
          setFormData({});
          setErrors({});
          navigate("/dashboard/admin/links");
        },
      };
      dispatch(actions.updateLink(payload));
    } else {
      const payload = {
        data: {
          ...formData,
          status: formData.status == "Active" ? true : false,
        },
        onSuccess: () => {
          toastSuccess("Link created successfully");
          setFormData({});
          setErrors({});
          navigate("/dashboard/admin/links");
        },
      };
      dispatch(actions.createLink(payload));
    }
  };

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <h3 className="formheadingText"> {Link?.name ? "Edit" : "Add"} Link</h3>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="Name">
            <Form.Label>
              <span className="headingText">name</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.name && "is-invalid"} ${
                errors.name === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.name && "invalid-feedback"} `}>
              {!!errors.name && errors.name}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="link">
            <Form.Label>
              <span className="headingText">link</span>
            </Form.Label>
            <Form.Control
              type="url"
              name="link"
              value={formData.link}
              maxLength={255}
              onChange={handleInputChange}
              // onBlur={localhandleOnblur}
              className={`form-control ${errors.link && "is-invalid"} ${
                errors.link === false && "is-valid"
              }`}
              required
            />
            <small className={`${errors.link && "invalid-feedback"} `}>
              {!!errors.link && errors.link}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="type">
            <Form.Label>
              <span className="headingText">Link type</span>
            </Form.Label>
            <ReactSelect
              value={{ value: formData.type, label: formData.type }}
              onChange={(e) => {
                handleChangeSelect(e, "type");
              }}
              options={[
                { value: "whatsNew", label: "What's New" },
                { value: "relatedLinks", label: "Related Links" },
              ]}
              placeholder="Select a type"
              name="type"
              className={`form-control ${errors.type && "is-invalid"} ${
                errors.type === false && "is-valid"
              }`}
            />
            <small className={`${errors.type && "invalid-feedback"} `}>
              {!!errors.type && errors.type}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="status">
            <Form.Label>
              <span className="headingText">Status</span>
            </Form.Label>
            <ReactSelect
              value={{
                value: formData.status,
                label: formData.status,
              }}
              onChange={(e) => {
                handleChangeSelect(e, "status");
              }}
              options={[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" },
              ]}
              placeholder="Select a status"
              name="status"
              className={`form-control ${errors.status && "is-invalid"} ${
                errors.status === false && "is-valid"
              }`}
            />
            <small className={`${errors.status && "invalid-feedback"} `}>
              {!!errors.status && errors.status}
            </small>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={12} className="mt-5">
          <div className="float-end">
          <Button
              variant="danger"
              style={{ marginLeft: "1em" }}
              onClick={()=> navigate("/dashboard/admin/links")}
            >
              Back
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LinkEdit;

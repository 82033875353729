import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  auctionCount:null,
  closedAuctions: null,
  downloadInProgress: {},
};

const sellerDashboardSlice = createSlice({
  name: "sellerDashboard",
  initialState,
  reducers: {
    fetchAuctionCount(state, action) {
      state.loading = true;
    },
    fetchAuctionCountSuccess(state, action) {
      state.auctionCount = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchAuctionCountFailure(state, action) {
      state.loading = false;
    },
    fetchClosedAuctions(state, action) {
      state.loading = true;
    },
    fetchClosedAuctionsSuccess(state, action) {
      state.closedAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchClosedAuctionsFailure(state, action) {
      state.loading = false;
    },
    downloadDocument(state, action) {
      state.downloadInProgress[action.payload.id] = true;
    },
    downloadDocumentSuccess(state, action) {
      state.downloadInProgress[action.payload.id] = false;
    },
    downloadDocumentFailure(state, action) {
      state.downloadInProgress[action.payload.id] = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = sellerDashboardSlice;

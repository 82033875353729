import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  auctionDetail:{},
  
  
};

const buyerAuctionDetail = createSlice({
  name: "buyerAuctionDetail",
  initialState,
  reducers: {
    fetchAuctionDetail(state, action) {
      state.loading = true;
    },
    homeAuctionDetailSuccess(state, action) {      
      state.auctionDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    homeAuctionDetailFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = buyerAuctionDetail;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  adminAuctionList:{buyers:[],totalRecords:0},
  auctionStatus:{status:"review",name:"In Review"}
};

const adminAuctionsList = createSlice({
  name: "adminAuctionsList",
  initialState,
  reducers: {
    fetchAdminAuctions(state, action) {
      state.loading = true;
    },
    fetchAdminAuctionsSuccess(state, action) {
      state.adminAuctionList = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchAdminAuctionsFailure(state, action) {
      state.loading = false;
    },

    changeActionStatus(state, action) {
      state.auctionStatus = action.payload;
    }
  },
});

export const { actions, reducer, name: sliceKey } = adminAuctionsList;

import axios from "axios";
import { toast } from "react-toastify";

const axiosInstance = axios.create({});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log({'errorerror':error});
    // if (error.response.status === 401) {
    //   if (
    //     !window.location.href.includes("/login?l=t") &&
    //     !window.location.href.includes("/adminLogin")
    //   ) {
    // console.log(window.location, 'location');
    // if (error.response.status === 401) {
    //   if (!window.location.href.includes("/login?l=t")) {
    //     toast.error("Session Expired", {
    //       position: "top-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     });
    //     window.location.replace("/login?l=t");
    //   }
    // }
    if (error.response.config.url.includes("me")) return error;
    if (error.response.data && error.response.status !== 500) {
      console.log('inhererere')
      console.log({'rrr':error.response.data});
      toast.error(JSON.parse(error.response.data).message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (error.message === "Network Error" && !error.response.status) {
      toast.error("Network Error", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (error.response.status === 400) {
      toast.error("Invalid Request", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (error.response.status === 500) {
      toast.error("Internal Server Error", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return error;
  }
);

export default axiosInstance;

import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./style.module.css";
import MyBid from "../../../../components/dashboardApp/myBidTable";
import { formattedDate } from "../../../../utils/helper";

const Overview = ({auction,highestBidAmount,myBids,userHighestBidAmount}) => {
    
    const listData = [];
    const listHeading = [{
        date: 'Date and time',
        mybid: 'Bid price',
    }];

    for (let i = 0; i < 5; i++) {
        listData.push({
            date: "February 22, 2023 13:13 am",
            mybid: "₹40"+i,
        });
    }
    return (
        <>
        <Row className={styles.rowStyle}>
            <Col lg={1} className={styles.w10}>
                <p className={styles.textHeading}>Status</p>
                <p>{auction.status}</p>
            </Col>
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>No of bids</p>
                <p className={styles.valueText}>{myBids.length}</p>
            </Col>
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>Total qty</p>
                <p>{auction.totalQuantity}</p>
            </Col>
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>Ask price/kg</p>
                <p>₹{auction.askedPricePerUnit}</p>
            </Col>
            <Col lg={1} className={styles.w10}>
                <p className={styles.textHeading}>H1 bid/kg</p>
                <p>₹{highestBidAmount}</p>
            </Col>
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>My bid/kg</p>
                <p>₹{userHighestBidAmount}</p>
            </Col>
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>Launch date</p>
                <p>{formattedDate(auction.createdAt)}</p>
            </Col>
        </Row>
        <Row className={styles.rowStyle}>
            <MyBid listData={myBids} listHeading={listHeading} auctionDetail={auction} />
        </Row>
        </>
    );
};

export default Overview;
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Badge, Dropdown, Button } from "react-bootstrap";
import styles from "./links.module.css";
import Heading from "../../../components/shared/Heading";
import SearchIcon from "../../../../assets/dashboardImages/searchBlack.svg";
import CustomTable from "../../../components/dashboardApp/customTable";
import TableFooter from "../../../components/dashboardApp/tableFooter";
import { Link, useNavigate } from "react-router-dom";
import AdminCustomTable from "../../../components/dashboardApp/adminCustomTable";

import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions, initialState } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import UsersCustomTable from "../../../components/dashboardApp/adminCustomTable/userTable";
import { toastSuccess } from "../../../utils/toastHelper";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

const Links = () => {
  const navigate = useNavigate();

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [fromPage, setFromPage] = useState(1);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    // Perform any other actions based on the new items per page value
  };
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const payload = {
      page: currentPage - 1,
      limit: itemsPerPage,
    };
    dispatch(actions.fetchLinks(payload));
  }, [dispatch,  currentPage, itemsPerPage]);

  const dbLinkList = useSelector(selectors.selectLinkList) || initialState;


  const allLinksList = [];
  const linksHeadings = [
    {
      linkName: "Link Name",
      type: "Type",
      status: "Status",
      action: "Action",
    },
  ];

  if (dbLinkList.Links && dbLinkList.Links.length) {
    for (let i = 0; i < dbLinkList.Links.length; i++) {
      allLinksList.push({
        linkId: dbLinkList.Links[i]["_id"],
        linkName: dbLinkList.Links[i]["name"],
        type: dbLinkList.Links[i]["type"],
        status: dbLinkList.Links[i]["status"], 
      });
      
    }
  }

  const totalCount = dbLinkList.totalRecords;
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLinkClick = (linkId) => {
    navigate(`/dashboard/admin/links/edit/${linkId}`);
  };
  const handleLinkDelete = (linkId) => {

    confirmAlert({
      message: `Are you sure you want to delete this ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>{
            const payload = {
              data: {
                id: linkId,
              },
              onSuccess: () => {
                toastSuccess("Link Deleted successfully");
                const payload = {
                  page: currentPage - 1,
                  limit: itemsPerPage,
                };
                dispatch(actions.fetchLinks(payload));
                navigate("/dashboard/admin/links");
              },
            };
            dispatch(actions.removeLink(payload));
          }
        },
        {
          label: 'No'
        }
      ]
    });
   
  };
  return (
    <>
      <section>
        <Container fluid>
          <Row>
            <Col lg={10} className={styles.marginBottom36}>
              <h2>Links</h2>
            </Col>
          

            <Col lg={2} className={`text-right ` }>
            {/* <Link className='nav-link' to="/dashboard/admin/links/add">
            Add
          </Link> */}
              <Button variant="primary" style={{position:"absolute", top:"89px", right:"48px"}} onClick={() => navigate("/dashboard/admin/links/add")}>
                Add
              </Button>
            </Col>
         
          </Row>
          <Row>
            <Col lg={12} className={styles.cardTable}>
              <UsersCustomTable
                heading={linksHeadings}
                filteredData={allLinksList}
                onRowClick={handleLinkClick}
                handleLinkDelete={handleLinkDelete}
                type={"links"}
              />
            </Col>
          </Row>

          {pageCount > 1 ? (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              currentPage={currentPage}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              totalCount={totalCount}
              fromCount={fromPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </section>
    </>
  );
};

export default Links;

import React, {  useState } from "react";
import { Nav } from "react-bootstrap";
import SImage from "../../../shared/Image";
import styles from "./sidebar.module.css";
import IoHammerOutline from "../../../../../assets/dashboardImages/IoHammerOutline.svg";
import FiUser from "../../../../../assets/dashboardImages/FiUser.svg";
import BiUser from "../../../../../assets/dashboardImages/Biuser.svg";
import live from "../../../../../assets/dashboardImages/live.svg";
import HiBell from "../../../../../assets/dashboardImages/HiBell.svg";
import { Link } from 'react-router-dom';

// const navStyle = {
//   active: {
//     backgroundColor: "red"
//   }
// }

const AdminDashboardSidebar = ({ isOpen,toggleSidebar }) => {
  const [showOpen, setIsOpen] = useState(true);


  const handleNavClick = () => {
    const BrowserWidth= window.innerWidth;
    if(BrowserWidth<991){
      toggleSidebar()
    }
    else{
     console.log("not mobile",BrowserWidth) 
    }
    // setIsOpen(!showOpen);
  };
  const sidebarStyles = {
    height: "100vh",
    width: "250px",
    backgroundColor: "#171923",
    position: "fixed",
    top: "0",
    left: isOpen ? "0" : "-250px",
    transition: "all 0.3s ease-out",
    padding: "20px",
    paddingTop: "6em",
    zIndex: "0",
  };

  return (
    <div     style={sidebarStyles} className={`userDashboard ${!showOpen && "ml-6"} `}  >
      <Nav
        defaultActiveKey="/dashboard/admin/auctions"
        className={[styles.sidenav, "flex-column"].join(" ")}
      >
        
        <Link className='nav-link'  onClick={handleNavClick} to="/dashboard/admin/auctions">
          <SImage avatar={IoHammerOutline} pclass={styles.iconImg}/>Auctions
          </Link>

        {/* <Nav.Link href="/dashboard/admin/suppliers">
          <SImage avatar={FiUser} pclass={styles.iconImg} />
          Suppliers
        </Nav.Link> */}

        <Link className='nav-link' onClick={handleNavClick} to="/dashboard/admin/suppliers">
          <SImage avatar={FiUser} pclass={styles.iconImg}/>Suppliers
          </Link>

        {/* <Nav.Link href="/dashboard/admin/buyers">
          <SImage avatar={BiUser} pclass={styles.iconImg} />
          Buyers
        </Nav.Link> */}

        <Link className='nav-link' onClick={handleNavClick} to="/dashboard/admin/buyers">
          <SImage avatar={BiUser} pclass={styles.iconImg}/>Buyers
          </Link>

          <Link className='nav-link' onClick={handleNavClick} to="/dashboard/admin/links">
          <SImage avatar={live} pclass={styles.iconImg}/>Links
          </Link>

          <Link className='nav-link' onClick={handleNavClick} to="/dashboard/admin/mandi">
          <SImage avatar={HiBell} pclass={styles.iconImg}/>Mandi
          </Link>

      </Nav>
    </div>
  );
};

export default AdminDashboardSidebar;

// const getClasses = () => {
//   const navLinks = document.querySelectorAll('.nav-link');
// }

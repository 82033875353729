import React, { useState } from "react";

const SImage =(props) => {
  return (
    <>
      <img src={props.avatar} className={props.pclass? props.pclass : ''} />
    </>
  );
}

export default SImage;

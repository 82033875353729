import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  homeMandiList:{mandiItems:[],totalRecords:0},
};

const homeMandiList = createSlice({
  name: "homeMandiList",
  initialState,
  reducers: {
    fetchMandis(state, action) {
      state.loading = true;
    },
    fetchMandisSuccess(state, action) {
      state.homeMandiList = action.payload; 
      state.loading = false;
    },
    fetchMandisFailure(state, action) {
      state.loading = false;
    },
  },
 
});

export const { actions, reducer, name: sliceKey } = homeMandiList;

import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.profileDetails || initialState;

export const accountDetails = createSelector([selectDomain], (state) => {
  const profileDetails = state.profileDetails && state.profileDetails.document;

  if (profileDetails) {
    const { role, category, phone, email } = profileDetails;
    return {
      role,
      category,
      phone,
      email,
    };
  }
  return {
    role: "",
    category: "",
    phone: "",
    email: "",
  };
});

export const personalDetails = createSelector([selectDomain], (state) => {
  const profileDetails = state.profileDetails && state.profileDetails.document;

  if (profileDetails) {
    const { authorisedEmail, authorisedPhone, companyName, userName } =
      profileDetails;
    return {
      authorisedEmail,
      authorisedPhone,
      companyName,
      userName,
    };
  }
  return {
    authorisedEmail: "",
    authorisedPhone: "",
    companyName: "",
    userName: "",
  };
});

export const legalDetails = createSelector([selectDomain], (state) => {
  const profileDetails = state.profileDetails && state.profileDetails.document;

  if (profileDetails) {
    const { aadharNumber, gst, pan, aadharUrl, gstUrl, panUrl } =
      profileDetails;
    return {
      aadharNumber,
      gst,
      pan,
      aadharUrl,
      gstUrl,
      panUrl,
    };
  }
  return {
    aadharNumber: "",
    gst: "",
    pan: "",
    aadharUrl: "",
    gstUrl: "",
    panUrl: "",
  };
});

export const addressDetails = createSelector([selectDomain], (state) => {
  const profileDetails = state.profileDetails && state.profileDetails.document;

  if (profileDetails) {
    const { address, country, state, district, pincode, addressProofUrl } =
      profileDetails;
    return {
      address,
      country,
      state,
      district,
      pincode,
      addressProofUrl,
    };
  }
  return {
    address: "",
    country: "",
    state: "",
    district: "",
    pincode: "",
    addressProofUrl: "",
  };
});

export const bankDetails = createSelector([selectDomain], (state) => {
  const profileDetails = state.profileDetails && state.profileDetails.document;

  if (profileDetails) {
    const {
      accountNumber,
      bankName,
      branchName,
      cancelledChequeUrl,
      ifsc,
      accountHolderName,
    } = profileDetails;
    return {
      accountNumber,
      bankName,
      branchName,
      cancelledChequeUrl,
      ifsc,
      accountHolderName,
    };
  }
  return {
    accountNumber: "",
    bankName: "",
    branchName: "",
    cancelledChequeUrl: "",
    ifsc: "",
    accountHolderName: "",
  };
});

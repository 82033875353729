import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  supplierList:{buyers:[],totalRecords:0},
  supplierFilterStatus:"approved"

};

const suppliersList = createSlice({
  name: "suppliersList",
  initialState,
  reducers: {
    fetchSuppliers(state, action) {
      state.loading = true;
    },
    fetchSuppliersSuccess(state, action) {
      state.supplierList = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchSuppliersFailure(state, action) {
      state.loading = false;
    },
    changeSupplierFilterStatus(state, action) {
      state.supplierFilterStatus = action.payload;
    }
  },
});

export const { actions, reducer, name: sliceKey } = suppliersList;

import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchUserDetail({ payload }) {
  try {
    const res = yield call(API.fetchUserDetailData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchUserByIdSuccess(data));
  } catch (err) {
    yield put(actions.fetchUserByIdFailure(err?.response?.data));
  }
}

export function* handleUpdateUserStatus({ payload }) {
  try {
    const res = yield call(API.updateUserStatusData, payload);    
    const parsedObject = JSON.parse(res.data);      
    const { data } = parsedObject;
    yield put(actions.updateUserStatusSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.updateUserStatusFailure(err?.response?.data));
  }
}

export function* handleBlockSeller({ payload }) {
  try {
    const res = yield call(API.blockSeller, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.blockSellerSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.blockSellerFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* userDetailSaga() {
  yield takeLatest(actions.fetchUserById.type, handleFetchUserDetail);
  yield takeLatest(actions.updateUserStatus.type, handleUpdateUserStatus);
  yield takeLatest(actions.blockSeller.type, handleBlockSeller);
}

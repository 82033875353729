import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.homeAuctionDetail || initialState;

export const homeauctiondetail = createSelector([selectDomain], (state) => state.auctionDetail);




import React from "react";
import Styles from "./styles.module.css";

export default function AboutMedicinalSecond() {
  return (
    <div className={Styles.aboutMedicinalSecond}>
      <p>
        A comprehensive database on important medicinal plants has been
        developed by the Central Council for Research in Ayurvedic Sciences
        (CCRAS).
      </p>
      <p>
        The database provides access to nearly 22,000 reList of Medicinal Plants
        ferences (reprints / abstracts) pertaining to the 16 selected medicinal
        plants. These include the classical literature from Ayurveda, Unani,
        Siddha and Homeopathy system of medicine as well as modern literature
        from various books and journals covering basic and applied science and
        medicine. These were segregated into 6 categories viz. Botany,
        Chemistry, Pharmacology, Pharmacy, Miscellaneous and Reprints in other
        languages.
      </p>
      <p>
        Information regarding authors, title of the article, book / journal
        name, publisher, volume, page no., year etc. for all collected
        references is fed into this database with user-friendly search option.
        This database will act as a common platform at which all published
        information on a particular medicinal plant can be accessed.
      </p>
      <p>
        <b>Guidelines for users</b>
      </p>
      <p>
        Before accessing the database, every user has to fill the registration
        proforma and obtain user id and password. The user has to sign in
        everytime he / she wants to access the database. The database can be
        used for the purpose of research and development of medicinal plants.
        Any commercial use of its content is strictly prohibited.
      </p>
    </div>
  );
}

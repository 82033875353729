import actions from "./actions";

const roles = {
  SUPER_ADMIN: {
    name: "super_admin",
    permissions: [actions.ACCESS_ADMIN_DASHBOARD_PAGE],
  },
  SELLER: {
    name: "seller",
    permissions: [actions.ACCESS_SELLER_DASHBOARD_PAGE],
  },
  BUYER: {
    name: "buyer",
    permissions: [actions.ACCESS_BUYER_DASHBOARD_PAGE],
  },
};

export default roles;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  recentAuctions:{auctions:[],totalRecords:0},
  linkList:[],  
};

const homeRecentAuctions = createSlice({
  name: "homeRecentAuctions",
  initialState,
  reducers: {
    fetchLiveAuctions(state, action) {
      state.loading = true;
    },
    fetchLiveAuctionsSuccess(state, action) {
      state.recentAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchLiveAuctionsFailure(state, action) {
      state.loading = false;
    },
    fetchLinks(state, action) {
      state.loading = true;
      state.linkList = []; 

    },
    fetchLinksSuccess(state, action) {
      state.linkList = action.payload; 
      state.loading = false;
    },
    fetchLinksFailure(state, action) {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = homeRecentAuctions;

import React from "react";
import styles from "./styles.module.css";

export default function Button({
  solid,
  outline,
  text,
  buttonStyle,
  buttonClick,
  type,
  disable,
}) {
  const classes = [styles.button];

  if (solid && disable) {
    classes.push(styles.solid);
    return (
      <div className={styles.disable}>
        <button
          type={type ? type : ""}
          onClick={buttonClick}
          style={buttonStyle}
          className={classes.join(" ")}
        >
          {text}
        </button>
      </div>
    );
  }
  if (outline && disable) {
    classes.push(styles.outline);
    return (
      <div className={styles.disable}>
        <button
          type={type ? type : ""}
          onClick={buttonClick}
          style={buttonStyle}
          className={classes.join(" ")}
        >
          {text}
        </button>
      </div>
    );
  }

  if (solid) {
    classes.push(styles.solid);
    return (
      <button
        type={type ? type : ""}
        onClick={buttonClick}
        style={buttonStyle}
        className={classes.join(" ")}
      >
        {text}
      </button>
    );
  }
  if (outline) {
    classes.push(styles.outline);
    return (
      <button
        type={type ? type : ""}
        onClick={buttonClick}
        style={buttonStyle}
        className={classes.join(" ")}
      >
        {text}
      </button>
    );
  }
}

import React from 'react';
import { Col, Pagination } from 'react-bootstrap';

const CustomPagination = ({ currentPage, pageCount, handlePageChange }) => {
    const renderPaginationButtons = () => {
        const buttons = [];
        const maxButtonsToShow = 5;
        const rangeStart = Math.max(currentPage - Math.floor(maxButtonsToShow / 2), 1);
        const rangeEnd = Math.min(rangeStart + maxButtonsToShow - 1, pageCount);
    
        buttons.push(
        <Pagination.Prev
            key="prev"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
        >Prev</Pagination.Prev>
        );
    
        if (rangeStart > 1) {
        buttons.push(
            <Pagination.Ellipsis key="ellipsis-start">...</Pagination.Ellipsis>
        );
        }
    
        for (let i = rangeStart; i <= rangeEnd; i++) {
        buttons.push(
            <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
            >
            {i}
            </Pagination.Item>
        );
        }
    
        if (rangeEnd < pageCount) {
        buttons.push(
            <Pagination.Ellipsis key="ellipsis-end">...</Pagination.Ellipsis>
        );
        }
    
        buttons.push(
        <Pagination.Next
            key="next"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pageCount}
        >Next</Pagination.Next>
        );
    
        return buttons;
    };
    return <Col style={{paddingRight: "0px"}} lg={4} id="customPagination"><Pagination style={{ float: 'right'}} >{renderPaginationButtons()}</Pagination></Col>;
};

export default CustomPagination;

import React, { useEffect } from "react";
import General from "../../../../components/dashboardApp/GeneralDetail";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions } from "./slice";
import { auctionSellerInfoamtionSaga } from "./saga";
import * as selectors from "./selectors";

const Supplier = ({auction}) => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: auctionSellerInfoamtionSaga });
  const dispatch = useDispatch();

    useEffect(()=>{
        
        if(auction && auction.createdBy){
            dispatch(actions.fetchSellerInformation({id:auction.createdBy}));
        }
        
        
    },[]);

    const userDetail = useSelector(selectors.selectauctionSeller) || {};
    
    
    return (
        <>
        
           {userDetail &&  <General userData ={userDetail}/> }
        </>
    );
}

export default Supplier;
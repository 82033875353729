import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import styles from './styles.module.css';
import FileIcon from "../../../../../assets/icons/file.svg";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";

export default function LegalDetails(props) {
  const [aadharNumber, setAadharNumber] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [aadharToUpload, setAadharToUplopad] = useState(null);
  const [gstToUpload, setGstToUplopad] = useState(null);
  const [panToUpload, setPanToUplopad] = useState(null);
  const [aadharNumberError, setAadharNumberError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [panNumberError, setPanNumberError] = useState("");

  const navigate = useNavigate();

  const handleInputClick = (id) => {
    document.getElementById(id).click();
  };

  const handleAadharChange = (event) => {
    setAadharNumber(event.target.value);
    setAadharNumberError("");
  };

  const handleGstChange = (event) => {
    setGstNumber(event.target.value);
    setGstNumberError("");
  };

  const handlePanChange = (event) => {
    setPanNumber(event.target.value);
    setPanNumberError("");
  };
  // validation added
  const validateAadhar = (number) => {
    const aadharRegex = /^\d{12}$/;
    return aadharRegex.test(number);
  };

  const validatePAN = (number) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(number.toUpperCase());
  };

  const validateGST = (number) => {
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return gstRegex.test(number.toUpperCase());
  };

 // validation added

  const handleSubmit = () => {
    if (aadharNumber !== "" || gstNumber !== "" || panNumber !== "" || aadharToUpload || gstToUpload || panToUpload) {
      props.nextButtonClicked(aadharNumber, gstNumber, panNumber, aadharToUpload, gstToUpload, panToUpload);
    }
  };

  const CustomButton = withStyles({
    root: {
      width: "190px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "#FFFFFF !important",
      border: "1px solid #E2E8F0 !important",
      boxShadow: "none !important",
    },
  })(Button);

  const CustomVerifyButton = withStyles({
    root: {
      width: "190px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "green !important",
    },
  })(Button);

  return (
    <>
      <div id={styles.legalHeading}>Legal documents</div>
      <div id={styles.legalSubheading}>Enter legal documents</div>

      <div id={styles.formSection}>
        <div id={styles.inputLabel}>Aadhar number</div>
        <div id={styles.inputWrapper}>
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            placeholder="Ex. 6245 XXXX XXXX"
            inputProps={{
              id: styles.inputField,
              maxLength: 12,
            }}
            value={aadharNumber}
            onChange={handleAadharChange}
            onBlur={() => {
              if (aadharNumber && !validateAadhar(aadharNumber)) {
                setAadharNumberError("Invalid Aadhar number. It should be a 12-digit number.");
              }
            }}
          
          />
          {aadharNumberError && <FormHelperText error>{aadharNumberError}</FormHelperText>}
        </div>
      </div>

      <div id={styles.uploadSection}>
        <div id={styles.uploadLabel}>Upload Your Aadhar (if any)</div>
      </div>
      <Box id={styles.uploadBox}>
        <input
          type="file"
          name=""
          id="input_file_aadhar"
          style={{ display: "none" }}
          onChange={(event) => setAadharToUplopad(event.target.files)}
          accept={".pdf"}
        />
        <div id={styles.uploadBoxContent}>
          <div>Drag and drop files or</div>
          <div id={styles.browseLink} onClick={() => handleInputClick("input_file_aadhar")}>
            Browse
          </div>
        </div>
        <div id={styles.supportedFormats}>Supported formats: JPEG, JPG, PNG, PDF</div>
      </Box>

      {aadharToUpload && (
        <div id={styles.filePreview}>
          <Box id={styles.fileContainer}>
            <div id={styles.fileContent}>
              <div id={styles.fileDetails}>
                <img src={FileIcon} alt="file" />
                <div>{aadharToUpload[0].name}</div>
              </div>
              <img
                src={deleteIcon}
                alt="deleteIcon"
                id={styles.deleteIcon}
                onClick={() => setAadharToUplopad(null)}
              />
            </div>
          </Box>
        </div>
      )}

      <div id={styles.formSection}>
        <div id={styles.inputLabel}>GST number</div>
        <div id={styles.inputWrapper}>
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            placeholder="Ex. ABCDXXXXXXXXXXX"
            inputProps={{
              id: styles.inputField,
              maxLength: 15,
            }}
            value={gstNumber}
            onChange={handleGstChange}
            onBlur={() => {
              if (gstNumber && !validateGST(gstNumber)) {
                setGstNumberError("Invalid GST number. Please enter a valid GST number.");
              }
            }}
          
          />
          {gstNumberError && <FormHelperText error>{gstNumberError}</FormHelperText>}
        </div>
      </div>

      <div id={styles.uploadSection}>
        <div id={styles.uploadLabel}>Upload GST certificate (if any)</div>
      </div>
      <Box id={styles.uploadBox}>
        <input
          type="file"
          name=""
          id="input_file_gst"
          style={{ display: "none" }}
          onChange={(event) => setGstToUplopad(event.target.files)}
          accept={".pdf"}
        />
        <div id={styles.uploadBoxContent}>
          <div>Drag and drop files or</div>
          <div id={styles.browseLink} onClick={() => handleInputClick("input_file_gst")}>
            Browse
          </div>
        </div>
        <div id={styles.supportedFormats}>Supported formats: JPEG, JPG, PNG, PDF</div>
      </Box>

      {gstToUpload && (
        <div id={styles.filePreview}>
          <Box id={styles.fileContainer}>
            <div id={styles.fileContent}>
              <div id={styles.fileDetails}>
                <img src={FileIcon} alt="file" />
                <div>{gstToUpload[0].name}</div>
              </div>
              <img
                src={deleteIcon}
                alt="deleteIcon"
                id={styles.deleteIcon}
                onClick={() => setGstToUplopad(null)}
              />
            </div>
          </Box>
        </div>
      )}

      <div id={styles.formSection}>
        <div id={styles.inputLabel}>PAN number</div>
        <div id={styles.inputWrapper}>
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            placeholder="Ex. ABCDXXXXXX"
            inputProps={{
              id: styles.inputField,
              maxLength: 10,
            }}
            value={panNumber}
            onChange={handlePanChange}
            onBlur={() => {
              if (panNumber && !validatePAN(panNumber)) {
                setPanNumberError("Invalid PAN number. It should follow the format ABCDE1234F.");
              }
            }}
          />
          {panNumberError && <FormHelperText error>{panNumberError}</FormHelperText>}
        </div>
      </div>

      <div id={styles.uploadSection}>
        <div id={styles.uploadLabel}>Upload PAN (if any)</div>
      </div>
      <Box id={styles.uploadBox}>
        <input
          type="file"
          name=""
          id="input_file_pan"
          style={{ display: "none" }}
          onChange={(event) => setPanToUplopad(event.target.files)}
          accept={".pdf"}
        />
        <div id={styles.uploadBoxContent}>
          <div>Drag and drop files or</div>
          <div id={styles.browseLink} onClick={() => handleInputClick("input_file_pan")}>
            Browse
          </div>
        </div>
        <div id={styles.supportedFormats}>Supported formats: JPEG, JPG, PNG, PDF</div>
      </Box>

      {panToUpload && (
        <div id={styles.filePreview}>
          <Box id={styles.fileContainer}>
            <div id={styles.fileContent}>
              <div id={styles.fileDetails}>
                <img src={FileIcon} alt="file" />
                <div>{panToUpload[0].name}</div>
              </div>
              <img
                src={deleteIcon}
                alt="deleteIcon"
                id={styles.deleteIcon}
                onClick={() => setPanToUplopad(null)}
              />
            </div>
          </Box>
        </div>
      )}

      {/* <div id={styles.actionButtons}>
        <CustomButton onClick={props.backButtonClicked}>Back</CustomButton>
        <CustomVerifyButton onClick={props.skipButtonClicked}>Skip</CustomVerifyButton>
        <CustomVerifyButton onClick={handleSubmit}>Next</CustomVerifyButton>
      </div> */}
      <div style={{ display: "flex", gap: 40, marginTop: "3rem" }} id="flexdiv">
        <div>
          <CustomButton onClick={props.backButtonClicked} variant="contained">
            <div
              style={{
                color: "#1A202C",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Back
            </div>
          </CustomButton>
        </div>
        <div>
          <CustomVerifyButton onClick={props.skipButtonClicked}>
            <div
              style={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Skip
            </div>
          </CustomVerifyButton>
        </div>
        <div>
          <CustomVerifyButton onClick={handleSubmit}>
            <div
              style={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Next
            </div>
          </CustomVerifyButton>
        </div>
      </div>
    </>
  );
}

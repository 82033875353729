import React from "react";
import { useSelector } from "react-redux";
import * as selectors from "../selectors";
import { Col, Row, Form } from "react-bootstrap";

// const profileData = {
//   company: "ABC company",
//   pocEmail: "pocemail@test.com",
//   pocName: "Ganesh Gyani",
//   pocMobile: "7766555789",
// };

const Personaldetail = () => {
  const { authorisedEmail, authorisedPhone, companyName, userName } =
    useSelector(selectors.personalDetails);
  return (
    <>
      <Col lg={12}>
        <h5>Contact person's details</h5>
      </Col>
      <Col lg={3} className="mt-2">
        <Form.Group controlId="select_type">
          <Form.Label>
            <span className="headingText">Company</span>
          </Form.Label>
          {/* <Form.Control
            type="text"
            name="select_type"
            defaultValue={companyName}
          /> */}
          <h6 style={{ textTransform: "capitalize" }}>{companyName}</h6>
        </Form.Group>
      </Col>
      <Col lg={3} className="mt-2">
        <Form.Group controlId="email">
          <Form.Label>
            <span className="headingText">Email</span>
          </Form.Label>
          {/* <Form.Control
            type="email"
            name="person_email"
            defaultValue={authorisedEmail}
          /> */}
          <h6>{authorisedEmail}</h6>
        </Form.Group>
      </Col>
      <Col lg={3} className="mt-2">
        <Form.Group controlId="person_name">
          <Form.Label>
            <span className="headingText">Name</span>
          </Form.Label>
          {/* <Form.Control
            type="text"
            name="person_name"
            defaultValue={userName}
          /> */}
          <h6 style={{ textTransform: "capitalize" }}>{userName}</h6>
        </Form.Group>
      </Col>
      <Col lg={3} className="mt-2">
        <Form.Group controlId="sdo">
          <Form.Label>
            <span className="headingText">Phone number</span>
          </Form.Label>
          {/* <Form.Control
            type="text"
            name="person_contact"
            defaultValue={authorisedPhone}
          /> */}
          <h6>{authorisedPhone}</h6>
        </Form.Group>
      </Col>
    </>
  );
};

export default Personaldetail;

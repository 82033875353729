import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  message: null,
};

const pendingPaymentSlice = createSlice({
  name: "pendingPayment",
  initialState,
  reducers: {
    submitPaymentProof(state, action) {
      state.loading = true;
    },
    submitPaymentProofSuccess(state, action) {
      state.loading = false;
    },
    submitPaymentProofFailure(state, action) {
      state.loading = false;
      state.error = action.payload?.err?.message;
    },
  },
});

export const { actions, reducer, name: sliceKey } = pendingPaymentSlice;

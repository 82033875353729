import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  wonAuctions:{auctions:[],totalRecords:0},
};

const wonAuctions = createSlice({
  name: "buyerWonAuctions",
  initialState,
  reducers: {
    fetchWonAuctions(state, action) {
      state.loading = true;
    },
    fetchWonAuctionsSuccess(state, action) {
      state.wonAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchWonAuctionsFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = wonAuctions;

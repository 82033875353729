import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./style.module.css";
import image from "../../../../assets/dashboardImages/addressproof.svg";
import credit from "../../../../assets/dashboardImages/creditc.svg";

const General = ({ userData }) => {
  return (
    <>
      <Row>
        <Col lg={6}>
          <span className={styles.heading}>User Details</span>
        </Col>
        <Col lg={6}>
          <span className={styles.heading}>Address</span>
        </Col>
      </Row>
      <Row>
        <Col
          lg={6}
          style={{
            marginTop: "1em",
            marginLeft: "0.5em",
            marginRight: "0.5em",
          }}
          className={[styles.colStyle]}
        >
          <Row className={styles.rowStyle}>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>ID</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData._id}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>Firm name</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.companyName}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>
                Authorized person's name
              </span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.userName}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>
                Authorized person's email
              </span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>
                {userData.authorisedEmail}
              </span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>
                Authorized person's ph no.
              </span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>
                {userData.authorisedPhone}
              </span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>GST Number</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.gst}</span>
            </Col>
            <Col lg={6} className={[styles.paddingCol].join(" ")}>
              <span className={styles.pheadingtext}>PAN Card Number</span>
            </Col>
            <Col lg={6} className={[styles.paddingCol].join(" ")}>
              <span className={styles.valueText}>{userData.pan}</span>
            </Col>
          </Row>
        </Col>
        <Col
          lg={6}
          style={{
            marginTop: "1em",
            marginLeft: "0.5em",
            marginRight: "0.5em",
          }}
          className={styles.colStyle}
        >
          <Row className={styles.rowStyle}>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>Address</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.address}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>Country</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.country}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>State</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.state}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>District</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.district}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>PIN Code</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.pincode}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>GST Number</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.gst}</span>
            </Col>
            <Col lg={6} className={[styles.paddingCol].join(" ")}>
              <span className={styles.pheadingtext}>Address proof</span>
            </Col>
            <Col lg={6} className={[styles.paddingCol].join(" ")}>
              <span className={styles.valueText}>
                {userData.addressProofUrl ? (
                  <a
                    href={userData.addressProofUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#38A169", textDecoration: "none" }}
                  >
                    download
                  </a>
                ) : (
                  "Not available"
                )}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <Col lg={6}>
          <span className={styles.heading}>Bank details</span>
        </Col>
      </Row>
      <Row>
        <Col
          lg={6}
          style={{
            marginTop: "1em",
            marginLeft: "0.5em",
            marginRight: "0.5em",
          }}
          className={[styles.colStyle]}
        >
          <Row className={styles.rowStyle}>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>Bank Name</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.bankName}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>Branch Name</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.branchName}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>Account Number</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>
                {userData.accountNumber
                  ? `********${userData.accountNumber.substring(3)}`
                  : "------"}
              </span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>Account holder name</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.userName}</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.pheadingtext}>IFSC Code</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>{userData.ifsc}</span>
            </Col>
            <Col lg={6} className={[styles.paddingCol].join(" ")}>
              <span className={styles.pheadingtext}>Canceled cheque</span>
            </Col>
            <Col lg={6} className={[styles.paddingCol].join(" ")}>
              <span className={styles.valueText}>
                {userData.cancelledChequeUrl ? (
                  <a
                    href={userData.cancelledChequeUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#38A169", textDecoration: "none" }}
                  >
                    download
                  </a>
                ) : (
                  "Not available"
                )}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default General;

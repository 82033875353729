import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchAdminAuctions({ payload }) {
  try {
    const res = yield call(API.fetchAdminAuctionListData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchAdminAuctionsSuccess(data));
  } catch (err) {
    yield put(actions.fetchAdminAuctionsFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* adminAuctionListingSaga() {
  yield takeLatest(actions.fetchAdminAuctions.type, handleFetchAdminAuctions);
  
}

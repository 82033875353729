import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  bidData:{},
  
  
};

const postBid = createSlice({
  name: "postBid",
  initialState,
  reducers: {
    postBid(state, action) {
      state.loading = true;
    },
    postBidSuccess(state, action) {   
      state.bidData = action.payload; //action.payload.data.document;
      state.loading = false;
      
    },
    postBidFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = postBid;

import React from "react";
import styles from "./styles.module.css";
import { formatDateForMyBids } from "../../../utils/helper";

// const myBids = [
//   { date: "February 22, 2023 13:13 am", bidPrice: 3000 },
//   { date: "February 22, 2023 13:13 am", bidPrice: 3000 },
//   { date: "February 22, 2023 13:13 am", bidPrice: 3000 },
//   { date: "February 22, 2023 13:13 am", bidPrice: 3000 },
//   { date: "February 22, 2023 13:13 am", bidPrice: 3000 },
//   { date: "February 22, 2023 13:13 am", bidPrice: 3000 },
//   { date: "February 22, 2023 13:13 am", bidPrice: 3000 },
// ];

export default function MyBids({ bids }) {
  return (
    <>
      {bids.length ? (
        <div className={styles.myBids}>
          <div className={styles.heading}>
            <span>Date and time</span>
            <span>Bid price</span>
          </div>
          {bids.map((bid) => (
            <div key={bid._id} className={styles.data}>
              <span>{bid.createdAt && formatDateForMyBids(bid.createdAt)}</span>
              <span>₹{bid.amount}</span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement,Legend} from 'chart.js';
import styles from "./style.module.css";
Chart.register(ArcElement,Legend);

const DoughnutChart = (props) => {
  const {won,lost,lbl} = props
  const closedAuction = parseInt(won + lost);
  
  const data = {
    labels:lbl, //[ 'Awarded', 'Pending'],
    datasets: [
      {
        data: [won, lost],
        backgroundColor: ['#38A169','#CBD5E0'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
        title: {
          display: true,
        },
        legend: {
          padding: '0px 0px 0px 10px',
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            pointStyle:'circle',
            usePointStyle: true,
            textAlign: 'left',
            boxWidth: 5,
            boxHeight: 5
          },
        },
      },
      responsive: true,
    cutout: '70%',
    height: 300,
  };

  return (
    <div className={styles.doughnutChartContainer}>
      <div className={styles.doughnutChartContent} style={{position: 'relative', height:'40vh', width:'20vw'}}>
        <Doughnut data={data} options={options} />
        {/* <div className={styles.centerText}><div className={styles.hText}>{closedAuction}</div> <div className={styles.subtext}>Closed auctions</div></div> */}
        <div className={styles.centerText}><div className={styles.hText}>{closedAuction}</div></div>
      </div>
    </div>
  );
};

export default DoughnutChart;

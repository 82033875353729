import React, { useState } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./customTable.module.css";
import PlaceBid from "../../../components/dashboardApp/PlaceBidInputButton";
import { Link } from "react-router-dom";

const SellerInReviewCustomTable = ({
  liveAuctionHeading,
  filteredData,
  bids,
  auctionDetailLink,
}) => {
  return (
    <>
      <Table responsive>
        <thead style={{ backgroundColor: "#F7FAFC" }}>
          {liveAuctionHeading.map((auction, index) => (
            <tr key={index}>
              <td className={styles.headT}>{auction.id}</td>
              <td className={styles.headT}>{auction.auction}</td>
              <td className={styles.headT}>{auction.createdDate}</td>

              <td className={styles.headT}>{auction.quantity}</td>
              <td className={styles.headT}>{auction.asked}</td>
            </tr>
          ))}
        </thead>
        <tbody>
          {filteredData.map((auction, index) => (
            <tr key={index}>
              <td className={styles.dataT}>{auction.auctionId}</td>
              <td className={styles.dataT}>
                <Link
                  to={auctionDetailLink + "?id=" + auction.id}
                  className={styles.greenT}
                >
                  {auction.auction}
                </Link>
              </td>
              {/* <td className={styles.dataT}>{auction.auction}</td> */}
              <td className={styles.dataT}>{auction.createdDate}</td>
              <td className={styles.dataT}>
                <span>{auction.quantity}</span>
              </td>
              <td className={styles.dataT}>
                <span>{auction.asked}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default SellerInReviewCustomTable;

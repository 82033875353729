import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state) => state?.auth || initialState;

export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);

export const selectMessage = createSelector(
  [selectDomain],
  (state) => state.message
);

export const selectLoginInProgress = createSelector(
  [selectDomain],
  (state) => state.loginInProgress
);

export const selectGenerateOtpInProgress = createSelector(
  [selectDomain],
  (state) => state.generateOtpInProgress
);

export const selectOTPLoginInProgress = createSelector(
  [selectDomain],
  (state) => state.generateLoginOtpInProgress
);

export const selectLoggedInUser = createSelector(
  [selectDomain],
  (state) => state.loggedInUser
);

export const selectFetchingLoggedInUser = createSelector(
  [selectDomain],
  (state) => state.fetchingLoggedInUser
);

export const selectLogoutProgress = createSelector(
  [selectDomain],
  (state) => state.logoutInProgress
);

export const selectLogoutSuccess = createSelector(
  [selectDomain],
  (state) => state.logoutSuccess
);

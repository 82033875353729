import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  inReviewAuctions:{auctions:[],totalRecords:0},
  
  
};

const sellerInReviewAuctions = createSlice({
  name: "sellerInReviewAuctions",
  initialState,
  reducers: {
    fetchInReviewAuctions(state, action) {
      state.loading = true;
    },
    fetchInReviewAuctionsSuccess(state, action) {
      state.inReviewAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchInReviewAuctionsFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = sellerInReviewAuctions;

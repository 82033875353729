import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.userDetailForAdmin || initialState;

export const userDetail = createSelector([selectDomain], (state) => state.userDetail);




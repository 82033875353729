import styles from "./styles.module.css";
import { formattedDate,myHighestBid } from "../../../utils/helper"; //"../../../utils/helper";
import { useAuth } from "../../../contexts/auth"; //"../../../contexts/auth";
export default function HederaAuctionOverview({auction,hederaAuction,hederaAuctionBids,displayMode,misMatchData }) {
    const auth = useAuth();    
    let myBid=null;
    let myHBid=null;
    let dataArray=[];
    
    if(hederaAuctionBids){
      myHBid = myHighestBid(hederaAuctionBids, auth.loggedInUser._id); 
    }

    if(auction.bids){
      myBid = myHighestBid(auction.bids, auth.loggedInUser._id); 
    }

    let headKeysValues = [
      {key:'bidcount',value:'No. of bids'},
      {key:'totalQuantity',value:'Total quantity'},
      {key:'askedPricePerUnit',value:'Asked price/Kg'},
      {key:'topbid',value:'My H1 bid/Kg'},
      {key:'createdAt',value:'Create date'}
    ]
    
    const matchDBData = (dbAuctin,hBid)=>{
      
      headKeysValues.forEach(element => {
              if(element.key === 'bidcount'){
                dataArray.push(
                  {
                    key : element.key,
                    headKey:element.value,
                    dbValue: dbAuctin.bids.length,
                    display:true
                  }
                );
              }
              else if(element.key === 'topbid'){
                dataArray.push(
                  {
                    key : element.key,
                    headKey:element.value,
                    dbValue: (hBid ? hBid.amount :''),
                    display:true
                  }
                );
              }
              else{
                dataArray.push(
                  {
                    key : element.key,
                    headKey:element.value,
                    dbValue: dbAuctin[element.key],   
                    display:true                                    
                  }
                );

              }
      });
    }

    const matchHederaData = (hederaAuction,hederaAuctionBids,hederaHBid) => {
      
        dataArray.forEach(element => {
          if(element.key === 'bidcount'){
            element.hederaValue = hederaAuctionBids.length;
          }
          else if(element.key === 'topbid'){
            element.hederaValue = (hederaHBid ? hederaHBid.amount :'');
          }
          else{
            element.hederaValue= hederaAuction[element.key];
          }
          element.display = (displayMode === 'all') ? true : (displayMode === 'matched') ? (element.dbValue === element.hederaValue) : (element.dbValue !== element.hederaValue);     
          //Set Global misMatch flag
          if(hederaAuctionBids.length && hederaAuction && Object.keys(hederaAuction).length !== 0 && element.dbValue !== element.hederaValue){
             misMatchData(true);
          }
          
          
        });
    }

    if(auction){
      matchDBData(auction,myBid); 
       
    }

    if(hederaAuction,hederaAuctionBids){
      matchHederaData(hederaAuction,hederaAuctionBids,myHBid); 
      
    }

    return (<>
        <main className={styles.mainContent}>        
                <section>
                  {auction.labReport && (
                    <span className={styles.title}>
                      1. Overview              
                    </span>
                  )}
                </section>
                <section className={[styles.data, "param_overview"].join(' ')}>
                  <div className={styles.dataLeft}>
                    <span className={styles.key}>Parameters</span>                    
                    {dataArray.map((q) => {
                      if(q.display){
                        return <span key={q.headKey}>{q.headKey}</span>;
                      }                      
                    })}
                  </div>
                  <div className={styles.dataRight}>
                    <span>Data</span>
                    {dataArray.map((q,index)=>{
                      if(q.display){
                       return <span key={index}>
                        {(q.key === 'askedPricePerUnit' || q.key === 'topbid') ? '₹' : ''}
                        {
                        q.headKey === 'Create date' ? formattedDate(q.dbValue) : q.dbValue
                        }
                        { 
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.dbValue !== q.hederaValue)) ? ' | ':''
                        }  
                        
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.dbValue !== q.hederaValue)) ?  (<strong className={styles.redfont}> {(q.key === 'askedPricePerUnit' || q.key === 'topbid') ? '₹' : ''}  {q.hederaValue} </strong>):''
                        }                        
                        </span>;
                      }                      
                    })}
                    
                  </div>
                </section>
              </main>
        </>);


}



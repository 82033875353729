import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchSuppliers({ payload }) {
  try {
    const res = yield call(API.fetchSuppliersListData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchSuppliersSuccess(data));
  } catch (err) {
    yield put(actions.fetchSuppliersFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* suppliersListingSaga() {
  yield takeLatest(actions.fetchSuppliers.type, handleFetchSuppliers);
  
}

import React from "react";
import { useSelector } from "react-redux";
import * as selectors from "../selectors";
import { Col, Form } from "react-bootstrap";


// const profileData = {
//   accountCategory: "trader",
//   email: "example@gmail.com",
//   mobile: "9870754630",
// };

const AccountDetail = () => {
  const { role, category, phone, email } = useSelector(
    selectors.accountDetails
  );

  return (
    <>
      <Col lg={12}>
        <h5>Account details</h5>
      </Col>
      <Col lg={3} className="mt-2">
        <Form.Group controlId="dos">
          <Form.Label>
            <span className="headingText">Account type</span>
          </Form.Label>
          <h6 style={{textTransform: "capitalize"}}>{role}</h6>
        </Form.Group>
      </Col>
      <Col lg={3} className="mt-2">
        <Form.Group controlId="doh">
          <Form.Label>
            <span className="headingText">Account category</span>
          </Form.Label>
          <h6>{category}</h6>
        </Form.Group>
      </Col>
      <Col lg={3} className="mt-2">
        <Form.Group controlId="dop">
          <Form.Label>
            <span className="headingText">Email</span>
          </Form.Label>
          <h6>{email}</h6>
        </Form.Group>
      </Col>
      <Col lg={3} className="mt-2">
        <Form.Group controlId="sdo">
          <Form.Label>
            <span className="headingText">Mobile number</span>
          </Form.Label>
          <h6>{phone}</h6>
        </Form.Group>
      </Col>
    </>
  );
};

export default AccountDetail;

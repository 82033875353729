import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./style.module.css";
import MyBid from "../../../../components/dashboardApp/myBidTable";
// import { formattedDateTime } from "../../../../utils/helper";



import image1 from "../../../../../assets/homeImages/auctionView1.svg";
import previousIcon from "../../../../../assets/icons/backward.svg";
import nextIcon from "../../../../../assets/icons/forward.svg";
import { useState } from "react";
import { formattedDateTime,formatDateForAuctionDetail } from "../../../../utils/helper";




const noimages = [{ url: image1, id: 0 }];



const Overview = ({ auctionDetail, highestBidAmount }) => {
    const listData = [];
    const listHeading = [{
        date: 'Date and time',
        mybid: 'Bid price',
    }];

    const images = auctionDetail.images;

    const [image, setImage] = useState(0);

    const handlePrev = () => {
        if (image === 0) return;
        const imageIndex = image - 1;
        setImage(imageIndex);
    };

    const handleNext = () => {
        if (image === images.length - 1) return;
        const imageIndex = image + 1;
        setImage(imageIndex);
    };

    const handleSelectImage = (index) => {
        setImage(index);
    };

    for (let i = 0; i < 5; i++) {
        listData.push({
            date: "February 22, 2023 13:13 am",
            mybid: "₹40" + i,
        });
    }
    return (
        <>
            <Row style={{ marginTop: '2em', marginBottom: '2em' }} className={styles.rowStyle}>
                <Col lg={1} className={styles.w10}>
                    <p className={styles.textHeading}>Status</p>
                    <p>{auctionDetail.status}</p>
                </Col>
                <Col lg={2} className={styles.w17}>
                    <p className={styles.textHeading}>No of bids</p>
                    <p className={styles.valueText}>{auctionDetail.bids.length}</p>
                </Col>
                <Col lg={2} className={styles.w17}>
                    <p className={styles.textHeading}>Total qty</p>
                    <p>{auctionDetail.totalQuantity}{auctionDetail.quantityUnit}</p>
                </Col>
                <Col lg={2} className={styles.w17}>
                    <p className={styles.textHeading}>Ask price/{auctionDetail.quantityUnit}</p>
                    <p>{auctionDetail.askedPricePerUnit}</p>
                </Col>
                <Col lg={1} className={styles.w10}>
                    <p className={styles.textHeading}>H1 bid/kg</p>
                    <p>{highestBidAmount}</p>
                </Col>
                {/* <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>My bid/kg</p>
                <p>2</p>
            </Col> */}
                <Col lg={2} className={styles.w17}>
                    <p className={styles.textHeading}>Launch date</p>
                    <p>{auctionDetail.launchDate ? formattedDateTime(auctionDetail.launchDate) : '--'}</p>
                </Col>


                {/* ----------------------------- */}




                <section className={styles.imageViewer}>
                    <div className={styles.imageContainer}>
                        {images.length > 0 ? (
                            <>
                                <img src={images[image]} alt={`herb name`} />
                            </>
                        ) : (
                            <>
                                <img src={noimages[image].image} alt={`herb name`} />
                            </>
                        )}
                    </div>



                   <div className={styles.imageSelector}>
                        <img
                            className={styles.iconLeft}
                            onClick={handlePrev}
                            src={previousIcon}
                            alt="Previous Icon"
                        />
                        <div className={styles.thumbnailList}>
                            {images.length > 0 &&
                                images.map((image, index) => (
                                    <div className={styles.imageContainerSmall}>
                                        <img
                                            onClick={() => handleSelectImage(index)}
                                            key={index}
                                            src={image}
                                            alt="herb name"
                                        />
                                    </div>
                                ))}
                        </div>
                        <img
                            className={styles.iconRight}
                            onClick={handleNext}
                            src={nextIcon}
                            alt="Next Icon"
                        />
                    </div> 





                    <Row className={styles.rowStyle}>
        <h3> Description</h3>
        <div className={styles.description}>
              <h3>About auction</h3>
              <div className={styles.collection}>
                <span className={styles.key}>
                  ID:{" "}
                  <span className={styles.value}>
                    {auctionDetail._id && auctionDetail._id}
                  </span>
                </span>
                <span className={styles.key}>
                  Launch date:{" "}
                  <span className={styles.value}>
                    {auctionDetail.createdAt &&
                      formatDateForAuctionDetail(auctionDetail.createdAt)}
                  </span>
                </span>
              </div>
              <h3>Grade description</h3>
              <p>{auctionDetail.gradeDescription}</p>
              <h3>Method of collection</h3>
              <div className={styles.badge}>
                <span>
                  {auctionDetail.collectionMethod &&
                    auctionDetail.collectionMethod[0] &&
                    auctionDetail.collectionMethod[0].type}
                </span>
              </div>
              <div className={styles.method}>
                <div className={styles.methodElement}>
                  <div>
                    <span className={styles.methodHeading}>
                      Report of patwari / revenue authority / girdawari
                    </span>
                    <a
                      href={
                        auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].reportPatwari
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={styles.report}
                    >
                      download report
                    </a>
                  </div>
                  <div>
                    <span className={styles.methodHeading}>
                      Additional document
                    </span>
                    <a
                      href={
                        auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].additionalDocument
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={styles.report}
                    >
                      download report
                    </a>
                  </div>
                </div>
                <h3>Land details:</h3>
                <div className={styles.methodElement}>
                  <div>
                    <span className={styles.methodHeading}>Village</span>
                    <span className={styles.landValue}>
                      {auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].village}
                    </span>
                  </div>
                  <div>
                    <span className={styles.methodHeading}>Tehsil</span>
                    <span className={styles.landValue}>
                      {auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].tehsil}
                    </span>
                  </div>
                  <div>
                    <span className={styles.methodHeading}>State</span>
                    <span className={styles.landValue}>
                      {auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].state}
                    </span>
                  </div>
                  <div>
                    <span className={styles.methodHeading}>District</span>
                    <span className={styles.landValue}>
                      {auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].district}
                    </span>
                  </div>
                </div>
                {auctionDetail.collectionMethod &&
                auctionDetail.collectionMethod[0] &&
                auctionDetail.collectionMethod[0].legalDoc ? (
                  <div className={styles.methodElement}>
                    <div>
                      <span className={styles.methodHeading}>
                        Legal procurement certificate (LPC)
                      </span>
                      <a
                      href={
                        auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].legalDoc
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={styles.report}
                    >
                      download report
                    </a>
                    </div>
                  </div>
                ) : null}
              </div>
              <h3>Packaging</h3>
              <div className={styles.collection}>
                <span className={styles.key}>
                  Type:{" "}
                  <span className={styles.value}>
                    {auctionDetail.packagingType}
                  </span>
                </span>
                <span className={styles.key}>
                  Size:{" "}
                  <span className={styles.value}>
                    {auctionDetail.packagingSize}
                  </span>
                </span>
              </div>
            </div>   
        </Row> 
                </section>


            </Row>
            <h3> Bids</h3>
            <Row className={styles.rowStyle}>
                <MyBid listData={auctionDetail.bids} listHeading={listHeading} auctionDetail={auctionDetail} />
            </Row>
        </>
    );
};

export default Overview;



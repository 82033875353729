import React from "react";
import { Row, Col } from "react-bootstrap";
import ShowPageDropdown from "../showPageDropdown";
import CustomPagination from "../CustomPagination";
import styles from "./tableFooter.module.css";

const TableFooter = ({
  itemsPerPage,
  handleItemsPerPageChange,
  currentPage,
  pageCount,
  handlePageChange,
  fromCount,
  totalCount,
}) => {
  return (
    <Row className="mt-5">
      <Col lg={4} style={{ paddingLeft: "0px" }}>
        <Row>
          {/* <Col lg={4}>
            <span className={styles.showitem}>
              {fromCount}-{itemsPerPage} of {totalCount}
            </span>
          </Col> */}
          <Col lg={8} className={styles.showitem}>
            <span className={styles.showitem}>Rows per page:</span>
            <ShowPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          </Col>
        </Row>
      </Col>
      <Col lg={4}></Col>
      <CustomPagination
        currentPage={currentPage}
        pageCount={pageCount}
        handlePageChange={handlePageChange}
      />
    </Row>
  );
};

export default TableFooter;

import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchLiveAuctionDetail({ payload }) {
  
  try {
    const res = yield call(API.fetchHomeRecentAuctionDetailData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.homeAuctionDetailSuccess(data));
  } catch (err) {
    yield put(actions.homeAuctionDetailFailure(err?.response?.data));
  }
}

export function* handleFetchLiveAuctionDetailforUnAuthencated({ payload }) {
  
  try {
    const res = yield call(API.fetchHomeRecentAuctionDetailDataUnAuthenticated, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.homeAuctionDetailSuccess(data));
  } catch (err) {
    yield put(actions.homeAuctionDetailFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* labListingSaga() {
  yield takeLatest(actions.fetchAuctionDetail.type, handleFetchLiveAuctionDetail);
  yield takeLatest(actions.fetchAuctionDetailforUnAuthenticated.type, handleFetchLiveAuctionDetailforUnAuthencated);
  
}

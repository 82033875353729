import React from "react";
import styles from "./styles.module.css";

export default function MedicinalPlantsAssessment() {
  return (
    <div className={styles.medicinalPlantsAssessment}>
      <ul>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Herbal sector in India: current scenario
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter2.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Study methodology and strategy
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter3.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Consumptionby domestic herbal industry
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter4.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Consumption by rural households and folk healers
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter5.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Supply of herbal raw drugs from wild collections
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_asse$ssment/chapter6.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Supplies of herbal raw drugs from cultivation
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter7.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Domestic trade of herbal raw drugs
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter8.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Foreign trade (import and export) of herbal raw drugs
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter9.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Controversial herbal raw drugs (substitutes, equivalents,
            adulterants)
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter10.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Medicinal plants trade in india: policy and legislative framework
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter11.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Medicinal plant species in commercial demand: consolidated inventory
            and Analysis
          </a>
        </li>
        <li>
          <a
            href="https://echarak.in/echarak/templates/medicinal_plants_assessment/chapter12.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Discussion and recommendations
          </a>
        </li>
      </ul>
    </div>
  );
}

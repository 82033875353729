import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.buyerCancelledAuctions || initialState;

export const selectliveauctions = createSelector([selectDomain], (state) => state.cancelledAuctions);




import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions } from "./slice";
import { authSaga } from "./saga";
import * as selectors from "./selectors";
import roles from "../../constants/roles";
import actionConstants from "../../constants/actions";

const AuthContext = React.createContext({
  login: () => {},
  adminLogin: () => {},
  logout: () => {},
  error: null,
  loginInProgress: false,
  otpLoginInProgress: false,
  checkingForLoggedInUser: false,
  loggedInUser: null,
  permissions: [],
  fetchLoggedInUser: () => {},
  updateLoggedInUser: () => {},
  isAuthorised: () => {},
  generateOtp: () => {},
  generateLoginOtp: () => {},
  validateOtp: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: authSaga });
  const dispatch = useDispatch();

  const navigate = useNavigate();
  //const error = useSelector(selectors.selectError);
  //const successMessage = useSelector(selectors.selectMessage);
  const loginInProgress = useSelector(selectors.selectLoginInProgress);
  const otpLoginInProgress = useSelector(selectors.selectOTPLoginInProgress);
  const logoutSuccess = useSelector(selectors.selectLogoutSuccess);
  const loggedInUser = useSelector(selectors.selectLoggedInUser);
  const fetchingLoggedInUser = useSelector(
    selectors.selectFetchingLoggedInUser
  );

  const login = ({ username, password }) => {
    dispatch(actions.login({ username, password }));
  };

  const adminLogin = ({ username, password }) => {
    dispatch(
      actions.adminLogin({
        username,
        password,
        onSuccess: () => {
          navigate("dashboard/admin/auctions");
        },
      })
    );
  };

  const logout = () => {
    dispatch(actions.logout());
    navigate('/');
  };

  const fetchLoggedInUser = () => {
    dispatch(actions.fetchLoggedInUser());
  };

  const updateLoggedInUser = () => {
    dispatch(actions.updateLoggedInUser());
  };

  const generateOtp = (phone, state, onSuccess) => {
    const payload = {
      data: {
        phone,
        state,
      },
      onSuccess,
    };
    dispatch(actions.generateOtp(payload));
  };

  const generateLoginOtp = (phone, state, onSuccess) => {
    const payload = {
      data: {
        phone,
        state,
      },
      onSuccess,
    };
    dispatch(actions.generateLoginOtp(payload));
  };

  const validateOtp = (phone, requestedOtp, state, onSuccess) => {
    const payload = {
      data: {
        phone,
        state,
        requestedOtp,
      },
      onSuccess,
    };

    dispatch(actions.validateOtp(payload));
  };
  /*   useEffect(() => {
    if (error && error !== "") {
      //message.error(error);
    }
  }, [error]); 

  useEffect(() => {
    if (successMessage && successMessage !== "") {
      //message.success(successMessage);
    }
  }, [successMessage]);
  */
  useEffect(() => {
    dispatch(actions.fetchLoggedInUser());
  }, [dispatch]);

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    let l = searchParams.get("l");

    if (!l) {
      if (logoutSuccess) {
        alert('logout');
        navigate("/");
      }
    } else {
      dispatch(actions.logoutCustom());
    }
    
  }, [logoutSuccess, navigate, dispatch]);

  const role = loggedInUser?.role;
  let permissions = [];
  Object.keys(roles).forEach((key) => {
    if (role && roles[key].name === role) {
      if (
        (role === roles.SELLER.name || role === roles.BUYER.name) &&
        loggedInUser.status === "approval_pending"
      )
        permissions = [actionConstants.ACCESS_PORTAL_NOT_ALLOWED];
      else permissions = [...roles[key].permissions];
    }
  });

  const isAuthorised = (action) => {
    // console.log("coming from authContext",permissions.indexOf(action) !== -1)
    return permissions.indexOf(action) !== -1;
  };

  const authValues = {
    login,
    adminLogin,
    logout,
    // error,
    loginInProgress,
    otpLoginInProgress,
    checkingForLoggedInUser: fetchingLoggedInUser,
    loggedInUser,
    permissions,
    fetchLoggedInUser,
    updateLoggedInUser,
    isAuthorised,
    generateOtp,
    generateLoginOtp,
    validateOtp,
  };

  return (
    <AuthContext.Provider value={authValues}>
      {/* fetchingLoggedInUser ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </div>
      ) : (
        ""
      )*/}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React from 'react';
import { Dropdown } from 'react-bootstrap';

const ShowPageDropdown = ({ itemsPerPage, handleItemsPerPageChange }) => {
  const options = [10, 50, 100]; // You can customize the available options here
  const styles ={
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1A202C',
    backgroundColor: '#EDF2F7',  
    borderRadius: '6px',
    border: 'none'
  } 
  return (
    <Dropdown style={{ float: 'right'}} >
      <Dropdown.Toggle style={styles} id="showPageDropdown">
        {itemsPerPage}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            key={option}
            active={option === itemsPerPage}
            onClick={() => handleItemsPerPageChange(option)}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShowPageDropdown;

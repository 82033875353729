import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteIcon from "../../../../../assets/icons/deleteIconred.svg"
// import { BsCloudUpload } from 'react-icons/bs';
import styles from "./style.module.css";
import Dropzone from "react-dropzone";

const ImageForm = ({
  formData,
  handleInputChange,
  saveImages,
  previousStep,
  detail,
  removeAuctionImage
}) => {
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);

  const [divList, setDivList] = useState([]);
  const handleDrop = (acceptedFiles) => {
    const updatedFiles = acceptedFiles.map((file) =>      
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    
    setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };

  const handleRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  const handleExistRemove = (key) => {   
    const updatedData = divList.filter((div, index) => index !== key);
    setDivList(updatedData);
    removeAuctionImage(key);
  };
  const handleNext = () => {
    if (files.length === 0 && divList.length === 0) {
      setFileError("Please select an image");
    }else{
      setFileError(null);
    }
    if(files.length !== 0){
      setFileError(null);
      saveImages(files);
    }else{
      if(divList.length > 0){
        setFileError(null);
        saveImages(files);
      }
    }
  };

  useEffect(()=> {
    setDivList(detail.auctionDetail?.images || []);
  }, [detail]);

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <Row>
        <Col lg={12} className="mt-5">
          <h3 className="formheadingText mb-3">Upload Images</h3>
          <Dropzone onDrop={handleDrop} accept="image/*">
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.dropzone}>
                <input {...getInputProps()} required accept=".jpeg" 
                className={`form-control ${fileError && 'is-invalid'} ${
                fileError === false && 'is-valid'
              }`} onChange={handleInputChange} />
                {/* <BsCloudUpload size={40} /> */}
                <p>
                  Drag and drop files or{" "}
                  <span className="text-success">Browse</span>
                </p>
              </div>
            )}
          </Dropzone>
          <div>
            <small className={`${fileError && 'invalid-feedback'} `} style={{display: 'unset'}}>
              {!!fileError && fileError}
            </small>
          </div>
          <div className="row">
            <div className={[styles.previewContainer].join(' ')}>
              {files.map((file, index) => (
                <div className={[styles.preview, 'col-md-2'].join(' ')} style={{ textAlign: 'center'}} key={index}>
                  <Image src={file.preview} thumbnail />
                  <Link to={'#'} className="text-danger" onClick={() => handleRemove(index)}>
                  {'Remove'}<img src={ deleteIcon } style={{ width: '20px'}} /> 
                  </Link>
                </div>
              ))}
              {divList.map((file, index) => (
                <div className={[styles.preview, 'col-md-2'].join(' ')} style={{ textAlign: 'center'}} key={index}>
                  <Image src={file} thumbnail />
                  <Link to={'#'} className="text-danger" onClick={() => handleExistRemove(index)}>
                  {'Remove'}<img src={ deleteIcon } style={{ width: '20px'}} /> 
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mt-5">
          <div className="float-end">
            <Button variant="secondary" onClick={previousStep}>
              Previous
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImageForm;
import axios from "../../axios";

const requestConfig = {
  url: "",
  method: "get", // default
  baseURL: "",
  transformRequest: [
    function transformRequest(data) {
      // Do whatever you want to transform the data
      return data;
    },
  ],
  transformResponse: [
    function transformResponse(data) {
      // Do whatever you want to transform the data
      return data;
    },
  ],
  headers: {},
  params: {},
  timeout: 330000,
  withCredentials: true,
  credentials: "include",
  responseType: "json", // default
  maxContentLength: 50000,
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
  maxRedirects: 5, // default
};

export const getFromLocalStorage = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveToLocalStorage = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Ignoring write error as of now
  }
};

export const clearFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
};

function getRequestConfig(apiConfig) {
  let config = Object.assign({}, requestConfig);
  if (apiConfig) {
    config = Object.assign({}, requestConfig, apiConfig);
  }
  return config;
}

export const get = (url, params, apiConfig) => {
  const config = getRequestConfig(apiConfig);
  config.params = params;
  // const token = localStorage.getItem("token");
  // axios.defaults.headers.get["Authorization"] = `bearer ${token}`;
  const request = axios.get(url, config);
  return request;
};

export const post = (url, data, apiConfig) => {
  const config = getRequestConfig(apiConfig);
  // let postData = {};
  // postData = JSON.stringify(data);
  // const token = localStorage.getItem("token");
  axios.defaults.headers.post["Content-Type"] = "application/json";
  // if (token) {
  //   axios.defaults.headers.post["Authorization"] = `bearer ${token}`;
  // }
  const request = axios.post(url, JSON.stringify(data), config);
  return request;
};

export const put = (url, data) => {
  const config = getRequestConfig();
  // const token = localStorage.getItem("token");
  axios.defaults.headers.put["Content-Type"] = "application/json";
  // if (token) {
  //   axios.defaults.headers.put["Authorization"] = `bearer ${token}`;
  // }
  const request = axios.put(url, JSON.stringify(data), config);
  return request;
};

export const patch = (url, data) => {
  const config = getRequestConfig();
  config.headers["Content-Type"] = "application/json";
  const request = axios.patch(url, JSON.stringify(data), config);
  return request;
};

export const deleteResource = (url) => {
  const config = getRequestConfig();
  const request = axios.delete(url, config);
  return request;
};

export const objectToQueryString = (queryObject)=>{  
    let urlQuery = '';
    for (const key in queryObject) {
      
      if(queryObject[key]){
        if(urlQuery){
          urlQuery =  `${urlQuery}&${key}=${queryObject[key]}`
        }
        else{
          urlQuery = `${urlQuery}?${key}=${queryObject[key]}`
        }
      }      
  }
  return urlQuery;
}

import React from "react";
import styles from "./styles.module.css";
import { useAuth } from "../../../contexts/auth";
import { Link } from "react-router-dom";

export default function Disclaimer() {
  const auth = useAuth();
  return (
    <>
      <main className={styles.about}>
        <h1>Disclaimer Policy for Jadi Buti Bazar</h1>
        <h2>1. Introduction</h2>
        <p>
        Jadi Buti Bazar is an online e-auction platform designed to facilitate the trade of raw herbs and their derivatives. By connecting farmers, farmer producer organizations (FPOs), farmer producer companies (FPCs), aggregators, and buyers, Jadi Buti Bazar aims to create a transparent marketplace that benefits both sellers and buyers. However, the use of this platform is subject to certain disclaimers and conditions outlined below.
        </p>
        <h2>2. General Disclaimer</h2>
        <p>
        Platform Use: Jadi Buti Bazar serves as an intermediary to connect sellers and buyers of raw herbs and their derivatives. The platform does not participate in or guarantee any transactions, the quality of products, or the accuracy of the information provided by users. All transactions and interactions on the platform are conducted at the user's own risk.
        </p>
        <p>
        No Guarantee of Quality or Authenticity: While Jadi Buti Bazar aims to ensure that products listed on the platform are of good quality and authentic, it does not independently verify the quality, authenticity, or traceability of the products. The quality report and traceability details provided on the platform are based on information supplied by the sellers and third-party laboratories or agencies. Jadi Buti Bazar is not responsible for any discrepancies or inaccuracies in these reports.
        </p>



        <h2>3. User Responsibility</h2>
        <p>
        Accuracy of Information: Sellers are responsible for ensuring the accuracy of the information provided during auction creation, including product details, quality reports, and traceability information. Buyers are advised to carefully review all information and conduct their due diligence before participating in any auction or making any purchase.</p>
        <p>Compliance with Laws and Regulations: Users are solely responsible for ensuring that their activities on the platform comply with all applicable local, national, and international laws and regulations. Jadi Buti Bazar does not provide legal advice and is not liable for any legal issues arising from the use of the platform.

        </p>
        <h2>4. Limitations of Liability</h2>
        <p>
        Indirect or Consequential Damages: Jadi Buti Bazar shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or related to the use of the platform, including but not limited to loss of profits, loss of business opportunities, or any other commercial or economic losses.
        </p>
        <p>
        Force Majeure: Jadi Buti Bazar shall not be liable for any failure to perform its obligations under this disclaimer policy due to events beyond its reasonable control, including but not limited to acts of God, natural disasters, war, civil unrest, or technical failures.
        </p>


        <h2>5. Third-Party Services</h2>
        <p>
        Third-Party Quality Assessments: The platform may feature or link to services provided by third-party laboratories, quality assessors, or traceability agencies. Jadi Buti Bazar does not endorse, guarantee, or assume responsibility for these third-party services, and users interact with them at their own risk.
        </p>
        <h2>6. Intellectual Property</h2>
        <p>
        Content Ownership: All content, trademarks, logos, and data available on the Jadi Buti Bazar platform are the property of their respective owners and are protected by intellectual property laws. Unauthorized use of any content on the platform is prohibited.
        </p>
        <p>User-Generated Content: Any content uploaded by users, including auction details, product images, and quality reports, remains the property of the user but grants Jadi Buti Bazar a non-exclusive, worldwide, royalty-free license to use, display, and distribute such content on the platform.</p>
        <h2>7. Privacy and Data Security</h2>
        <p>
        User Data: Jadi Buti Bazar is committed to protecting the privacy and security of user data. However, it cannot guarantee the absolute security of data transmitted over the internet and will not be liable for any unauthorized access, data breaches, or other security incidents beyond its control.
        </p>
        <p>Use of Data: By using the platform, users consent to the collection, use, and processing of their personal data as described in the platform’s Privacy Policy. Users are advised to review the Privacy Policy to understand their rights and obligations regarding their personal data.</p>
     
        <h2>8. Amendments to the Disclaimer Policy</h2>
        <p>
        Jadi Buti Bazar reserves the right to modify or update this disclaimer policy at any time without prior notice. Users are encouraged to review this policy periodically to stay informed of any changes. Continued use of the platform after any such modifications shall constitute acceptance of the revised policy.
        </p>


        <h2>9. Governing Law</h2>
        <p>
        This disclaimer policy shall be governed by and construed in accordance with the laws of lands. Any disputes arising out of or related to the use of the platform shall be subject to the exclusive jurisdiction of the courts located in Mandi (Himachal Pradesh) courts.
        </p>

        <h2>10. Contact Information</h2>
        <p>
        For any questions or concerns regarding this disclaimer policy, users may contact Jadi Buti Bazar at If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at:</p>


        <p>
          <ul><li>[Name of the organization:- <strong>Regional cum Facilitation Centre, Northern Region-1,National Medicinal Plants Board,Ministry Ayush, GOI]</strong></li>
            <li><strong> Address:-</strong> [RCFC-Northern Region-1/ क्षेत्रीय सुगमता केंद्र उत्तर भारत-1 NMPB, Ministry AYUSH GOI /राष्ट्रीय औषध पादप बोर्ड,  1st Floor, CoEDG Building, Research Institute in Indian Systems of Medicine (RIISM), Joginder Nagar-175015 Mandi HP] </li></ul> </p>


        <p><strong> Email id:- </strong>
        <li> rcfcnorth@gmail.com </li>  <li>doctorchandan@gmail.com </li>  <li>avikasubba94@gmail.com</li>
         
        <strong> Contact Person:-</strong> 
          <li>	Dr. Arun Chandan(Regional Director)-+91 7018001026 </li>
            <li>	Avika Subba (Consultant-Marketing)- +91-8968619467</li></p>
      
      </main>
    </>
  );
}

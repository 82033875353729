import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.sellerDashboard || initialState;

export const selectauctioncount = createSelector([selectDomain], (state) => state.auctionCount);
export const selectclosedauction = createSelector([selectDomain], (state) => state.closedAuctions);

export const selectDownloadStatus = createSelector(
  [selectDomain],
  (state) => state.downloadInProgress
);

import React, { useState } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./customTable.module.css";
import { formattedDate } from "../../../../utils/helper";

const AuctionCustomTable = ({
  heading,
  filteredData,
  bids,
  onRowClick,
  type,
  dropDownValue,
}) => {
  
  return (
    <>
      <Table responsive>
        <thead style={{ backgroundColor: "#F7FAFC" }}>
          {heading.map((headings, index) => (
            <tr key={index}>
              <td className={styles.headT}>{headings.auctionID}</td>
              {/* <td className={styles.headT}>{headings.supplierID}</td> */}
              <td className={styles.headT}>{headings.supplierName}</td>
              <td className={styles.headT}>{headings.herbName}</td>
              <td className={styles.headT}>{headings.createdDate}</td>
              {/* {dropDownValue !== "In Review" && (
                    <td className={styles.headT}>{headings.noOfbids}</td>
                  )} */}
              <td className={styles.headT}>{headings.noOfbids}</td>
              <td className={styles.headT}>{headings.quantity}(KG)</td>
              <td className={styles.headT}>{headings.askedPricePerUnit}</td>
              {/* {dropDownValue !== "In Review" && (
                    <td className={styles.headT}>{headings.highbid}</td>
                  )} */}
              <td className={styles.headT}>{headings.status}</td>
            </tr>
          ))}
        </thead>
        <tbody>
          {filteredData.map((data, index) => (
            <tr
              key={index}
              onClick={() => {
                onRowClick(data.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <td className={styles.dataT}>{data.auctionID}</td>
              {/* <td className={styles.dataT}>{data.supplierID}</td> */}
              <td className={styles.dataT}>{data.supplierName}</td>
              <td className={styles.dataT}>
                <span>{data.herbName}</span>
              </td>
              <td className={styles.dataT}>
                <span>{formattedDate(data.createdDate)}</span>
              </td>
              {/* {dropDownValue !== "In Review" && (
                    <td className={styles.dataT}>
                      <span>{data.noOfbids}</span>
                    </td>
                  )} */}
              <td className={styles.dataT}>
                <span>{data.noOfbids}</span>
              </td>
              <td className={styles.dataT}>
                <span>{data.quantity}</span>
              </td>
              <td className={styles.dataT}>
                <span>{data.askedPricePerUnit}</span>
              </td>
              {/* {dropDownValue !== "In Review" && (
                    <td className={styles.dataT}>
                      <span>{data.highbid}</span>
                    </td>
                  )} */}
              <td className={styles.dataT}>
                <span>{data.status}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AuctionCustomTable;

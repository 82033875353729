import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  Link:{},
};

const LinksForAdmin = createSlice({
  name: "LinksForAdmin",
  initialState,
  reducers: {
    resetLink(state, action) {
      state.loading = false;
      state.error = null;
      state.Link = {};
    },
    fetchLinkById(state, action) {
      state.loading = true;
    },
    fetchLinkByIdSuccess(state, action) {
      state.Link = action.payload; 
      state.loading = false;
    },
    fetchLinkByIdFailure(state, action) {
      state.loading = false;
    },
    createLink(state, action) {
      state.loading = true;
    },
    createLinkSuccess(state, action) {
      // state.Link = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    createLinkFailure(state, action) {
      state.loading = false;
    },
    updateLink(state, action) {
      state.loading = true;
    },
    updateLinkSuccess(state, action) {
      // state.Link = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    updateLinkFailure(state, action) {
      state.loading = false;
    },
    removeLink(state, action) {
      state.loading = true;
    },
    removeLinkSuccess(state, action) {
      state.Link = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    removeLinkFailure(state, action) {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = LinksForAdmin;

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
  } from '@mui/material';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { mandiListingSaga } from './saga';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { sliceKey, reducer, actions, initialState } from "./slice";
import * as selectors from "./selectors";
import TableFooter from '../../../components/dashboardApp/tableFooter';


 const Mandi = () => {
    

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: mandiListingSaga });
  const dispatch = useDispatch();
  const dbMandiList = useSelector(selectors.selectMandiList) || initialState;

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [fromPage, setFromPage] = useState(1);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    // Perform any other actions based on the new items per page value
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData,SetTableData] = useState([]);

  // const [page, setPage] = useState(0);
  const rowsPerPage = 15; 

  const totalCount = dbMandiList.totalRecords;
  const pageCount = Math.ceil(totalCount / itemsPerPage);




  useEffect(() => {
    SetTableData(dbMandiList.mandiItems)
  }, [dbMandiList]);


  useEffect(() => {
    const payload = {
      page: currentPage - 1,
      limit: itemsPerPage,
    };
    dispatch(actions.fetchMandis(payload));
  
  }, [dispatch,  currentPage, itemsPerPage]);


    const handleChangePage = ( newPage) => {
      setCurrentPage(newPage);
    };
  


  return (<>
    {/* <button onClick={HandleNew}>Add</button> */}
    <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
    <TableContainer component={Paper}>
      <Table aria-label="Mandi Data Table" stickyHeader>
        <TableHead>
        <TableRow>
        <TableCell colSpan={7}><strong  style={{textAlign:"center"}}><h1 className='marketheading'>Market rates of Medicinal Plants</h1></strong></TableCell>
        </TableRow>
        <TableRow>
        <TableCell colSpan={7}><strong className='marketheading1'  style={{textAlign:"center", display:"block"}}>August Second Fortnight (2024)</strong></TableCell>
        </TableRow>
        <TableRow>
            <TableCell colSpan={4}><strong  style={{textAlign:"center", display:"block"}}>Name of Medicinal Plants</strong></TableCell>
            <TableCell colSpan={3}><strong  style={{textAlign:"center", display:"block"}}>Mandi Name & Rate/Kg</strong></TableCell>
            </TableRow>
          <TableRow>
            {/* <TableCell><strong>SNo</strong></TableCell> */}
            <TableCell><strong>Botanical Name</strong></TableCell>
            <TableCell><strong>Trade Name</strong></TableCell>
            <TableCell><strong>Parts</strong></TableCell>
            <TableCell><strong>Amritsar</strong></TableCell>
            <TableCell><strong>Delhi</strong></TableCell>
            <TableCell><strong>Saharanpur</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {tableData?.map((row,inde) => (
              <TableRow key={row?.tradeName + inde} hover>
                {/* <TableCell>{inde + 1}</TableCell> */}
                <TableCell>{row?.botanicalName}</TableCell>
                <TableCell>{row?.tradeName}</TableCell>
                <TableCell>{row?.parts.join(', ')}</TableCell>
                <TableCell>
                  {typeof row?.rates.amritsar === 'number' ? row?.rates.amritsar : row?.rates.amritsar}
                </TableCell>
                <TableCell>
                  {typeof row?.rates.delhi === 'number' ? row?.rates.delhi : row?.rates.delhi}
                </TableCell>
                <TableCell>
                  {typeof row?.rates.saharanpur === 'number'
                    ? row?.rates.saharanpur
                    : row?.rates.saharanpur}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {/* <TablePagination
        component="div"
        count={totalCount}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[15]}
        labelRowsPerPage=""
      /> */}

{pageCount > 1 ? (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              currentPage={currentPage}
              pageCount={pageCount}
              handlePageChange={handleChangePage}
              totalCount={totalCount}
              fromCount={fromPage}
            />
          ) : (
            <></>
          )}
    </Paper>
</>
  )
}

export default Mandi;
import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../../services/apis";

export function* handleFetchAllCountries({ payload }) {
  try {
    const res = yield call(API.getCountries, payload);
    const parsedObject = JSON.parse(res.data);
    const { data } = parsedObject;
    yield put(actions.fetchCountriesSuccess(data));
  } catch (err) {
    yield put(actions.fetchCountriesFailure(err?.response?.data));
  }
}

export function* handleFetchAllStates({ payload }) {
  try {
    const res = yield call(API.getStates, payload);
    const parsedObject = JSON.parse(res.data);
    const { data } = parsedObject;
    yield put(actions.fetchStatesSuccess(data));
  } catch (err) {
    yield put(actions.fetchStatesFailure(err?.response?.data));
  }
}

export function* handleFetchAllCities({ payload }) {
  console.log('is here in the saga')
  try {
    const res = yield call(API.getCities, payload);
    const parsedObject = JSON.parse(res.data);
    const { data } = parsedObject;
    yield put(actions.fetchCitiesSuccess(data));
  } catch (err) {
    yield put(actions.fetchCitiesFailure(err?.response?.data));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* regionSaga() {
  yield takeLatest(actions.fetchCountries.type, handleFetchAllCountries);
  yield takeLatest(actions.fetchStates.type, handleFetchAllStates);
  yield takeLatest(actions.fetchCities.type, handleFetchAllCities);
}

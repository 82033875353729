import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  auctionDetail:{},
  
  
};

const homeAuctionDetail = createSlice({
  name: "homeAuctionDetail",
  initialState,
  reducers: {
    fetchAuctionDetail(state, action) {
      state.loading = true;
    },
    fetchAuctionDetailforUnAuthenticated(state, action) {
      state.loading = true;
    },
    homeAuctionDetailSuccess(state, action) {      
      state.auctionDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    homeAuctionDetailFailure(state, action) {
      state.loading = false;
    },
    clearStore(state,action){
      state.loading = false
      state.error = null
      state.auctionDetail = null      
      }
  },
});

export const { actions, reducer, name: sliceKey } = homeAuctionDetail;

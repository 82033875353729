import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Button from "../../../components/shared/button";
import { useAuth } from "../../../contexts/auth";

const buttonStyle = {
  width: "100%",
};

export default function AdminLogin() {
  const auth = useAuth();


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { adminLogin, loggedInUser, logout } = auth;
  

  useEffect(() => {
    if (loggedInUser) {
      logout();
    }
  }, [loggedInUser, logout]);

  const handleSubmit = (e) => {
    e.preventDefault();
    adminLogin({ username: email, password });
  };

  return (
    <div className={styles.login}>
      <h1>Admin Login</h1>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">Enter Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="wmail"
              placeholder="Email Address"
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              placeholder="Password"
            />
          </div>
          <Button
            solid={true}
            text="Login"
            type="submit"
            buttonStyle={buttonStyle}
          />
        </form>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { toastError, toastSuccess } from "../../utils/toastHelper";

export const initialState = {
  loggedInUser: null,
  loginInProgress: false,
  fetchingUser: false,
  error: null,
  message: null,
  resetPasswordStatus: null,
  fetchingLoggedInUser: true,
  logoutInProgress: false,
  logoutSuccess: false,
  generateOtpInProgress: false,
  generateLoginOtpInProgress: false,
  validateOtpInProgress: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.loginInProgress = true;
      state.logoutSuccess = false;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.loginInProgress = false;
      state.error = null;
    },
    loginFailure(state, action) {
      state.error =
        "An error occured during login. Please check username/password or try again later.";
      toastError("An error occured during login. Please check username/password or try again later.");
      console.log({'action':action});
      state.loginInProgress = false;
    },

    adminLogin(state, action) {
      state.loginInProgress = true;
      state.logoutSuccess = false;
      state.error = null;
    },
    adminLoginSuccess(state, action) {
      state.loginInProgress = false;
      state.error = null;
    },
    adminLoginFailure(state, action) {
      console.log({'action':action});
      state.error =
        "An error occured during login. Please check username/password or try again later.";
      state.loginInProgress = false;
      toastError('Error occured')
    },
    updateLoggedInUser(state, action) {
      state.fetchingLoggedInUser = true;
    },
    fetchLoggedInUser(state, action) {
      state.fetchingLoggedInUser = true;
      state.loggedInUser = null;
    },
    fetchLoggedInUserSuccess(state, action) {
      state.fetchingLoggedInUser = false;
      state.loggedInUser = action.payload.data.document;
    },
    fetchLoggedInUserFailure(state, action) {
      state.fetchingLoggedInUser = false;
      state.loggedInUser = null;
    },
    
    logout(state, action) {
      state.logoutInProgress = true;
      state.logoutSuccess = false;
      state.error = null;
    },
    logoutCustom(state, action) {
      state.logoutSuccess = false;      
    },
    logoutSuccess(state, action) {
      state.logoutInProgress = false;
      state.logoutSuccess = false;
      state.error = null;
    },
    logoutFailure(state, action) {
      state.error = action.payload.message;
      state.logoutInProgress = false;
      state.logoutSuccess = false;
    },
    generateOtp(state, action) {
      state.generateOtpInProgress = true;
    },
    generateOtpSuccess(state, action) {
      state.generateOtpInProgress = false;
    },
    generateOtpFailure(state, action) {
      state.generateOtpInProgress = false;
    },
    generateLoginOtp(state, action) {
      state.generateLoginOtpInProgress = true;
    },
    generateLoginOtpSuccess(state, action) {
      state.generateLoginOtpInProgress = false;
    },
    generateLoginOtpFailure(state, action) {
      state.generateLoginOtpInProgress = false;
    },
    validateOtp(state, action) {
      state.validateOtpInProgress = true;
    },
    validateOtpSuccess(state, action) {
      state.validateOtpInProgress = false;
    },
    validateOtpFailure(state, action) {
      state.validateOtpInProgress = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = authSlice;

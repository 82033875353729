import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../../services/apis";
import * as utils from "../../../../app/utils/helper";

export function* handleFetchUserDetail({ payload }) {
  try {
    const res = yield call(API.fetchProfileDetails, payload);
    const parsedObject = JSON.parse(res.data);
    const { data } = parsedObject;
    yield put(actions.fetchProfileDetailsSuccess(data));
  } catch (err) {
    yield put(actions.fetchProfileDetailsFailure(err?.response?.data));
  }
}

export function* handleDownloadAddressProof({ payload }) {
  try {
    // const { fileName } = payload.data;
    // const res = yield call(API.getDownloadUrl, payload);
    // const { data } = res;
    const file = yield call(API.downloadFile, {
      url: payload.addressProofUrl,
      data: "",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(file.data);
    link.setAttribute("download", payload.fileName);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    yield put(actions.downloadAddressProofSuccess({ id: payload.id }));
  } catch (err) {
    yield put(actions.downloadAddressProofFailure({ id: payload.id, err }));
  }
}

export function* handleUpdateBankDetails({ payload }) {
  try {
    //file upload
    const { cancelledChecque } = payload.data;
    if (cancelledChecque) {
      const urlRes = yield call(API.getFileUrl, {
        fileName: cancelledChecque[0].name,
      });

      const url = JSON.parse(urlRes.data).data;
      const formData = new FormData();
      formData.append("file", cancelledChecque[0]);
      const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
      if (fileUploadRes.status !== 200) {
        throw new Error("Error uploading File!");
      }
      const fileUploadedUrl = new URL(url);
      const pathName = fileUploadedUrl?.pathname.slice(1);
      const res = yield call(API.updateBankDetails, {
        ...payload.data,
        cancelledChequeUrl: pathName,
      });
      let data = JSON.parse(res.data);
      yield put(actions.updateBankDetailsSuccess(data));
    } else {
      const res = yield call(API.saveBankDetails, payload.data);
      let data = JSON.parse(res.data);
      yield put(actions.updateBankDetailsSuccess(data));
    }
    payload.onSuccess();
  } catch (err) {
    yield put(actions.updateBankDetailsFailure(err?.response?.data));
    console.log(err);
  }
}

export function* handleUpdateAddressDetails({ payload }) {
  try {
    //file upload
    const { addressProof } = payload.data;
    if (addressProof) {
      const urlRes = yield call(API.getFileUrl, {
        fileName: addressProof[0].name,
      });

      // console.log(addressProof);

      const url = JSON.parse(urlRes.data).data;
      const formData = new FormData();
      formData.append("file", addressProof[0]);
      const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
      if (fileUploadRes.status !== 200) {
        throw new Error("Error uploading File!");
      }
      const fileUploadedUrl = new URL(url);
      const pathName = fileUploadedUrl?.pathname.slice(1);
      const res = yield call(API.updateAddressDetails, {
        ...payload.data,
        addressProofUrl: pathName,
      });
      let data = JSON.parse(res.data);
      yield put(actions.updateAddressDetailsSuccess(data));
    } else {
      const res = yield call(API.updateAddressDetails, payload.data);
      let data = JSON.parse(res.data);
      yield put(actions.updateAddressDetailsSuccess(data));
    }
    payload.onSuccess();
  } catch (err) {
    yield put(actions.updateAddressDetailsFailure(err?.response?.data));
  }
}

// export function* handleFetchOpenAuctionDetail({ payload }) {

//   try {
//     const res = yield call(API.fetchOpenAuctionDetailData, payload);
//     const parsedObject = JSON.parse(res.data);
//     const { data } = parsedObject;
//     yield put(actions.homeAuctionDetailSuccess(data));
//   } catch (err) {
//     yield put(actions.homeAuctionDetailFailure(err?.response?.data));
//   }
// }
export function* handleUpdateLegalDetails({ payload }) {
  try {
   //file upload

   const { aadharCertificate, gstCertificate, panCertificate } = payload.data;
   let aadharUrl = "";
   let gstUrl = "";
   let panUrl = "";

   console.log(payload);

   if (aadharCertificate) {
     const fileName = aadharCertificate[0].name;
     const fileType = aadharCertificate[0].type;
     if (fileType === "application/pdf") {
       console.log(aadharCertificate);
       const urlRes = yield call(API.getFileUrl, {
         fileName,
         fileType,
       });
       const url = JSON.parse(urlRes.data).data;
       const formData = new FormData();
       formData.append("file", aadharCertificate[0]);
       const fileUploadRes = yield call(API.uploadFile, {
         data: formData,
         url,
       });
       if (fileUploadRes.status !== 200) {
         throw new Error("Error uploading Aadhar!");
       }
       const fileUploadedUrl = new URL(url);
       aadharUrl = fileUploadedUrl?.pathname.slice(1);
       payload.data.aadharUrl = aadharUrl;
     } else {
       console.log(aadharCertificate);
       const urlRes = yield call(API.getFileUrl, {
         fileName,
         fileType,
       });
       const convertedFile = yield utils.convertFileToBase64(aadharCertificate[0]);
       const url = JSON.parse(urlRes.data).data;
       const formData = new FormData();
       formData.append("file", convertedFile);
       const fileUploadRes = yield call(API.uploadFile, {
         data: formData,
         url,
       });
       if (fileUploadRes.status !== 200) {
         throw new Error("Error uploading Aadhar!");
       }
       const fileUploadedUrl = new URL(url);
       aadharUrl = fileUploadedUrl?.pathname.slice(1);
       payload.data.aadharUrl = aadharUrl;
     }
   }

   if (gstCertificate) {
     const urlRes = yield call(API.getFileUrl, {
       fileName: gstCertificate[0].name,
       fileType: gstCertificate[0].type,
     });

     const url = JSON.parse(urlRes.data).data;
     const formData = new FormData();
     formData.append("file", gstCertificate[0]);
     const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
     if (fileUploadRes.status !== 200) {
       throw new Error("Error uploading Gst!");
     }
     const fileUploadedUrl = new URL(url);
     gstUrl = fileUploadedUrl?.pathname.slice(1);
     payload.data.gstUrl = gstUrl;
   }

   if (panCertificate) {
     const urlRes = yield call(API.getFileUrl, {
       fileName: panCertificate[0].name,
       fileType: panCertificate[0].type,
     });

     const url = JSON.parse(urlRes.data).data;
     const formData = new FormData();
     formData.append("file", panCertificate[0]);
     const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
     if (fileUploadRes.status !== 200) {
       throw new Error("Error uploading Pan!");
     }
     const fileUploadedUrl = new URL(url);
     panUrl = fileUploadedUrl?.pathname.slice(1);
     payload.data.panUrl = panUrl;
   }

   const res = yield call(API.saveLegalDetails, {
     ...payload.data,
   });
   let data = JSON.parse(res.data);
   yield put(actions.saveLegalDetailsSuccess(data));

   payload.onSuccess();
 } catch (err) {
   yield put(actions.saveLegalDetailsFailure(err?.response?.data));
   console.log(err);
 }
 //yield takeLatest(actions.saveLegalDetails.type, handleSaveLegalDetails);
}
/**
 * Root saga manages watcher lifecycle
 */
export function* myProfileSaga() {
  yield takeLatest(actions.fetchProfileDetails.type, handleFetchUserDetail);
  //   yield takeLatest(actions.fetchOpenHomeAuctionDetail.type, handleFetchOpenAuctionDetail);
  yield takeLatest(
    actions.downloadAddressProof.type,
    handleDownloadAddressProof
  );
  yield takeLatest(
    actions.updateAddressDetails.type,
    handleUpdateAddressDetails
  );
  yield takeLatest(actions.updateBankDetails.type, handleUpdateBankDetails);
  yield takeLatest(actions.saveLegalDetails.type, handleUpdateLegalDetails);
}




import React, { useState } from "react";
import styles from "./styles.module.css";
import openIcon from "../../../../assets/icons/open.svg";
import closeIcon from "../../../../assets/icons/close.svg";

export default function Accordian({ title, children }) {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={styles.accordian}>
      <div
        style={isActive ? { background: "#F7FAFC" } : {}}
        className={styles.title}
        onClick={handleToggle}
      >
        <h3>{title}</h3>
        <img src={isActive ? closeIcon : openIcon} alt="" />
      </div>
      <div
        style={isActive ? { display: "block" } : { display: "none" }}
        className={styles.details}
      >
        {children}
      </div>
    </div>
  );
}

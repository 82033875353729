import React, { useState} from "react";
import { Badge, Table } from 'react-bootstrap';
import styles from "./style.module.css";
import PlaceBid from "../PlaceBidInputButton";
import { formattedDate,formattedDateTime } from "../../../utils/helper";
import Button from "../../shared/button";
import { useAuth } from "../../../contexts/auth";

const SellerBid = ({listHeading, listData,auctionDetail,awardAuction}) => {   
    
    const auth = useAuth();
    let arrayForSort = [];
    if(listData.length){
        arrayForSort = [...listData]
        arrayForSort.sort(
            (p1, p2) => 
            (p1.amount < p2.amount) ? 1 : (p1.amount > p2.amount) ? -1 : 0);
    }
    
    
       
    
  return (
    <>
        <Table responsive>
            <thead style={{backgroundColor: '#F7FAFC'}}>
                {listHeading.map((auction, index) => (
                    <tr key={index}>
                        
                        <td className={styles.headT}>{auction.date}</td>
                        <td className={styles.headT}>{auction.mybid}</td>
                        <td className={styles.headT}>{auction.bidPlacedBy}</td>
                    </tr>
                    ))}
            </thead>
            <tbody>
                {arrayForSort.map((bid, index) => (
                    <tr key={index}>
                        <td className={styles.dataT}>{formattedDateTime(bid.createdAt)}</td>
                        <td className={styles.dataT}>₹{bid.amount} 
                       {auctionDetail.status === 'awarded' && auctionDetail.awardedBid === bid._id && 
                            <Badge className={[styles.wonBadge].join(' ')}>
                            Won
                            </Badge> 
                       }
                       {(auctionDetail.status === 'bid_closed' && auth.loggedInUser.role == 'seller') && 
                            <button className={[ 'btn', styles.btnOutline, 'float-end' ].join(' ')} onClick={ () => awardAuction(bid)}> Award</button>
                       }
                        </td>
                        <td className={styles.dataT}>{(bid.placedBy && bid.placedBy.companyName) ? bid.placedBy.companyName : '--'}</td>                        
                    </tr>
                ))}
            </tbody>
        </Table>   
    </>
  );
};

export default SellerBid;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  auctionCount:null,
  buyerwonlost: null,
  downloadInProgress: {},
};

const buyerDashboardSlice = createSlice({
  name: "buyerDashboard",
  initialState,
  reducers: {
    fetchAuctionCount(state, action) {
      state.loading = true;
    },
    fetchAuctionCountSuccess(state, action) {
      state.auctionCount = action.payload; 
      state.loading = false;
    },
    fetchAuctionCountFailure(state, action) {
      state.loading = false;
    },
    fetchWonLost(state, action) {
      state.loading = true;
    },
    fetchWonLostSuccess(state, action) {
      state.buyerwonlost = action.payload; 
      state.loading = false;
    },
    fetchWonLostFailure(state, action) {
      state.loading = false;
    },
    downloadDocument(state, action) {
      state.downloadInProgress[action.payload.id] = true;
    },
    downloadDocumentSuccess(state, action) {
      state.downloadInProgress[action.payload.id] = false;
    },
    downloadDocumentFailure(state, action) {
      state.downloadInProgress[action.payload.id] = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = buyerDashboardSlice;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  cancelledAuctions:{auctions:[],totalRecords:0},
  
  
};

const buyerCancelledAuctions = createSlice({
  name: "buyerCancelledAuctions",
  initialState,
  reducers: {
    fetchCancelledAuctions(state, action) {
      state.loading = true;
    },
    fetchCancelledAuctionsSuccess(state, action) {
      state.cancelledAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchCancelledAuctionsFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = buyerCancelledAuctions;

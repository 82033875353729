import React from "react";
import styles from "./styles.module.css";
import Accordian from "../../../components/homeApp/accordian";

export default function AgroTech() {
  return (
    <div className={styles.agroTech}>
      <h1>Agro-techniques of medicinal plants</h1>
      <Accordian title="Do's and don'ts">
        <ul>
          <li>
            <a
              href="https://echarak.in/echarak/templates/Good%20agricultural%20practices%20for%20medicinal%20plants.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Good agricultural practices for medicinal plants
            </a>
          </li>
          <li>
            <a
              href="https://echarak.in/echarak/templates/Good%20field%20collection%20practices%20for%20medicinal%20plants.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Good field collection practices for medicinal plants
            </a>
          </li>
          <li>
            <a
              href="https://echarak.in/echarak/templates/Post-harvest%20management%20of%20medicinal%20plants.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Post-harvest management of medicinal plants
            </a>
          </li>
        </ul>
      </Accordian>
    </div>
  );
}

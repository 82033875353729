import React from "react";
import styles from "./auctionCard.module.css";
import { Card } from 'react-bootstrap';

const DCard=(props)=>{
    return (
        <>
            <Card className={styles.card}>
                <Card.Body className={styles.cardBody}>
                    {props.children}
                </Card.Body>
            </Card>
        </>
    );
}
export default DCard;
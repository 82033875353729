import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

// First select the relevant part from the state

const selectAuctionDomain = (state) => state?.hederaValidation || initialState;
const selectBidsDomain = (state) => state?.hederaValidation || initialState;


export const selecthederaAuction = createSelector([selectAuctionDomain], (state) => state.hederaAuction);
export const selecthederaAuctionBids = createSelector([selectBidsDomain], (state) => state.hederaAuctionBids);




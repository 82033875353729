import styles from "./styles.module.css";
import { formattedDate,myHighestBid,formatedAuctionId } from "../../../utils/helper";

export default function HederaAuctionDetail({auction,hederaAuction,displayMode,misMatchData}) {

  let headKeysValues = [
    {key:'auctionIdForDisplay',value:'Auction ID'},
    {key:'launchDate',value:'Auction launch date'},    
    {key:'herbName',value:'Herb name'},
    {key:'herbVariety',value:'Variety'},
    {key:'totalQuantity',value:'Total quantity'},
    {key:'askedPricePerUnit',value:'Asked price/kg'}
  ];

  let dataArray=[];
  const matchDBData = (dbAuctin)=>{      
    headKeysValues.forEach(element => {
      dataArray.push(
        {
          key : element.key,
          headKey:element.value,
          dbValue: dbAuctin[element.key],   
          display:true                                    
        }
      );            
    });
  } 

  const matchHederaData = (hederaAuction) => {      
    dataArray.forEach(element => {
      element.hederaValue= hederaAuction[element.key];
      
      element.display = (displayMode === 'all') ? true : (displayMode === 'matched') ? (element.dbValue === element.hederaValue) : (element.dbValue !== element.hederaValue);     
          //Set Global misMatch flag
          if(hederaAuction && Object.keys(hederaAuction).length !== 0 && element.dbValue !== element.hederaValue){
            
            misMatchData(true);
          }      
    });
}

  if(auction){
    matchDBData(auction);  
  }

  if(hederaAuction){
    matchHederaData(hederaAuction); 
    
  }

    return (<>
        <main className={styles.mainContent}>         
                <section>
                  {auction.labReport && (
                    <span className={styles.title}>
                      3.Auction Details              
                    </span>
                  )}
                </section>
                <section className={[styles.data, "param_overview"].join(' ')}>
                  <div className={styles.dataLeft}>
                    <span className={styles.key}>Parameter</span>
                    {dataArray.map((q) => {
                      if(q.display){
                        return <span key={q.headKey}>{q.headKey}</span>;
                      }                      
                    })}
                    
                    
                  </div>
                  <div className={styles.dataRight}>
                    <span>Data</span>
                    {dataArray.map((q,index)=>{
                      if(q.display){
                       return <span key={index}>
                        {(q.key === 'askedPricePerUnit') ? '₹' : ''}
                        {
                        q.key === 'launchDate' ? formattedDate(q.dbValue) : (q.key === 'auctionIdForDisplay' ? formatedAuctionId(q.dbValue):q.dbValue) 
                        }
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.dbValue !== q.hederaValue)) ? ' | ':''
                        }  
                        {
                          (hederaAuction && Object.keys(hederaAuction).length !== 0 && (q.dbValue !== q.hederaValue)) ?  (<strong className={styles.redfont}> {(q.key === 'askedPricePerUnit') ? '₹' : ''}  {q.key === 'launchDate' ? formattedDate(q.hederaValue) : q.hederaValue } </strong>):''
                        }                        
                        </span>;
                      }                      
                    })
                  }                   
                    
                  </div>
                </section>
              </main>
        </>);
}



import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  MandiList:{links:[],totalRecords:0},
};

const MandiList = createSlice({
  name: "MandiList",
  initialState,
  reducers: {
    fetchMandis(state, action) {
      state.loading = true;
    },
    fetchMandisSuccess(state, action) {
      state.MandiList = action.payload; 
      state.loading = false;
    },
    fetchMandisFailure(state, action) {
      state.loading = false;
    },
    removeMandi(state, action) {
      state.loading = true;
    },
    removeMandiSuccess(state, action) {
      // state.Mandi = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    removeMandiFailure(state, action) {
      state.loading = false;
    },
  },
 
});

export const { actions, reducer, name: sliceKey } = MandiList;

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MainDashboardLayout from "../../components/dashboardApp/layout";
import { Routes, Route } from "react-router-dom";
import routes from "../../../routes";
import Dashboard from "./dashboard";
import LiveAuction from "./liveAuction";
import InreviewAuction from "./InreviewAuction";
import RejectedAuction from "./rejectauction";
import AwardedAuction from "./awardedAuction";
import CancelledAuction from "./cancelledAuction";
import PendingAward from "./pendingAward";
import AuctionDetail from "./auctionDetail";

//import DraftAuction from "./draftAuction";
import DraftedAuction from "./draftedAuction";
import DraftCreateAuction from "./draftCreateAuction";
import CreateAuction from "./createAuction";
import MyProfile from "./myProfile";

const SellerDashboardApp = () => {
  return (
    <>
      <MainDashboardLayout>
        {/* <Dashboard /> */}
        {/* <LiveAuction /> */}
        {/* <InreviewAuction /> */}
        {/* <AwardedAuction /> */}
        {/* <CancelledAuction /> */}
        {/* <PendingAward /> */}
        <Routes>
          {" "}
          {/* seller */}
          <Route exact path="/landing" element={<Dashboard />} />
          <Route exact path="/pending-awards" element={<PendingAward />} />
          <Route exact path="/pending-awards/auction-detail" element={<AuctionDetail />}/>
          <Route exact path="/live-auctions" element={<LiveAuction />} />
          <Route exact path="/live-auctions/auction-detail" element={<AuctionDetail />}/>
          <Route exact path="/draft-auctions" element={<DraftedAuction />} />
          <Route
            exact
            path="/inreview-auctions"
            element={<InreviewAuction />}
          />
          <Route
            exact
            path="/reject-auctions"
            element={<RejectedAuction/>}
          />
          <Route exact path="/inreview-auctions/auction-detail" element={<AuctionDetail />}/>
          <Route exact path="/reject-auctions/auction-detail" element={<AuctionDetail />}/>
          <Route exact path="/awarded-auctions" element={<AwardedAuction />} />
          <Route exact path="/awarded-auctions/auction-detail" element={<AuctionDetail />}/>
          <Route
            exact
            path="/cancelled-auctions"
            element={<CancelledAuction />}
          />
          <Route exact path="/cancelled-auctions/auction-detail" element={<AuctionDetail />}/>
          <Route
            exact
            path="/live-auctions/auction-detail"
            element={<AuctionDetail />}
          />
          <Route exact path="/draft-auctions/update" element={<DraftCreateAuction />} />
          <Route exact path="/create-auction" element={<CreateAuction />} />
          <Route
            exact
            path="/live-auctions/auction-detail"
            element={<AuctionDetail />}
          />
          <Route exact path="/profile" element={<MyProfile />} />
        </Routes>
      </MainDashboardLayout>
    </>
  );
};

export default SellerDashboardApp;

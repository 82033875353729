import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import DashboardHeader from "../layout/header";
import BuyerDashboardSidebar from "../layout/sidebar";
import SellerDashboardSidebar from "../layout/sellerSidebar";
import AdminDashboardSidebar from "./adminSidebar";
import { useAuth } from "../../../contexts/auth";

const MainDashboardLayout = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const auth = useAuth();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const sideBarRef = useRef(null);

  const handleClickOutside = (event) => {
    const BrowserWidth= window.innerWidth;
    if (sideBarRef.current && !sideBarRef.current.contains(event.target) && BrowserWidth<991) {
      toggleSidebar()
    }
  };
  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const contentAreaStyles = {
    padding: "25px",
    marginLeft: isOpen ? "250px" : "0",
    transition: "all 0.3s ease-out",
    backgroundColor: "#F7FAFC",
    minHeight: "100vh",
  };
  
  return (
    <main style={{marginTop:"4.5rem"}}>
      <div ref={sideBarRef}>
        {auth.loggedInUser && (
          <>
            <DashboardHeader toggleSidebar={toggleSidebar} />
            {auth.loggedInUser && auth.loggedInUser.role === "buyer" && (
              <>
                <BuyerDashboardSidebar isOpen={isOpen}  toggleSidebar={toggleSidebar} />
              </>
            )}

            {auth.loggedInUser && auth.loggedInUser.role === "seller" && (
              <>
                <SellerDashboardSidebar isOpen={isOpen}  toggleSidebar={toggleSidebar} />
              </>
            )}

            {auth.loggedInUser && auth.loggedInUser.role === "super_admin" && (
              <>
                <AdminDashboardSidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
              </>
            )}
          </>
        )}
      </div>

      <div style={contentAreaStyles}>{props.children}</div>
    </main>
  );
};

export default MainDashboardLayout;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  adminAuctionDetail:{},
};

const auctionDetailForAdmin = createSlice({
  name: "auctionDetailForAdmin",
  initialState,
  reducers: {
    fetchAuctionById(state, action) {
      state.loading = true;
    },
    fetchAuctionByIdSuccess(state, action) {
      state.adminAuctionDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchAuctionByIdFailure(state, action) {
      state.loading = false;
    },
    updateAuctionStatus(state, action) {
      state.loading = true;
    },
    updateAuctionStatusSuccess(state, action) {
      state.userDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    updateAuctionStatusFailure(state, action) {
      state.loading = false;
    },
    rejectAuctionStatus(state, action) {
      state.loading = true;
    },
    rejectAuctionStatusSuccess(state, action) {
      state.userDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    rejectAuctionStatusFailure(state, action) {
      state.loading = false;
    },
    cancelAuctionStatus(state, action) {
      state.loading = true;
    },
    cancelAuctionStatusSuccess(state, action) {
      state.userDetail = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    cancelAuctionStatusFailure(state, action) {
      state.loading = false;
    }

  },
});

export const { actions, reducer, name: sliceKey } = auctionDetailForAdmin;

import React, { useCallback, useEffect, useState } from "react";
import backwardImage from "../../../../assets/icons/backward.svg";
import forwardImage from "../../../../assets/icons/forward.svg";
import "./styles.css";

export default function LandingCarousel({ carouselItems }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNextIndex = useCallback(() => {
    setActiveIndex(activeIndex + 1);
  }, [activeIndex]);

  const handlePrevIndex = useCallback(() => {
    console.log(activeIndex);
    setActiveIndex(activeIndex - 1);
  }, [activeIndex]);

  useEffect(() => {
    if (activeIndex === carouselItems.length - 2) {
      carouselItems.push(...carouselItems);
    }
  }, [carouselItems, activeIndex]);

  useEffect(() => {
    const newTimer = setInterval(() => {
      handleNextIndex();
    }, 4000);
    return () => {
      clearInterval(newTimer);
    };
  }, [handleNextIndex]);

  return (
    <div className="carousel">
      {carouselItems.map((item, index) => (
        <section
          key={index}
          className="main"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, .6),rgba(0, 0, 0, 0.6)), url(${item.image})`,
            transform: `translateX(${
              activeIndex ? `-${activeIndex * 100}%` : "0"
            })`,
          }}
        >
          <div className="mainText">
            <h1>{item.heading}</h1>
            <p>{item.subHeading}</p>
          </div>
        </section>
      ))}
      {activeIndex !== 0 ? (
        <button className="buttonLeft" onClick={handlePrevIndex}>
          <img src={backwardImage} alt="" />
        </button>
      ) : null}
      <button className="buttonRight" onClick={handleNextIndex}>
        <img src={forwardImage} alt="" />
      </button>
    </div>
  );
}

import { include } from "named-urls";

//Need to be improved as nested routing for dashboard is not working
const routes = {
  home: include("/", {
    disclaimer:"disclaimer/",
    privacypolicy:"privacypolicy/",
    landing: "",

    auctions: include("auctions/", {
      allAuctions: "",
      auctionDetails: "auctionDetail/:id",
    }),
contact:"contact/",

mandi:"mandi/",

    about: "about/",

    knowMore: include("knowMore/", {
      about: "aboutMedicinalPlants/",
      agroTech: "agroTech/",
    }),

    login: "login/",
    register: "register/",
    pendingPayment: "pendingPayment/",
  }),
  adminLogin: "adminlogin/",
  dashboard: include("/dashboard/", {
    landing: "",
    profile: "profile/",
    buyer: include("buyer/", {
      home: "*", 
      landing: "landing",
      profile: "profile",
      pendingAwards: "pending-awards",
      liveAuctions: include("live-auctions/", {
        auctionDetail: "/auction-detail/:id",
      }),
      wonAuctions: include("won-auctions/", {
        auctionDetail: "/auction-detail/:id",
      }),
      //wonAuctions: "won-auctions",
      lostAuctions: "lost-auctions",
      cancelledAwards: "cancelled-awards",
    }),
    seller: include("seller/", {
      home: "*",
      landing: "landing",
      profile: "profile",
      liveAuctions: "live-auctions",
      wonAuctions: "won-auctions",
      lostAuctions: "lost-auctions",
      pendingAwards: "pending-awards",
      cancelledAwards: "cancelled-awards",
      draftAwards: "draft-auctions",
    }),
    admin: include("admin/", {
      landing: "",
          // i added this route for mandi
    mandi:"mandi/",
    mandiAdd:"mandi/add/",
    mandiEdit:"mandi/edit/:id",
      profile: "profile/",
      auctions: "auctions/",
      auctionDetails: "auctions/:auctionId",
      suppliers: "suppliers/",
      buyers: "buyers/",
      supplierDetail: "suppliers/:supplierId/",
      buyerDetail: "buyers/:buyerId",
      links: "links/",
      linkEdit: "links/edit/:id",
      linkAdd: "links/add",
    }),
  }),
};

export default routes;

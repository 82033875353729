import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import Button from "../../../components/shared/button";
import OtpComponent from "./OtpContents";
import otpVerifiedIcon from "../../../../assets/icons/otp-verified.svg";
import { useNavigate } from "react-router";
import { useAuth } from "../../../contexts/auth";
import { toastError } from "../../../utils/toastHelper";
import * as selectors from "../../../contexts/auth/selectors";
import PreLoader from "../../../components/shared/preLoader";
import routes from "../../../../routes";

const buttonStyle = {
  width: "100%",
};

export default function Login() {
  const auth = useAuth();

  const [mobile, setMobile] = useState("");
  const [otpButtonClicked, setOtpButtonClicked] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const navigate = useNavigate();

  const {
    generateLoginOtp,
    validateOtp,
    otpLoginInProgress,
    loggedInUser,
  } = auth;

  const generateOtpInProgress = useSelector(
    selectors.selectGenerateOtpInProgress
  );

  useEffect(() => {
    if (loggedInUser) {
      navigate(routes.home.landing);
    }
  }, [loggedInUser, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("clicked");
  };

  const mobileChange = (event) => {
    setMobile(event.target.value);
  };

  const onBackButtonClick = () => {
    setOtpButtonClicked(false);
  };

  const onVerifyButtonClick = (requestedOtp) => {
    const onSuccess = () => {
      setOtpVerified(true);
    };
    validateOtp(mobile, requestedOtp, "purpose_login", onSuccess);
  };

  const onClickOtpButton = (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(mobile)) {
      toastError("Invalid Mobile Number");
      return;
    }
    const onSuccess = () => {
      //setOtpButtonClicked(!otpButtonClicked);
      setOtpButtonClicked(true);
    };

    generateLoginOtp(mobile, "purpose_login", onSuccess);
  };

  function validatePhoneNumber(input_str) {
    var re = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

    return re.test(input_str);
  }

  useEffect(() => {
    if (otpVerified) {
      const timer = setTimeout(() => {
        setOtpVerified(false);
        navigate("/");
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [otpVerified, navigate]);

  return (
    <>
      {generateOtpInProgress || otpLoginInProgress ? <PreLoader /> : <></>}
      <div className={styles.login}>
        {!otpButtonClicked && !otpVerified && (
          <>
            {" "}
            <h1>Login</h1>
            <div className={styles.formContainer}>
              <h2>Login into your account</h2>
              <form>
                <div>
                  <label htmlFor="mobile">
                    Enter 10 digits registered mobile number
                  </label>
                  <input
                    type="number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    name="mobile"
                    placeholder="943042XXXX"
                    maxLength={10}
                    minLength={10}
                  />
                </div>
                {!otpLoginInProgress && (
                  <Button
                    solid={true}
                    text="Login"
                    type="submit"
                    buttonStyle={buttonStyle}
                    buttonClick={onClickOtpButton}
                  />
                )}
                {otpLoginInProgress && (
                  <Button
                    solid={true}
                    disable={true}
                    text="Sending OTP..."
                    type="submit"
                    buttonStyle={buttonStyle}
                  />
                )}
              </form>
            </div>
          </>
        )}

        {otpButtonClicked && !otpVerified && (
          <OtpComponent
            onBackButtonClick={onBackButtonClick}
            onVerifyButtonClick={onVerifyButtonClick}
            onSendOTP={onClickOtpButton}
          />
        )}
        {otpVerified && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "180px",
              }}
            >
              <img src={otpVerifiedIcon} alt="verifiedIcon" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
              className={styles.accountDetails}
            >
              OTP verified successfully!
            </div>
          </>
        )}
      </div>
    </>
  );
}

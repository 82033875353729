import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchLinkById({ payload }) {
  try {
    const res = yield call(API.fetchLinkData, payload);    
    const parsedObject = JSON.parse(res.data);    
   
    const { body } = parsedObject;
    yield put(actions.fetchLinkByIdSuccess(body));
  } catch (err) {
    yield put(actions.fetchLinkByIdFailure(err?.response?.data));
  }
}


export function* handleCreateLink({ payload }) {
  try {
    const res = yield call(API.createLinkData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { body } = parsedObject;
    yield put(actions.createLinkSuccess(body));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.createLinkFailure(err?.response?.data));
  }
}

export function* handleUpdateLink({ payload }) {
  try {
   
    const res = yield call(API.updateLinkData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { body } = parsedObject;
    yield put(actions.updateLinkSuccess(body));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.updateLinkFailure(err?.response?.data));
  }
}

export function* handleDeleteLink({ payload }) {
  try {
    const res = yield call(API.removeLinkData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { data } = parsedObject;
    yield put(actions.removeLinkSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.removeLinkFailure(err?.response?.data));
  }
}





/**
 * Root saga manages watcher lifecycle
 */
export function* LinksSaga() {
  yield takeLatest(actions.fetchLinkById.type, handleFetchLinkById);
  yield takeLatest(actions.createLink.type, handleCreateLink);
  yield takeLatest(actions.updateLink.type, handleUpdateLink);
  yield takeLatest(actions.removeLink.type, handleDeleteLink);
}

import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchAuctionDetail({ payload }) {
  try {
    const res = yield call(API.fetchUserAuctionDataDetail, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchAuctionByIdSuccess(data));
  } catch (err) {
    yield put(actions.fetchAuctionByIdFailure(err?.response?.data));
  }
}

export function* handleUpdateAcutionStatus({ payload }) {
  try {
    const res = yield call(API.updateAdminAuctionStatusData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.updateAuctionStatusSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.updateAuctionStatusFailure(err?.response?.data));
  }
}

export function* handleRejectAcutionStatus({ payload }) {
  try {
    const res = yield call(API.rejectAuctionStatus, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.rejectAuctionStatusSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.rejectAuctionStatusFailure(err?.response?.data));
  }
}

export function* handleCancleAcutionStatus({ payload }) {
  try {
    const res = yield call(API.cancelAuctionStatus, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.cancelAuctionStatusSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.cancelAuctionStatusFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* auctionDetailSaga() {
  yield takeLatest(actions.fetchAuctionById.type, handleFetchAuctionDetail);
  yield takeLatest(actions.updateAuctionStatus.type, handleUpdateAcutionStatus);
  yield takeLatest(actions.rejectAuctionStatus.type, handleRejectAcutionStatus);
  yield takeLatest(actions.cancelAuctionStatus.type, handleCancleAcutionStatus);
  
}

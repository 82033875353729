import React, { useState } from "react";
import styles from "./styles.module.css";
import { useAuth } from "../../../contexts/auth";
import MyBids from "../myBids";
import {
  filterMyBids,
  formatDateForAuctionDetail,
} from "../../../utils/helper";

const tabs = ["My bids", "Description"];

const activeLinkStyle = {
  color: "#25855A",
  borderColor: "#25855A",
};

export default function AuctionViewTab({ auction }) {
  let { auctionDetail, highestBidAmount, userHighestBidAmount } = auction;
  const [tab, setTab] = useState(0);

  const auth = useAuth();
  const myBids = auth.loggedInUser
    ? filterMyBids(auctionDetail.bids, auth.loggedInUser._id)
    : [];
  const handleTabClick = (tabIndex) => {
    setTab(tabIndex);
  };

  console.log(auctionDetail);

  return (
    <section className={styles.auctionViewTab}>
      <section className={styles.tabContainer}>
        {tabs.map((tabText, index) => (
          <button
            style={tab === index ? activeLinkStyle : null}
            className={styles.tabButton}
            key={tabText}
            onClick={() => handleTabClick(index)}
          >
            {tabText}
          </button>
        ))}
      </section>
      <section className={styles.auctionDetails}>
        {tab === 0 ? (
          <>{auth.loggedInUser && <MyBids bids={myBids} />}</>
        ) : null}
        {auth.loggedInUser &&tab === 1 ? (
          <>
            <div className={styles.description}>
              <h3>About auction</h3>
              <div className={styles.collection}>
                <span className={styles.key}>
                  ID:{" "}
                  <span className={styles.value}>
                    {auctionDetail._id && auctionDetail._id}
                  </span>
                </span>
                <span className={styles.key}>
                  Launch date:{" "}
                  <span className={styles.value}>
                    {auctionDetail.createdAt &&
                      formatDateForAuctionDetail(auctionDetail.createdAt)}
                  </span>
                </span>
              </div>
              <h3>Grade description</h3>
              <p>{auctionDetail.gradeDescription}</p>
              <h3>Method of collection</h3>
              <div className={styles.badge}>
                <span>
                  {auctionDetail.collectionMethod &&
                    auctionDetail.collectionMethod[0] &&
                    auctionDetail.collectionMethod[0].type}
                </span>
              </div>
              <div className={styles.method}>
                <div className={styles.methodElement}>
                  <div>
                    <span className={styles.methodHeading}>
                      Report of patwari / revenue authority / girdawari
                    </span>
                    <a
                      href={
                        auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].reportPatwari
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={styles.report}
                    >
                      download report
                    </a>
                  </div>
                  <div>
                    <span className={styles.methodHeading}>
                      Additional document
                    </span>
                    <a
                      href={
                        auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].additionalDocument
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={styles.report}
                    >
                      download report
                    </a>
                  </div>
                </div>
                <h3>Land details:</h3>
                <div className={styles.methodElement}>
                  <div>
                    <span className={styles.methodHeading}>Village</span>
                    <span className={styles.landValue}>
                      {auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].village}
                    </span>
                  </div>
                  <div>
                    <span className={styles.methodHeading}>Tehsil</span>
                    <span className={styles.landValue}>
                      {auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].tehsil}
                    </span>
                  </div>
                  <div>
                    <span className={styles.methodHeading}>State</span>
                    <span className={styles.landValue}>
                      {auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].state}
                    </span>
                  </div>
                  <div>
                    <span className={styles.methodHeading}>District</span>
                    <span className={styles.landValue}>
                      {auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].district}
                    </span>
                  </div>
                </div>
                {auctionDetail.collectionMethod &&
                auctionDetail.collectionMethod[0] &&
                auctionDetail.collectionMethod[0].legalDoc ? (
                  <div className={styles.methodElement}>
                    <div>
                      <span className={styles.methodHeading}>
                        Legal procurement certificate (LPC)
                      </span>
                      <a
                      href={
                        auctionDetail.collectionMethod &&
                        auctionDetail.collectionMethod[0] &&
                        auctionDetail.collectionMethod[0].legalDoc
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={styles.report}
                    >
                      download report
                    </a>
                    </div>
                  </div>
                ) : null}
              </div>
              <h3>Packaging</h3>
              <div className={styles.collection}>
                <span className={styles.key}>
                  Type:{" "}
                  <span className={styles.value}>
                    {auctionDetail.packagingType}
                  </span>
                </span>
                <span className={styles.key}>
                  Size:{" "}
                  <span className={styles.value}>
                    {auctionDetail.packagingSize}
                  </span>
                </span>
              </div>
            </div>
          </>
        ) : null}
      </section>
    </section>
  );
}

import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";

const backButtonStyles = {
  root: {
    width: "276px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "#FFFFFF !important",
    border: "1px solid #E2E8F0 !important",
    boxShadow: "none !important",
  },
};

const verifyButtonStyles = {
  root: {
    width: "276px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "green !important",
  },
};

const activeResendButtonStyle = {
  color: "rgba(56, 161, 105, 1)",
  cursor: "pointer",
};

function OtpComponent(props) {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const inputRefs = useRef([]);
  const [seconds, setSeconds] = useState(59);
  const [minutes, setMinutes] = useState(4);
  const [isResendActive, setIsResendActive] = useState(false);

  const CustomButton = withStyles(backButtonStyles)(({ classes, ...props }) => (
    <Button className={classes.root} {...props} />
  ));

  const CustomVerifyButton = withStyles(verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        // Timer reached 00:00, stop the timer
        clearInterval(interval);
        setIsResendActive(true);
        return;
      }

      // Update seconds and minutes
      if (seconds === 0) {
        setMinutes((prevMinutes) => prevMinutes - 1);
        setSeconds(59);
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const formattedTimer = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const handleInputChange = (event, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = event.target.value;
    setOtp(updatedOtp);
    if (event.target.value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = () => {
    // Logic to handle OTP submission
    //console.log("OTP submitted:", otp.join(""));
    props.onVerifyButtonClick(otp.join(""));
  };

  //   onChange={(event) => handleInputChange(event, index)}

  return (
    <>
      <div className={styles.enterOtpcontain}
        
      >
        <div className={styles.enterOtpText}>Enter OTP</div>
        <div className={styles.otpSentText}>
          A 6-digit OTP is sent to your number and email
        </div>
        <div className={styles.otpText}>OTP</div>
        <div className={styles.otpboxs}
          
        >
          {otp.map((value, index) => (
            <div key={index} style={{ margin: "0 5px" }}>
              <OutlinedInput className={styles.otpboxsinput}
                type="number"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                placeholder="0"
                value={value}
                onChange={(event) => handleInputChange(event, index)}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: "center",
                    width: "52px",
                    height: "48px",
                    borderRadius: "6px",
                  },
                }}
                inputRef={(el) => (inputRefs.current[index] = el)}
              />
            </div>
          ))}
        </div>
        <div className={styles.resendcodecontent} >
          <div style={{ display: "flex", gap: 8 }}>
            <div className={styles.checkText}>Didn't receive the code?</div>
            <div
              style={isResendActive ? activeResendButtonStyle : {}}
              className={styles.resendCode}
              disabled={!isResendActive}
              onClick={(e) => {
                setIsResendActive(false);
                setSeconds(59);
                setMinutes(4);
                props.onSendOTP(e);
              }}
            >
              Resend code
            </div>
            {/* <div className={styles.resendCode}><button onClick={props.onSendOTP}>Resend code</button></div> */}
          </div>
          <div className={styles.resendCode}>{formattedTimer}</div>
        </div>
        <div className={styles.otpbbut} >
          <div>
            <CustomButton onClick={props.onBackButtonClick} variant="contained">
              <div
                style={{
                  color: "#1A202C",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "28px",
                }}
              >
                Back
              </div>
            </CustomButton>
          </div>
          <div>
            <CustomVerifyButton onClick={handleSubmit}>
              <div
                style={{
                  color: "#FFFFFF",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "28px",
                }}
              >
                Verify OTP
              </div>
            </CustomVerifyButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtpComponent;

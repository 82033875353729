import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";

export function* handleAuctionCount({ payload }) {
  try {
    const res = yield call(API.fetchBuyerAuctionCountData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchAuctionCountSuccess(data));
  } catch (err) {
    yield put(actions.fetchAuctionCountFailure(err?.response?.data));
  }
}

export function* handleFetchWonLost({ payload }) {
  try {
    const res = yield call(API.fetchBuyerWonLostData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchWonLostSuccess(data));
  } catch (err) {
    yield put(actions.fetchWonLostFailure(err?.response?.data));
  }
}

// export function* handleDownload({ payload }) {
//   try {
//     const { fileName } = payload.data;
//     const res = yield call(API.getDownloadUrl, payload);
//     const { data } = res;
//     const file = yield call(API.downloadFile, {
//       url: data.data.document,
//       data: "",
//     });
//     const link = document.createElement("a");
//     link.href = window.URL.createObjectURL(file.data);
//     link.setAttribute(
//       "download",
//       fileName.split("_")[fileName.split("_").length - 1]
//     );
//     link.setAttribute("target", "_blank");
//     document.body.appendChild(link);
//     link.dispatchEvent(
//       new MouseEvent("click", {
//         bubbles: true,
//         cancelable: true,
//         view: window,
//       })
//     );
//     document.body.removeChild(link);
//     yield put(actions.downloadDocumentSuccess({ id: payload.id }));
//   } catch (err) {
//     yield put(actions.downloadDocumentFailure({ id: payload.id, err }));
//   }
// }

/**
 * Root saga manages watcher lifecycle
 */
export function* labListingSaga() {
  yield takeLatest(actions.fetchAuctionCount.type, handleAuctionCount);
  yield takeLatest(actions.fetchWonLost.type, handleFetchWonLost);
  //yield takeEvery(actions.downloadDocument.type, handleDownload);
}

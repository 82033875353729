import React,{useEffect,useState} from "react";
import styles from "./styles.module.css";
import backIcon from "../../../assets/icons/backward.svg";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions } from "./slice";
import { hederaDataSaga } from "./saga";
import * as selectors from "./selectors";
import ValidateHederaQuality from "./quality";
import ValidateHederaBids from "./bids";
import HederaAuctionOverview from "./overview";
import HederaAuctionDetail from "./detail";
import HederaAuctionTraceability from "./traceability";
import Button from "../../components/shared/button";
import {formatedAuctionId } from "../../utils/helper";
import { toastWarning } from "../../utils/toastHelper";


const activeButtonStyle = {
  padding: "0px 16px",
  border: "1px solid #444",
  color: "#444",
  borderRadius: "12px"
}

const buttonStyle = {
  padding: "0px 16px",
  border: "1px solid #CCC",
  color: "#CCC",
  borderRadius: "12px"
}

const verifyButtonStyle = {
  padding: "0px 16px",
  borderRadius: "12px"
}

export default function ValidateAuctionDetails({ close, auction }) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: hederaDataSaga });
  const showModes ={All:'all',Matched:'matched',UnMatched:'unmatched'};
  const [displayMode,setDisplayMode] = useState(showModes.All);
  const [displayMisMatch,setMisMatchData] = useState(false);
  const dispatch = useDispatch();
  const hederaAuction = useSelector(selectors.selecthederaAuction) || {};
  const hederaAuctionBids = useSelector(selectors.selecthederaAuctionBids) || {};

  useEffect(() => {
    
    if(auction.hederaValidationDate){
      validateAuctionFromHederaHistory();
    } 
    else{
      toastWarning('This record is not verified, please click on verify button.');
    }
    return () => {
      dispatch(actions.clearStore());
    }   
  },[auction]);

  

  

  const validateAuctionFromHederaHistory = ()=>{
    dispatch(actions.fetchHederaHistoryAuction(auction._id));
      dispatch(actions.fetchHederaHistoryAuctionBids(auction._id));
  }

  const validateAuctionFromHedera = ()=>{
    dispatch(actions.fetchHederaAuction(auction._id));
    dispatch(actions.fetchHederaAuctionBids(auction._id));
  }

  const handleShowModes = (mode)=>{    
    setDisplayMode(mode);
  }

  
  
  

  return (
    
    <div>
      <div className={styles.header}>
        <div className={styles.left}>
          <button onClick={close}>
            <img src={backIcon} style={{ height: "16px" }} alt="go back" />
          </button>
          <h2>Verification Report {formatedAuctionId(auction.auctionIdForDisplay)}</h2>          
        </div>
        
      </div>  
      {displayMisMatch && <div className={styles.hederaMessagePopup}>
        <div className={styles.hederaMessagePopup_first}></div>
        <div className={styles.hederaMessagePopup_second}>Your data seems mismatched on</div>
        <div className={styles.hederaMessagePopup_third}></div>
        <div className={styles.hederaMessagePopup_fourth}> Hedera</div>
      </div>}

      <div className={styles.buttonContainer}>          
          <Button type="button" buttonStyle={displayMode === showModes.All ? activeButtonStyle : buttonStyle}  text="All" outline={true} buttonClick={e=> handleShowModes(showModes.All)}/>
          <Button type="button" buttonStyle={displayMode === showModes.Matched? activeButtonStyle : buttonStyle}  text="Match" outline={true} buttonClick={e=> handleShowModes(showModes.Matched)}/>
          <Button type="button" buttonStyle={displayMode === showModes.UnMatched? activeButtonStyle : buttonStyle} text="Mis Match" outline={true} buttonClick={e=> handleShowModes(showModes.UnMatched)}/>
          <Button type="button" buttonStyle={verifyButtonStyle} text="Verify" solid={true} buttonClick={e=> validateAuctionFromHedera()}/>          
        </div>

        <div className={styles.dataTypeLabelContainer}>          
          
           <div style={{display: "flex", alignItems: "center", gap: "6px"}}> <div className={styles.localDataLabel}></div><div>Local Data</div></div>
           <div style={{display: "flex", alignItems: "center", gap: "6px"}}> <div className={styles.hederaDataLabel}></div><div>Hedera Data</div></div>
          
        </div>
      {
        auction && 
        <HederaAuctionOverview auction={auction} hederaAuction={hederaAuction} hederaAuctionBids={hederaAuctionBids} displayMode={displayMode} misMatchData={setMisMatchData}>

        </HederaAuctionOverview>
      }

       { auction &&
        <ValidateHederaBids auction={auction} hederaAuction={hederaAuction} hederaAuctionBids={hederaAuctionBids} displayMode={displayMode} misMatchData={setMisMatchData}>

        </ValidateHederaBids>
      }

      {
        auction && 
        <HederaAuctionDetail auction={auction} hederaAuction={hederaAuction} displayMode={displayMode} misMatchData={setMisMatchData}>

        </HederaAuctionDetail>
      }
      {
        auction && 
        <HederaAuctionTraceability auction={auction} hederaAuction={hederaAuction} displayMode={displayMode} misMatchData={setMisMatchData}>

        </HederaAuctionTraceability>
      } 

      {
        auction && 
        <ValidateHederaQuality auction={auction} hederaAuction={hederaAuction} displayMode={displayMode} misMatchData={setMisMatchData}>

        </ValidateHederaQuality>
      } 


      
    </div>
  );
}

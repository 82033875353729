import { Route, Routes } from "react-router-dom";
//import { Helmet } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
//import { useAuth } from "../contexts/auth";
import AuthProvider from "../contexts/auth";
import routes from "../../routes";
import LayoutHome from "../components/homeApp/layout";
import Landing from "./home/landing";
import AllAuctions from "./home/allAuctions";
import AuctionDetails from "./home/auctionDetails";
import About from "./home/about";
import AboutMedicinalPlants from "./home/aboutMedicinalPlants";
import AgroTech from "./home/agroTech";
import Login from "./auth/login";
import BuyerDashboardApp from "./buyerdashboardApp";
import SellerDashboardApp from "./sellerdashboardApp";
import HomeLogin from "./home/login";
import AdminDashboardApp from "./admindashboardApp";
import RegistrationPage from "./home/RegistrationPage";
import RegistrationSuccessfulPage from "./home/RegistrationPage/RegistrationSuccessful";
import actions from "../constants/actions";
import AuthorisedFor from "../utils/authorisedFor";
import IsAllowed from "../utils/isAllowed";
import PendingPaymentPage from "./home/pendingPayment";
import ScrollToTop from "../scrollTop";
import AdminLogin from "./adminAuth/adminLogin";
import RedirectToHome from "./redirectToHome";
import Auctions from "./admindashboardApp/auctions";
import AuctionDetail from "./admindashboardApp/auctionDetail";
import Suppliers from "./admindashboardApp/suppliers";
import UserDetail from "./admindashboardApp/userDetail";
import Buyers from "./admindashboardApp/buyers";
import Disclaimer from "./home/disclaimer";
import MainDashboardLayout from "../components/dashboardApp/layout";
import Privacypolicy from "./home/privacypolicy";
import Contact from "./home/contact";
import Links from "./admindashboardApp/links";
import LinkEdit from "./admindashboardApp/linkEdit";
import MandiAdmin from "./admindashboardApp/mandiAdmin/index";
import Mandi from "./home/mandi";
import MandiEdit from "./admindashboardApp/mandiEdit/index"

const Router = () => {
  return (
    <Routes>
      <Route
        exact
        path={routes.home.landing}
        element={
          <LayoutHome>
            <Landing />
          </LayoutHome>
        }
      />
      <Route
        exact
        path={routes.home.disclaimer}
        element={
          <LayoutHome>
            <Disclaimer/>
          </LayoutHome>
        }
      />
      <Route
        exact
        path={routes.home.privacypolicy}
        element={
          <LayoutHome>
            <Privacypolicy/>
          </LayoutHome>
        }
      />
      <Route
        exact
        path={routes.home.auctions.allAuctions}
        element={
          <LayoutHome>
            <AllAuctions />
          </LayoutHome>
        }
      />
      <Route
        exact
        path={routes.home.auctions.auctionDetails}
        element={
          <LayoutHome>
            <AuctionDetails />
          </LayoutHome>
        }
      />
      <Route
        exact
        path={routes.home.about}
        element={
          <LayoutHome>
            <About />
          </LayoutHome>
        }
      />
{/* NEW ROUTE FOR CONTACT */}
<Route
        exact
        path={routes.home.contact}
        element={
          <LayoutHome>
            <Contact />
          </LayoutHome>
        }
      />

<Route
        exact
        path={routes.home.mandi}
        element={
          <LayoutHome>
            <Mandi />
          </LayoutHome>
        }
      />
      <Route
        exact
        path={routes.home.knowMore.about}
        element={
          <LayoutHome>
            <AboutMedicinalPlants />
          </LayoutHome>
        }
      />
      <Route
        exact
        path={routes.home.knowMore.agroTech}
        element={
          <LayoutHome>
            <AgroTech />
          </LayoutHome>
        }
      />
      {/* NEW ROUTE FOR CONTACT */}


      <Route
        exact
        path={routes.home.pendingPayment}
        element={
          <AuthorisedFor
            action={actions.ACCESS_PORTAL_NOT_ALLOWED}
            yes={
              <LayoutHome>
                <PendingPaymentPage />
              </LayoutHome>
            }
            no={<RedirectToHome />}
          />
        }
      />
      {/* Auth Routes */}

      {/* <Route
        exact
        path="/templogin"
        element={
          <LayoutHome>
            <HomeLogin />
          </LayoutHome>
        }
      /> */}

      <Route
        exact
        path={routes.home.login}
        element={
          <LayoutHome>
            <Login />
          </LayoutHome>
        }
      />

      <Route
        exact
        path={routes.home.register}
        element={
          <LayoutHome>
            <RegistrationPage />
          </LayoutHome>
        }
      />
      <Route
        exact
        path={routes.adminLogin}
        element={
          <LayoutHome>
            <AdminLogin />
          </LayoutHome>
        }
      />
      {/* dashboard routes */}

      <Route
        exact
        path={routes.dashboard.buyer.home}
        element={
          <AuthorisedFor
            action={actions.ACCESS_BUYER_DASHBOARD_PAGE}
            yes={<BuyerDashboardApp />}
            no={<RedirectToHome />}
          />
          // <BuyerDashboardApp />
        }
      />
      <Route
        exact
        path={routes.dashboard.seller.home}
        element={
          <AuthorisedFor
            action={actions.ACCESS_SELLER_DASHBOARD_PAGE}
            yes={<SellerDashboardApp />}
            no={<RedirectToHome />}
          />
          // <SellerDashboardApp />
        }
      />

      {/* admin dashboard */}
      {/* <Route
        exact
        path={routes.dashboard.admin.home}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={<AdminDashboardApp />}
            no={<RedirectToHome />}
          />
          // <AdminDashboardApp />
        }
      /> */}
{/* i added this route for mandi in admin dashboard */}
      <Route
        exact
        path={routes.dashboard.admin.mandi}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <MandiAdmin />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />

{routes.dashboard.admin.mandiAdd}
       <Route
        exact
        path={routes.dashboard.admin.mandiAdd}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <MandiEdit />
              </MainDashboardLayout>
            }
            no={
              <p>test</p>  
                // <RedirectToHome />
              }
          />
        }
      />
      <Route
        exact
        path={routes.dashboard.admin.mandiEdit}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <MandiEdit />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />

      <Route
        exact
        path={routes.dashboard.admin.auctions}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <Auctions />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />
      <Route
        exact
        path={routes.dashboard.admin.auctionDetails}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <AuctionDetail />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />
      <Route
        exact
        path={routes.dashboard.admin.suppliers}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <Suppliers />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />
      <Route
        exact
        path={routes.dashboard.admin.supplierDetail}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <UserDetail />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />
      <Route
        exact
        path={routes.dashboard.admin.buyers}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <Buyers />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />
      <Route
        exact
        path={routes.dashboard.admin.buyerDetail}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <UserDetail />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />
 {/* links */}

 <Route
        exact
        path={routes.dashboard.admin.links}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <Links />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />
      {routes.dashboard.admin.linkAdd}
       <Route
        exact
        path={routes.dashboard.admin.linkAdd}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <LinkEdit />
              </MainDashboardLayout>
            }
            no={
              <p>test</p>  
                // <RedirectToHome />
              }
          />
        }
      />
      <Route
        exact
        path={routes.dashboard.admin.linkEdit}
        element={
          <AuthorisedFor
            action={actions.ACCESS_ADMIN_DASHBOARD_PAGE}
            yes={
              <MainDashboardLayout>
                <LinkEdit />
              </MainDashboardLayout>
            }
            no={<RedirectToHome />}
          />
        }
      />
      {/* <Route
        exact
        path={routes.dashboard.buyer.home}
        element={<BuyerDashboardApp />}
      />
      <Route
        exact
        path={routes.dashboard.seller.home}
        element={<SellerDashboardApp />}
      />
      <Route
        exact
        path={routes.dashboard.admin.home}
        element={<AdminDashboardApp />}
      /> */}
      <Route exact path="*" element={<RedirectToHome />} />
    </Routes>
  );
};

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;

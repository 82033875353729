import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.allRecentAuctions || initialState;

export const selectrecentauctions = createSelector([selectDomain], (state) => state.recentAuctions);

export const loading = createSelector([selectDomain], (state) => state.loading);


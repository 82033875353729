import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  awardedAuctions:{auctions:[],totalRecords:0},
};

const awardedLiveAuctions = createSlice({
  name: "sellerAwardedAuctions",
  initialState,
  reducers: {
    fetchAwaredAuctions(state, action) {
      state.loading = true;
    },
    fetchAwaredAuctionsSuccess(state, action) {
      state.awardedAuctions = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchAwaredAuctionsFailure(state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = awardedLiveAuctions;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";
import AccountDetail from "./AccountDetail";
import Personaldetail from "./PersonalDetail";
import LegalDoc from "./LegalDoc";
import AddressDetail from "./AddressDetail";
import BankDetail from "./BankDetail";
import styles from "./style.module.css";
import { myProfileSaga } from "./saga";
import { sliceKey, reducer, actions } from "./slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
// import * as selectors from "./selectors";
import { useAuth } from "../../../contexts/auth";

const ProfileDetail = (props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myProfileSaga });
  //
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const id = auth && auth.loggedInUser && auth.loggedInUser._id;
    if (id) {
      const payload = {
        id: id,
      };
      dispatch(actions.fetchProfileDetails(payload));
    }
  }, [dispatch, auth]);

  return (
    <>
      <Row className={styles.rowStyle}>
        <AccountDetail role={props.role} />
      </Row>
      <Row className={styles.rowStyle}>
        <Personaldetail />
      </Row>
      <Row className={styles.rowStyle}>
        <LegalDoc />
      </Row>
      <Row className={styles.rowStyle}>
        <AddressDetail />
      </Row>
      <Row className={styles.rowStyle}>
        <BankDetail />
      </Row>
    </>
  );
};

export default ProfileDetail;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  hederaAuction:null,
  hederaAuctionBids:[],
};

const hederaValidation = createSlice({
  name: "hederaValidation",
  initialState,
  reducers: {
    fetchHederaAuction(state, action) {
      state.hederaAuction =initialState.hederaAuction;
      state.loading = true;
    },
    fetchHederaAuctionSuccess(state, action) {
      state.hederaAuction = action.payload; 
      state.loading = false;
    },
    fetchHederaAuctionFailure(state, action) {
      state.loading = false;
    },
    fetchHederaHistoryAuction(state, action) {
      state.hederaAuction =initialState.hederaAuction;
      state.loading = true;
    },
    fetchHederaHistoryAuctionSuccess(state, action) {
      state.hederaAuction = action.payload; 
      state.loading = false;
    },
    fetchHederaHistoryAuctionFailure(state, action) {
      state.loading = false;
    },
    fetchHederaAuctionBids(state, action) {
      state.hederaAuctionBids =initialState.hederaAuctionBids;
      state.loading = true;
    },
    fetchHederaAuctionBidsSuccess(state, action) {      
      state.hederaAuctionBids = action.payload; 
      state.loading = false;
    },
    fetchHederaAuctionBidsFailure(state, action) {
      state.loading = false;
    },
    fetchHederaHistoryAuctionBids(state, action) {
      state.hederaAuctionBids =initialState.hederaAuctionBids;
      state.loading = true;
    },
    fetchHederaHistoryAuctionBidsSuccess(state, action) {      
      state.hederaAuctionBids = action.payload; 
      state.loading = false;
    },
    fetchHederaHistoryAuctionBidsFailure(state, action) {
      state.loading = false;
    },
    clearStore(state,action){
    state.loading = false
    state.error = null
    state.hederaAuction = null
    state.hederaAuctionBids = []
    }
  },
});

export const { actions, reducer, name: sliceKey } = hederaValidation;

import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.linksList || initialState;

export const selectLinkList = createSelector([selectDomain], (state) => state.linkList);




import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../../services/apis";

export function* handleSaveTransactionDetails({ payload }) {
  try {
    //file upload
    const { transactionScreenshot } = payload.data;

    const urlRes = yield call(API.getFileUrl, {
      fileName: transactionScreenshot[0].name,
    });

    const url = JSON.parse(urlRes.data).data;
    const formData = new FormData();
    formData.append("file", transactionScreenshot[0]);
    const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
    if (fileUploadRes.status !== 200) {
      throw new Error("Error uploading File!");
    }
    const fileUploadedUrl = new URL(url);
    const pathName = fileUploadedUrl?.pathname.slice(1);
    const res = yield call(API.saveTransactionDetails, {
      ...payload.data,
      transactionScreenshotUrl: pathName,
    });
    let data = JSON.parse(res.data);
    yield put(actions.saveBankDetailsSuccess(data));
    console.log(payload);
    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveBankDetailsFailure(err?.response?.data));
    console.log(err);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* transactionDetailsSaga() {
  yield takeLatest(
    actions.submitPaymentProof.type,
    handleSaveTransactionDetails
  );
}

import React from "react";
import HederaBar from "../hederaBar";
import Topbar from "../topbar/Topbar";
import Footer from "../footer/index";

export default function LayoutHome({ children }) {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <HederaBar />
      <Topbar />
      <div style={{ minHeight: "calc(100vh - 400px)" }}>{children}</div>
      <Footer />
    </div>
  );
}

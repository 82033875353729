import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state

const selectDomain = (state) => state?.buyersList || initialState;

export const selectbuyerList = createSelector([selectDomain], (state) => state.buyerList);

export const getBuyerFilterStatus = createSelector([selectDomain], (state) => state.buyerBuyerFilterStatus);



import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: null,
  message: null,
  saveAuctionDetailsInProgress: false,
  saveAuctionImagesInProgress: false,
  saveAuctionCollectionDetailsInProgress: false,
  saveQualityDetailsInProgress: false,
  saveTraceabilityDetailsInProgress: false,
  removeAuctionImageInProgress: false,
  auctionId: null,
};

const auctionCreationSlice = createSlice({
  name: "draftAuctionCreation",
  initialState,
  reducers: {
    saveAuctionDetails(state, action) {
      state.saveAuctionDetailsInProgress = true;
    },
    saveAuctionDetailsSuccess(state, action) {
      state.saveAuctionDetailsInProgress = false;
      state.auctionId = action.payload?.body._id;
    },
    saveAuctionDetailsFailure(state, action) {
      state.saveAuctionDetailsInProgress = false;
    },
    saveAuctionImages(state, action) {
      state.saveAuctionImagesInProgress = true;
    },
    saveAuctionImagesSuccess(state, action) {
      state.saveAuctionImagesInProgress = false;
    },
    saveAuctionImagesFailure(state, action) {
      state.saveAuctionImagesInProgress = false;
    },
    saveAuctionCollectionDetails(state, action) {
      state.saveAuctionCollectionDetailsInProgress = true;
    },
    saveAuctionCollectionDetailsSuccess(state, action) {
      state.saveAuctionCollectionDetailsInProgress = false;
    },
    saveAuctionCollectionDetailsFailure(state, action) {
      state.saveAuctionCollectionDetailsInProgress = false;
    },
    saveQualityDetails(state, action) {
      state.saveQualityDetailsInProgress = true;
    },
    saveQualityDetailsSuccess(state, action) {
      state.saveQualityDetailsInProgress = false;
    },
    saveQualityDetailsFailure(state, action) {
      state.saveQualityDetailsInProgress = false;
    },
    saveTraceabilityDetails(state, action) {
      state.saveTraceabilityDetailsInProgress = true;
    },
    saveTracebilityDetailsSuccess(state, action) {
      state.saveTraceabilityDetailsInProgress = false;
    },
    saveTracebilityDetailsFailure(state, action) {
      state.saveTraceabilityDetailsInProgress = false;
    },
    fetchCreateAuctionDetails(state) {
      state.loading = true;
      state.error = null;
      state.auctionDetails=null;
    },
    fetchCreateDetailsSuccess(state, action) {      
      state.auctionDetails = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchCreateDetailsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    removeAuctionImage(state, action) {
      state.removeAuctionImageInProgress = true;
    },
    removeAuctionImageSuccess(state, action) {
      state.removeAuctionImageInProgress = false;
    },
    removeAuctionImageFailure(state, action) {
      state.removeAuctionImageInProgress = false;
    },
    clearStore(state,action){
      state.loading = false
      state.error = null
      state.auctionDetails=null;
      }
  },
});

export const { actions, reducer, name: sliceKey } = auctionCreationSlice;
export const { fetchCreateAuctionDetails, fetchCreateDetailsSuccess, fetchCreateDetailsFailure } = actions;
import React, { useCallback, useEffect } from "react";
import styles from "./styles.module.css";
import AuctionView from "../../../components/homeApp/auctionView";
import forwardIcon from "../../../../assets/icons/forward.svg";
import AuctionViewTab from "../../../components/homeApp/auctionViewTab";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import routes from "../../../../routes";
import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import { useAuth } from "../../../contexts/auth";

export default function AuctionDetails() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();
  const auth = useAuth();
  const detail = useSelector(selectors.homeauctiondetail) || {};
  const { id } = useParams();

  const loadAuctionDetailPage = useCallback(() => {
    setTimeout(() => {
      dispatch(actions.fetchHomeAuctionDetail(id));
    }, 1000);
  }, [dispatch, id]);

  useEffect(() => {
    if (auth.loggedInUser) {
      dispatch(actions.fetchHomeAuctionDetail(id));
    } else {
      dispatch(actions.fetchOpenHomeAuctionDetail(id));
    }
  }, [dispatch, auth, id]);

  return (
    <section className={styles.auctionDetails}>
      <section className={styles.links}>
        <Link to={routes.home.landing}>
          <span>Home</span>
        </Link>
        <img src={forwardIcon} alt="Forward Icon" />
        <Link to={routes.home.auctions.allAuctions}>
          <span>Auctions</span>
        </Link>
        <img src={forwardIcon} alt="Forward Icon" />
        <span>
          {detail && detail.auctionDetail && detail.auctionDetail.herbName}
        </span>
      </section>
      {detail && detail.auctionDetail && (
        <>
          <AuctionView loadAuction={loadAuctionDetailPage} auction={detail} />
          <AuctionViewTab auction={detail} />
        </>
      )}
    </section>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  Mandi:{},
};

const MandisForAdmin = createSlice({
  name: "MandisForAdmin",
  initialState,
  reducers: {
    resetMandi(state, action) {
      state.loading = false;
      state.error = null;
      state.Mandi = {};
    },
    fetchMandiById(state, action) {
      state.loading = true;
    },
    fetchMandiByIdSuccess(state, action) {
      state.Mandi = action.payload; 
      state.loading = false;
    },
    fetchMandiByIdFailure(state, action) {
      state.loading = false;
    },
    createMandi(state, action) {
      state.loading = true;
    },
    createMandiSuccess(state, action) {
      // state.Mandi = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    createMandiFailure(state, action) {
      state.loading = false;
    },
    updateMandi(state, action) {
      state.loading = true;
    },
    updateMandiSuccess(state, action) {
      // state.Mandi = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    updateMandiFailure(state, action) {
      state.loading = false;
    },
    removeMandi(state, action) {
      state.loading = true;
    },
    removeMandiSuccess(state, action) {
      state.Mandi = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    removeMandiFailure(state, action) {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = MandisForAdmin;

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MainDashboardLayout from "../../components/dashboardApp/layout";
import { Routes, Route } from "react-router-dom";
// import routes from "../../../routes";
import Dashboard from "./dashboard";
import LiveAuction from "./liveAuction";
import WonAuction from "./wonAuction";
import LostAuction from "./lostAuction";
import CancelledAuction from "./cancelledAuction";
import PendingAward from "./pendingAward";
import AuctionDetail from "./auctionDetail";
import MyProfile from "./myProfile";

const BuyerDashboardApp = () => {
  return (
    <>
      <MainDashboardLayout>
        <Routes>
          {/* buyer */}
          <Route exact path="/landing" element={<Dashboard />} />
          <Route exact path="/pending-awards" element={<PendingAward />} />
          <Route exact path="/live-auctions" element={<LiveAuction />} />
          <Route
            exact
            path="/live-auctions/auction-detail"
            element={<AuctionDetail />}
          />
          <Route
            exact
            path="/won-auctions/auction-detail"
            element={<AuctionDetail />}
          />
          <Route
            exact
            path="/lost-auctions/auction-detail"
            element={<AuctionDetail />}
          />
          <Route
            exact
            path="/pending-awards/auction-detail"
            element={<AuctionDetail />}
          />
          <Route
            exact
            path="/cancelled-auctions/auction-detail"
            element={<AuctionDetail />}
          />
          <Route exact path="/won-auctions" element={<WonAuction />} />
          <Route exact path="/lost-auctions" element={<LostAuction />} />
          <Route
            exact
            path="/cancelled-auctions"
            element={<CancelledAuction />}
          />
          <Route exact path="/profile" element={<MyProfile />} />
        </Routes>
      </MainDashboardLayout>
    </>
  );
};

export default BuyerDashboardApp;

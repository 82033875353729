import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchLinks({ payload }) {
  try {
    const res = yield call(API.fetchLinksListData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { body } = parsedObject;
    yield put(actions.fetchLinksSuccess(body));
  } catch (err) {
    yield put(actions.fetchLinksFailure(err?.response?.data));
  }
}


export function* handleDeleteLink({ payload }) {
  try {
    const res = yield call(API.removeLinkData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { data } = parsedObject;
    yield put(actions.removeLinkSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.removeLinkFailure(err?.response?.data));
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export function* labListingSaga() {
  yield takeLatest(actions.fetchLinks.type, handleFetchLinks);
  yield takeLatest(actions.removeLink.type, handleDeleteLink);
  
}

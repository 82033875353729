import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state) => state?.registration || initialState;

export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);

export const selectMessage = createSelector(
  [selectDomain],
  (state) => state.message
);

export const selectUserId = createSelector(
  [selectDomain],
  (state) => state.userId
);

export const countries = createSelector([selectDomain], (state) => {
  return (
    state.countries &&
    state.countries.document &&
    state.countries.document.map((country) => {
      return { label: country.name, id: country._id };
    })
  );
});

export const states = createSelector([selectDomain], (state) => {
  return (
    state.states &&
    state.states.document &&
    state.states.document.map((state) => {
      return { label: state.name, id: state._id };
    })
  );
});

export const generateOtpInProgress = createSelector(
  [selectDomain],
  (state) => state.generateOtpInProgress
);


export const verifySaveAddressDetails = createSelector(
  [selectDomain],
  (state) => state.verifySaveAddressDetails
);
export const verifySaveBankDetails = createSelector(
  [selectDomain],
  (state) => state.verifySaveBankDetails
);
export const verifySaveLegalDetails = createSelector(
  [selectDomain],
  (state) => state.verifySaveLegalDetails
);

import React from "react";
import styles from "./styles.module.css";
import { useAuth } from "../../../contexts/auth";
import { Link } from "react-router-dom";

export default function About() {
  const auth = useAuth();
  return (
    <>
      <main className={styles.about}>
        <h1>About us</h1>
        <h2>About JadiButiBazar</h2>
        <p>
          JadiButiBazar is an online auction platform that connects herb
          suppliers with potential buyers. Our mission is to provide a simple,
          efficient, and transparent way for both suppliers and buyers to engage
          in herb trade.
        </p>
        <h2>How it works</h2>
        <p>
          Suppliers can list the herbs they want to sell on our platform. Buyers
          can then browse the listings and submit bids to purchase the herbs
          they want. The supplier has the freedom to award any of the bids from
          H1-H3 after closing the auction and close the deal with the buyer of
          their choice.
        </p>
        <h2>Our mission</h2>
        <p>
          Our mission is to make the herb trade more efficient and transparent.
          We want to help suppliers get the best possible price for their herbs,
          and we want to help buyers get the herbs they need at a fair price.
        </p>
        <h2>Our values</h2>
        <p>
          We are committed to providing a safe and secure platform for all
          users. We believe that trust is essential for any successful business,
          and we are committed to building a platform that our users can trust.
        </p>
        <p>
          We are also committed to providing a platform that is accessible to
          all. We believe that everyone should have the opportunity to
          participate in the global herb market, regardless of their location or
          financial resources.
        </p>
        <h2>Benefits for suppliers</h2>
        <ul>
          <li>Sell your herbs to a wider audience</li>
          <li>Get the best possible price for your herbs</li>
          <li>Minimize the time and effort involved in selling herbs</li>
        </ul>
        <h2>Benefits for buyers</h2>
        <ul>
          <li>Access a wide variety of herbs from different suppliers</li>
          <li>Get the best possible price for herbs</li>
          <li>Save time and money by buying herbs online</li>
        </ul>
        <h2>Join the JadiButiBazar community</h2>
        <p>
          If you are a herb supplier or buyer, we encourage you to join the
          JadiButiBazar community. We are committed to providing a platform that
          benefits both suppliers and buyers.
        </p>
        <p>
          {!auth.loggedInUser && (
            <>
              <Link to={"/register?l=t"}>Register</Link>today and start trading
              herbs!
            </>
          )}
        </p>
        <h2>About RCFC</h2>
        <p>
          The Regional cum Facilitation Centres (RCFCs) established by the
          National Medicinal Plants Board (NMPB) under the Ministry of AYUSH in
          2017-18 play a crucial role in promoting and supporting the
          cultivation, conservation, and sustainable management of medicinal
          plants in their respective regions. These 7 centers established in the
          country, act as one-stop solution for all matters related to medicinal
          plants, encompassing the entire value chain from collection and
          cultivation to primary processing, value addition, marketing,
          entrepreneurship, innovations and end to end solutions. RCFCNR-1 at
          RIISM Joginder Nagar serves 7 northern region states: Himachal
          Pradesh, Haryana, Punjab, UP, Uttarakhand, Delhi & Chandigarh.
        </p>
        <ul>
          <li>
            One Stop Solution for all matters related to Medicinal Plants Sector
            in the region for the Value chain of collection cultivation to
            marketing.
          </li>
          <li>
            Supports NMPB to fulfill its mandate, Undertake research activities,
            facilitate, get engaged in setting up of Primary Processing,
            grading, marketing facilities etc. in collaboration with local
            Stakeholders/organisations of repute, Develop managerial and
            technical skill among the concerned stakeholders including growers,
            collectors and to provide strategic support by organizing trainings,
            workshops, seminars etc.
          </li>
          <li>
            Development of Agro technology of Medicinal Plants especially
            endangered and high demand species in the region with focus on
            organic cultivation and customization/ field trials of already
            developed Agro techniques.
          </li>
          <li>
            Development of Region-Specific Quality Planting Materials and to
            scientifically handle issues concerned with it.
          </li>
          <li>
            Facilitation of sale of Medicinal Plants/ produce etc. resolving
            demand supply issue etc. and also develop a database on demand,
            quantity sold and price of major species along with development of
            marketing facility in the region by setting up of minimum support
            price.
          </li>
          <li>
            Provide inputs on conservation, sustainable cultivation, and
            technology up gradation, training & research on Medicinal Plants and
            to engage Forest Departments and other concerned Departments of the
            States in those activities.
          </li>
          <li>
            Domestication of wild species which are in demand and endemic to the
            region and developing varieties thereof.
          </li>
          <li>
            To take forward initiatives on Good Agricultural Practices (GAP),
            Good Field Collection Practices (GFCP) etc. in the States concerned
            and develop species specific GAP & GFCP of the region &
            dissemination.
          </li>
          <li>
            Function as a platform for bringing together the different
            stakeholders of the medicinal plants in the States (SMPB,
            implementing agencies of Mission Scheme other institutions, and
            civil organizations involved in the development of Medicinal Plants
            Sector).
          </li>
          <li>
            Assisting the various organizations in the region for formulating
            project proposals in the priority areas identified by NMPB and also
            for giving vide coverage of the scheme with emphasis on
            identification of region specific/issues.
          </li>
          <li>
            Develop capacities of concerned stakeholders by developing and
            implementing training programmes, orientation session etc.
          </li>
          <li>
            Organise periodical meetings/ workshops/ consultation of
            stakeholders.
          </li>
          <li>
            Conduct Review, monitoring and evaluation of the projects sanctioned
            by NMPB to various organisations.
          </li>
          <li>
            Collect and maintain database of all concerned Sectors of the
            Medicinal Plants in the states and Integration of database of
            various States of the region concerned.
          </li>
          <li>
            Undertake identified research study of relevance of the region and
            other matters concerned with Medicinal Plants including
            dissemination of research findings/ new technologies in the
            Medicinal Plants sector.
          </li>
          <li>
            Develop strategy for IEC (Information, Education and Communication)
            and implement IEC activities.
          </li>
          <li>
            The incubation centre has an area of approx. 3000 sq. ft and is
            managed by the Nodal Officer Dr. Arun Chandan Regional Director of
            RCFC NR-1 Ms Avika Subba: 8968619467 is the Manager of the Centre.
          </li>
        </ul>
      </main>
    </>
  );
}

import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../../services/apis";
import * as utils from "../../../../app/utils/helper";

export function* handleSaveContactDetails({ payload }) {
  try {
    const res = yield call(API.saveContactDetails, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.saveContactDetailsSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveContactDetailsFailure(err?.response?.data));
  }
}
export function* handleSavePersonalDetails({ payload }) {
  try {
    const res = yield call(API.savePersonalDetails, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.savePersonalDetailsSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.savePersonalDetailsFailure(err?.response?.data));
  }
}
export function* handleSaveLegalDetails({ payload }) {
  try {
    //file upload

    const { aadharCertificate, gstCertificate, panCertificate } = payload.data;
    let aadharUrl = "";
    let gstUrl = "";
    let panUrl = "";

    console.log(payload);

    if (aadharCertificate) {
      const fileName = aadharCertificate[0].name;
      const fileType = aadharCertificate[0].type;
      if (fileType === "application/pdf") {
        console.log(aadharCertificate);
        const urlRes = yield call(API.getFileUrl, {
          fileName,
          fileType,
        });
        const url = JSON.parse(urlRes.data).data;
        const formData = new FormData();
        formData.append("file", aadharCertificate[0]);
        const fileUploadRes = yield call(API.uploadFile, {
          data: formData,
          url,
        });
        if (fileUploadRes.status !== 200) {
          throw new Error("Error uploading Aadhar!");
        }
        const fileUploadedUrl = new URL(url);
        aadharUrl = fileUploadedUrl?.pathname.slice(1);
        payload.data.aadharUrl = aadharUrl;
      } else {
        console.log(aadharCertificate);
        const urlRes = yield call(API.getFileUrl, {
          fileName,
          fileType,
        });
        const convertedFile = yield utils.convertFileToBase64(aadharCertificate[0]);
        const url = JSON.parse(urlRes.data).data;
        const formData = new FormData();
        formData.append("file", convertedFile);
        const fileUploadRes = yield call(API.uploadFile, {
          data: formData,
          url,
        });
        if (fileUploadRes.status !== 200) {
          throw new Error("Error uploading Aadhar!");
        }
        const fileUploadedUrl = new URL(url);
        aadharUrl = fileUploadedUrl?.pathname.slice(1);
        payload.data.aadharUrl = aadharUrl;
      }
    }

    if (gstCertificate) {
      const urlRes = yield call(API.getFileUrl, {
        fileName: gstCertificate[0].name,
        fileType: gstCertificate[0].type,
      });

      const url = JSON.parse(urlRes.data).data;
      const formData = new FormData();
      formData.append("file", gstCertificate[0]);
      const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
      if (fileUploadRes.status !== 200) {
        throw new Error("Error uploading Gst!");
      }
      const fileUploadedUrl = new URL(url);
      gstUrl = fileUploadedUrl?.pathname.slice(1);
      payload.data.gstUrl = gstUrl;
    }

    if (panCertificate) {
      const urlRes = yield call(API.getFileUrl, {
        fileName: panCertificate[0].name,
        fileType: panCertificate[0].type,
      });

      const url = JSON.parse(urlRes.data).data;
      const formData = new FormData();
      formData.append("file", panCertificate[0]);
      const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
      if (fileUploadRes.status !== 200) {
        throw new Error("Error uploading Pan!");
      }
      const fileUploadedUrl = new URL(url);
      panUrl = fileUploadedUrl?.pathname.slice(1);
      payload.data.panUrl = panUrl;
    }

    const res = yield call(API.saveLegalDetails, {
      ...payload.data,
    });
    let data = JSON.parse(res.data);
    yield put(actions.saveLegalDetailsSuccess(data));
    const fieldsToCheck = [
      "aadharNumber",
      "gst",
      "pan"
    ];

    const allFieldsFilled = fieldsToCheck.every(
      (field) => payload.data[field] && payload.data[field].trim() !== ""
    );

    if(allFieldsFilled){ 
      yield put(actions.verifyLegalDetails());
    }

    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveLegalDetailsFailure(err?.response?.data));
    console.log(err);
  }
}

/*
if (aadharCertificate) {
     
      
      const res = yield call(API.saveLegalDetails, {
        ...payload.data,
        aadharUrl: pathName,
      });
      let data = JSON.parse(res.data);
      yield put(actions.saveLegalDetailsSuccess(data));
    } else {
      const res = yield call(API.saveLegalDetails, payload.data);
      let data = JSON.parse(res.data);
      yield put(actions.saveLegalDetailsSuccess(data));
    }
*/

export function* handleSaveBankDetails({ payload }) {
  try {
    //file upload
    const { cancelledChecque } = payload.data;
    if (cancelledChecque) {
      const urlRes = yield call(API.getFileUrl, {
        fileName: cancelledChecque[0].name,
      });

      const url = JSON.parse(urlRes.data).data;
      const formData = new FormData();
      formData.append("file", cancelledChecque[0]);
      const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
      if (fileUploadRes.status !== 200) {
        throw new Error("Error uploading File!");
      }
      const fileUploadedUrl = new URL(url);
      const pathName = fileUploadedUrl?.pathname.slice(1);
      const res = yield call(API.saveBankDetails, {
        ...payload.data,
        cancelledChequeUrl: pathName,
      });
      let data = JSON.parse(res.data);
      yield put(actions.saveBankDetailsSuccess(data));
    } else {
      const res = yield call(API.saveBankDetails, payload.data);
      let data = JSON.parse(res.data);
      yield put(actions.saveBankDetailsSuccess(data));
      const fieldsToCheck = [
        "accountHolderName",
        "bankName",
        "branchName",
        "accountNumber",
        "ifsc",
      ];
  
      const allFieldsFilled = fieldsToCheck.every(
        (field) => payload.data[field] && payload.data[field].trim() !== ""
      );
      if (allFieldsFilled) {
        
        yield put(actions.verifyBankDetails());
      }

    }
    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveBankDetailsFailure(err?.response?.data));
    console.log(err);
  }
}

export function* handleSaveAddressDetails({ payload }) {
  try {
    //file upload
    const { addressProof } = payload.data;
    if (addressProof) {
      const urlRes = yield call(API.getFileUrl, {
        fileName: addressProof[0].name,
      });

      // console.log(addressProof);

      const url = JSON.parse(urlRes.data).data;
      const formData = new FormData();
      formData.append("file", addressProof[0]);
      const fileUploadRes = yield call(API.uploadFile, { data: formData, url });
      if (fileUploadRes.status !== 200) {
        throw new Error("Error uploading File!");
      }
      const fileUploadedUrl = new URL(url);
      const pathName = fileUploadedUrl?.pathname.slice(1);
      const res = yield call(API.saveAddressDetails, {
        ...payload.data,
        addressProofUrl: pathName,
      });
      let data = JSON.parse(res.data);
      yield put(actions.saveAddressDetailsSuccess(data));
    } else {
      const res = yield call(API.saveAddressDetails, payload.data);
      let data = JSON.parse(res.data);
      yield put(actions.saveAddressDetailsSuccess(data));
    }
    const fieldsToCheck = [
      "address",
      "state",
      "district",
      "pincode",
    ];
    const allFieldsFilled = fieldsToCheck.every(
      (field) => payload.data[field] && payload.data[field].trim() !== ""
    );
    if (allFieldsFilled) {
      yield put(actions.verifyAddressDetails());

    }

    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveAddressDetailsFailure(err?.response?.data));
  }
}

export function* handleGenerateOtp({ payload }) {
  try {
    const res = yield call(API.generateOtp, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.generateOtpSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.generateOtpFailure(err?.response?.data));
  }
}
export function* handleValidateOtp({ payload }) {
  try {
    const res = yield call(API.validateOtp, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.validateOtpSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.validateOtpFailure(err?.response?.data));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* registrationSaga() {
  yield takeLatest(actions.saveContactDetails.type, handleSaveContactDetails);
  yield takeLatest(actions.savePersonalDetails.type, handleSavePersonalDetails);
  yield takeLatest(actions.saveAddressDetails.type, handleSaveAddressDetails);
  yield takeLatest(actions.saveLegalDetails.type, handleSaveLegalDetails);
  yield takeLatest(actions.saveBankDetails.type, handleSaveBankDetails);
  yield takeLatest(actions.generateOtp.type, handleGenerateOtp);
  yield takeLatest(actions.validateOtp.type, handleValidateOtp);
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#38A169",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#fff",
    },
  }));

export default function PreLoader() {
  return (
    <Box sx={{ width: '100%' }}>
      <BorderLinearProgress />
    </Box>
  );
}
import React, { useEffect, useRef, useState } from 'react';
import { Nav } from 'react-bootstrap';
import SImage from '../../../shared/Image';
import styles from './sidebar.module.css';
import dasboardImg from '../../../../../assets/dashboardImages/dashboard.svg';
import proImg from '../../../../../assets/dashboardImages/profile.svg';
import liveImg from '../../../../../assets/dashboardImages/live.svg';
import closeImg from '../../../../../assets/dashboardImages/close.svg';
import downImg from '../../../../../assets/dashboardImages/downa.svg';
import cancelImg from '../../../../../assets/dashboardImages/cancel.svg';
import { Link } from 'react-router-dom';

const DashboardSidebar = ({ isOpen, toggleSidebar }) => {
  const [showDropdown, setShowDropdown] = useState(false); //



  const handleNavClick = () => {
    const BrowserWidth = window.innerWidth;
    if (BrowserWidth < 991) {
      toggleSidebar();
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown menu
  };

  const sidebarStyles = {
    height: '100vh',
    width: '250px',
    backgroundColor: '#171923',
    position: 'fixed',
    top: '0',
    left: isOpen ? '0' : '-250px',
    transition: 'all 0.3s ease-out',
    padding: '20px',
    paddingTop: '6em',
    zIndex: '0'
  };

  const location = window.location.pathname;

  return (
    <div  style={sidebarStyles} className={`userDashboard`}>
      <Nav defaultActiveKey="/dashboard/buyer/landing" className={[styles.sidenav, "flex-column"].join(' ')}>
        <Link onClick={handleNavClick} className={location === "/dashboard/buyer/landing" ? 'nav-link active' : 'nav-link'} to="/dashboard/buyer/landing">
          <SImage avatar={dasboardImg} pclass={styles.iconImg}/>Dashboard
        </Link>
        <Link onClick={handleNavClick} className={location === "/dashboard/buyer/profile" ? 'nav-link active' : 'nav-link'} to="/dashboard/buyer/profile">
          <SImage avatar={proImg} pclass={styles.iconImg}/>My profile
        </Link>
        <Link onClick={handleNavClick} className={location === "/dashboard/buyer/live-auctions" ? 'nav-link active' : 'nav-link'} to="/dashboard/buyer/live-auctions">
          <SImage avatar={liveImg} pclass={styles.iconImg}/>Live auctions
        </Link>
        
        {/* Dropdown Toggle */}
        <Link className='nav-link' to="#" onClick={handleDropdownToggle}>
          <SImage avatar={closeImg} pclass={styles.iconImg}/>Closed auctions <SImage avatar={downImg} pclass={styles.iconImg2}/>
        </Link>

        {/* Dropdown Links */}
        {showDropdown && (
          <div style={{ paddingLeft: '20px' }}>
            <Link onClick={handleNavClick} className={location === "/dashboard/buyer/won-auctions" ? 'nav-link active' : 'nav-link'} to="/dashboard/buyer/won-auctions">
              Won auction
            </Link>
            <Link onClick={handleNavClick} className={location === "/dashboard/buyer/lost-auctions" ? 'nav-link active' : 'nav-link'} to="/dashboard/buyer/lost-auctions">
              Lost auctions
            </Link>
            <Link onClick={handleNavClick} className={location === "/dashboard/buyer/pending-awards" ? 'nav-link active' : 'nav-link'} to="/dashboard/buyer/pending-awards">
              Pending awards
            </Link>
          </div>
        )}

        <Link onClick={handleNavClick} className={location === "/dashboard/buyer/cancelled-auctions" ? 'nav-link active' : 'nav-link'} to="/dashboard/buyer/cancelled-auctions">
          <SImage avatar={cancelImg} pclass={styles.iconImg}/>Cancelled auctions
        </Link>
      </Nav>
    </div>
  );
};

export default DashboardSidebar;

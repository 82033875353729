import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    background: {
      paper: "#FFF",
    },
    primary: {
      main: "#1F2A37",
    },
    secondary: {
      main: "#FD841F",
    },
    salmon: {
      main: "#FA8072",
    },
    error: {
      main: "#ff0000",
    },
    black: {
      main: "#000000",
    },
    gray: {
      main: "#B0B0B0",
    },
  },
});

export default theme;

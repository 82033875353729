import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../../services/apis";

export function* handleSaveAuctionDetails({ payload }) {
  try {
    const res = yield call(API.saveDraftAuctionDetails, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.saveAuctionDetailsSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveAuctionDetailsFailure(err?.response?.data));
    console.log(err);
  }
}

export function* handleSaveAuctionImages({ payload }) {
  try {
    const images = [];
    if(payload.data.files.length > 0){
      for (const file of payload.data.files) {
        //These next lines are needed for uploading pdf/zip files
        //console.log(payload.data.files);
        //const formData = new FormData();
        //formData.append("file", file);
        //var nfile = formData.get("file");

        const urlRes = yield call(API.getFileUrl, {
          fileName: file.name,
        });
        const url = JSON.parse(urlRes.data).data;

        const fileUploadRes = yield call(API.uploadFile, { data: file, url });
        if (fileUploadRes.status !== 200) {
          throw new Error("Error uploading File!");
        }
        const fileUploadedUrl = new URL(url);
        const pathName = fileUploadedUrl?.pathname.slice(1);
        images.push({
          imageUrl: pathName,
        });
      }
    }

    const res = yield call(API.saveImageDetailsForAuction, {
      id: payload.data.id,
      create: false,
      images,
    });
    let data = JSON.parse(res.data);
    yield put(actions.saveAuctionImagesSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveAuctionImagesFailure(err?.response?.data));
    console.log(err);
  }
}

function* uploadFile(fieldname, filePath) {
  var fd = new FormData();
  fd.append(fieldname, filePath);
  var file = fd.get(fieldname);

  const urlRes = yield call(API.getFileUrl, {
    fileName: file.name,
  });

  const url = JSON.parse(urlRes.data).data;
  const fileUploadRes = yield call(API.uploadFile, { data: fd, url });
  if (fileUploadRes.status !== 200) {
    throw new Error("Error uploading File!");
  }
  const fileUploadedUrl = new URL(url);
  const pathName = fileUploadedUrl?.pathname.slice(1);
  return pathName;
}

export function* handleSaveAuctionCollectionDetails({ payload }) {
  try {
    if (payload.data.type === "cultivated") {
      let reportPatwari='';
      if(payload.data.reportPatwari){
      reportPatwari = yield call(
        uploadFile,
        "patwariFile",
        payload.data.reportPatwari
      );}
      let additionalDocument='';
      if(payload.data.additionalDocument){
      additionalDocument = yield call(
        uploadFile,
        "adocFile",
        payload.data.additionalDocument
      );}
      let legalDoc = "";
      if (payload.data.ret && payload.data.legalDoc) {
        legalDoc = yield call(
          uploadFile,
          "legalFile",
          payload.data.legalDoc
        );
      }
      const res = yield call(API.saveCollectionMethodDetails, {
        id: payload.data.id,
        tehsil: payload.data.tehsil,
        village: payload.data.village,
        district: payload.data.district,
        state: payload.data.state,
        ret: payload.data.ret,
        type: payload.data.type,
        reportPatwari: reportPatwari,
        additionalDocument: additionalDocument,
        legalDoc: legalDoc,
      });
      let data = JSON.parse(res.data);
      yield put(actions.saveAuctionCollectionDetailsSuccess(data));
    } else {
      let forestPermission = "";
      if (payload.data.forestPermission) {
        forestPermission = yield call(
          uploadFile,
          "forestPermission",
          payload.data.forestPermission
        );
      }
      let fdr = "";
      if (payload.data.fdr) {
        fdr = yield call(
          uploadFile,
          "fdr",
          payload.data.fdr
        );
      }
      let bmc = "";
      if (payload.data.bmc) {
        bmc = yield call(
          uploadFile,
          "bmc",
          payload.data.bmc
        );
      }
      let panchayatPermit = "";
      if (payload.data.panchayatPermit) {
        panchayatPermit = yield call(
          uploadFile,
          "panchayat",
          payload.data.panchayatPermit
        );
      }
      let additionalDocument='';
      if(payload.data.additionalDocument){
      additionalDocument = yield call(
        uploadFile,
        "adocFile",
        payload.data.additionalDocument
      );}
      let legalDoc = "";
      if (payload.data.ret && payload.data.legalDoc) {
        legalDoc = yield call(
          uploadFile,
          "legalFile",
          payload.data.legalDoc
        );
      }
      const res = yield call(API.saveCollectionMethodDetails, {
        id: payload.data.id,
        ret: payload.data.ret,
        type: payload.data.type,
        forestPermission: forestPermission,
        fdr: fdr,
        bmc: bmc,
        panchayatPermit: panchayatPermit,
        additionalDocument: additionalDocument,
        legalDoc: legalDoc,
      });
      let data = JSON.parse(res.data);
      yield put(actions.saveAuctionCollectionDetailsSuccess(data));
    
    }

    // const res = yield call(API.saveCollectionMethodDetails, payload.data);
    // let data = JSON.parse(res.data);
    // yield put(actions.saveAuctionCollectionDetailsSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveAuctionCollectionDetailsFailure(err?.response?.data));
  }
}

export function* handleSaveQualityDetails({ payload }) {
  try {
    let labReport = "";
    if (payload.data.labReport) {
      labReport = yield call(
        uploadFile,
        "labReport",
        payload.data.labReport
      );
    }
    const res = yield call(API.saveQualityDetails, {
      id: payload.data.id,
      labReport: labReport,
      testLabName: payload.data.testLabName,
      testLabDate: payload.data.testLabDate,
      qualityData: payload.data.qualityData
    });
    let data = JSON.parse(res.data);
    yield put(actions.saveQualityDetailsSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveQualityDetailsFailure(err?.response?.data));
  }
}

export function* handleSaveTraceabilityDetails({ payload }) {
  try {
    const res = yield call(API.saveTraceabilityDetails, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.saveTracebilityDetailsSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.saveTracebilityDetailsFailure(err?.response?.data));
  }
}

export function* handleRemoveImage({ payload }) {
  try {
    const res = yield call(API.removeDraftAuctionImage, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.removeAuctionImageSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.removeAuctionImageFailure(err?.response?.data));
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* auctionCreationSaga() {
  yield takeLatest(actions.saveAuctionDetails.type, handleSaveAuctionDetails);
  yield takeLatest(actions.saveAuctionDetails.type, handleSaveAuctionDetails);
  yield takeLatest(actions.saveAuctionImages.type, handleSaveAuctionImages);
  yield takeLatest(actions.removeAuctionImage.type, handleRemoveImage);
  yield takeLatest(
    actions.saveAuctionCollectionDetails.type,
    handleSaveAuctionCollectionDetails
  );
  yield takeLatest(actions.saveQualityDetails.type, handleSaveQualityDetails);
  yield takeLatest(
    actions.saveTraceabilityDetails.type,
    handleSaveTraceabilityDetails
  );
  yield takeLatest(actions.fetchCreateAuctionDetails.type, fetchAuctionDetailsSaga);
}

function* fetchAuctionDetailsSaga({ payload }) {
  try {
    
    const res = yield call(API.fetchUserAuctionDataDetail, payload.id);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject; // Call the API function to fetch profile details
    yield put(actions.fetchCreateDetailsSuccess(data)); // Dispatch the success action with the fetched data
  } catch (error) {
    yield put(actions.fetchCreateDetailsFailure(error?.response?.data)); // Dispatch the failure action with the error message
  }
}

import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../../services/apis";



export function* handleFetchAuctionSellerDetail({ payload }) {
  
  try {
    const res = yield call(API.fetchProfileDetails, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.SellerInformationSuccess(data.document));
  } catch (err) {
    yield put(actions.SellerInformationFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* auctionSellerInfoamtionSaga() {
  yield takeLatest(actions.fetchSellerInformation.type, handleFetchAuctionSellerDetail);
  
}

import React from "react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

export default function AboutMedicinalFirst() {
  return (
    <div className={styles.main}>
      <p>
        The Indian Medicinal Plants Database has been developed by the
        Foundation for Revitalisation of Local Health Traditions (FRLHT). This
        database incorporates 7263 botanical names, more than 1,50,000
        vernacular names in ten different Indian languages & more than 5000
        authentic images of Indian medicinal plants duly linked to the specific
        botanical entities.
      </p>
      <p>
        The medicinal plant species included in the database have been compiled
        and arranged under the six Indian Systems of Medicine namely
      </p>
      <ul>
        <li>Ayurveda (2559 botanical names/ 1540 species)</li>
        <li>Siddha (2267 botanical names / 1149 species)</li>
        <li> Unani (1049 botanical names / 493 species)</li>
        <li>Homeopathy (460 botanical names / 372 species)</li>
        <li>Sowa-Rigpa (671 botanical names / 250 species)</li>
        <li>Folk (6403 botanical names / 5376 species)</li>
      </ul>
      <p>
        Within each system of medicine one can browse the data for a specific
        botanical name or vernacular name. Each of the botanical names has
        proper author citation and linkage with botanical synonyms where ever
        applicable. Validation of specific botanical names has also been
        incorporated to establish the proper identify of the plant entity. In
        respect of each botanical entity enlisted under any of the codified
        systems of Indian medicine, the relevant Bibliography, establishing its
        inclusion under that system, has also been incorporated.
      </p>
      <p>
        Similarly the bibliography relating to the geographical distribution of
        specific plant species as compiled from the published literature, has
        also been included along with the facility to view the summarized
        comments relating to distribution.
      </p>
      <p>
        Visit{" "}
        <Link
          to="http://www.medicinalplants.in/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </Link>{" "}
        to know more.
      </p>
    </div>
  );
}

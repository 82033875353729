import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchInReviewAuctions({ payload }) {
  try {
    const res = yield call(API.fetchSellerInReviewAuctionsData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { data } = parsedObject;
    yield put(actions.fetchInReviewAuctionsSuccess(data));
  } catch (err) {
    yield put(actions.fetchInReviewAuctionsFailure(err?.response?.data));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* labListingSaga() {
  yield takeLatest(actions.fetchInReviewAuctions.type, handleFetchInReviewAuctions);
  
}

import React from "react";
import Heading from "../../../components/shared/Heading";
import { Col, Row, Form } from "react-bootstrap";
import ProfileDetail from "../../../components/dashboardApp/ProfileDetail";

const MyProfile=() => {
    return (
        <>
            <Row>
                <Col lg={12}>
                    <Heading text="My Profile" />
                </Col>
            </Row>
            <ProfileDetail role="Seller" />
        </>
    );
};

export default MyProfile;
import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { formattedDateForTrace } from "../../../../utils/helper";

const Supplier = ({ listHeading, listData, auctionDetail }) => {
  
  return (
    <>
      <Row>
        <Col lg={6}>
          <span className={styles.heading}>Quality details</span>
        </Col>
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <Col lg={6} className={styles.colStyle}>
          <Row className={styles.rowStyle}>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.headingText}>Lab name</span>
            </Col>
            <Col
              lg={6}
              className={[styles.paddingCol, styles.borderbtm].join(" ")}
            >
              <span className={styles.valueText}>
                {" "}
                {auctionDetail ? auctionDetail.testLabName : "--"}
              </span>
            </Col>
            <Col lg={6} className={styles.paddingCol}>
              <span className={styles.headingText}>Testing date</span>
            </Col>
            <Col lg={6} className={styles.paddingCol}>
              <span className={styles.valueText}>
                {auctionDetail ? formattedDateForTrace(auctionDetail.testDate) : "--"}
              </span>
            </Col>
            <Col lg={6} className={styles.paddingCol}>
              <span className={styles.headingText}>Lab Report</span>
            </Col>
            <Col lg={6} className={styles.paddingCol}>
              {auctionDetail && auctionDetail.labReport ? (
                <Link
                  to={auctionDetail.labReport}
                  target="_blank"
                  style={{ color: "#38a169" }}
                >
                  <span className={styles.valueText}></span>Download report
                </Link>
              ) : (
                <span className={styles.valueText}>No file found</span>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "2em" }}>
        {/* <Col lg={6} className={styles.colStyle}>
                    <Row className={styles.rowStyle}>   
                    {listHeading.map((auction, index) => (   
                        <>                    
                            <Col lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.pheadingtext}>{auction.parameter}</span></Col><Col lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.pheadingtext}>{auction.value}</span></Col>
                        </> 
                    ))}
                    {listData.map((auction, index) => (
                        <>
                            <Col lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.headingText}>{auction.parameter}</span></Col><Col lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.valueText}>{auction.value}</span></Col>
                        </>
                    ))}
                    </Row>
                </Col> */}
        <Col lg={6} className={styles.colStyle}>
          <Row className={styles.rowStyle}>
            {listHeading.map((auction, index) => (
              <>
                <Col
                  lg={6}
                  className={[styles.paddingCol, styles.borderbtm].join(" ")}
                >
                  <span className={styles.pheadingtext}>
                    {auction.parameter}
                  </span>
                </Col>
                <Col
                  lg={6}
                  className={[styles.paddingCol, styles.borderbtm].join(" ")}
                >
                  <span className={styles.pheadingtext}>{auction.value}</span>
                </Col>
              </>
            ))}
            {auctionDetail &&
              auctionDetail.qualityResult.map((auction, index) => (
                <>
                  <Col
                    lg={6}
                    className={[styles.paddingCol, styles.borderbtm].join(" ")}
                  >
                    <span className={styles.headingText}>
                      {auction.parameter}
                    </span>
                  </Col>
                  <Col
                    lg={6}
                    className={[styles.paddingCol, styles.borderbtm].join(" ")}
                  >
                    <span className={styles.headingText}>{auction.value}</span>
                  </Col>
                </>
              ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Supplier;

import React from "react";
import styles from "./styles.module.css";
import ViewAllIcon from "../../../../assets/icons/viewAll.svg";

export default function ButtonViewAll() {
  return (
    
    <button className={styles.button}>
      <span>View all</span> <img src={ViewAllIcon} alt="View All Icon" />
    </button>
  );
}

import React, { useState } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./customTable.module.css";
import { formattedDate } from "../../../../utils/helper";
import editIcon from "../../../../../assets/icons/edit_icon.svg";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";


const UsersCustomTable = ({
  heading,
  filteredData,
  bids,
  onRowClick,
  type,
  dropDownValue,
  handleLinkDelete=() => {},
}) => {
  
  return (
    <>
     <Table responsive>
        <thead style={{ backgroundColor: "#F7FAFC" }}>
          {type === "links"
            ? heading.map((headings, index) => (
                <tr key={index}>
                  <td className={styles.headT}>{headings.linkName}</td>
                  <td className={styles.headT}>{headings.type}</td>
                  <td className={styles.headT}>{headings.status}</td>
                  <td className={styles.headT}>{}</td>
                </tr>
              ))
            : type === "supplier"
            ? heading.map((headings, index) => (
                <tr key={index}>
                  <td className={styles.headT}>{headings.supplierName}</td>
                  <td className={styles.headT}>{headings.phoneNumber}</td>

                  <td className={styles.headT}>{headings.regDate}</td>
                  {/* {dropDownValue === "Approved" ? (
                    <td className={styles.headT}>{headings.noOfAuctions}</td>
                  ) : (
                    <td className={styles.headT}>
                      {headings.participatedAuctions}
                    </td>
                  )} */}
                  <td className={styles.headT}>{headings.status}</td>
                </tr>
              ))
            : type === "buyers" 
            ?  heading.map((headings, index) => (
                <tr key={index}>
                  <td className={styles.headT}>{headings.buyerName}</td>
                  <td className={styles.headT}>{headings.phoneNumber}</td>
                  <td className={styles.headT}>{headings.regDate}</td>
                  {/* {dropDownValue === "Approved" ? (
                    <td className={styles.headT}>{headings.noOfAuctions}</td>
                  ) : (
                    <td className={styles.headT}>
                      {headings.participatedAuctions}
                    </td>
                  )} */}
                  <td className={styles.headT}>{headings.status}</td>
                </tr>
              ))
              : type === "mandi" &&
      heading.map((headings, index) => (
        <tr key={index}>
          {/* <td className={styles.headT}>{headings.Sno}</td>s */}
          <td className={styles.headT}>{headings.BotanicalName}</td>
          <td className={styles.headT}>{headings.TradeName}</td>
          <td className={styles.headT}>{headings.Parts}</td>
          <td className={styles.headT}>{headings.Amritsar}</td>
          <td className={styles.headT}>{headings.Delhi}</td>
          <td className={styles.headT}>{headings.Saharanpur}</td>
          <td className={styles.headT}></td>
        </tr>
      ))}
        </thead>
        <tbody>
  {type === "links"
    ? filteredData.map((data, index) => (
        <tr key={index} style={{ cursor: "pointer" }}>
          <td className={styles.dataT}>{data.linkName}</td>
          <td className={styles.dataT}>
            {data.type === "whatsNew" ? "Whats New" : "Related Links"}
          </td>
          <td className={styles.dataT}>
            <span>{data.status ? "Active" : "Inactive"}</span>
          </td>
          <td className={styles.dataT}>
            <img
              src={editIcon}
              alt="editIcon"
              style={{ cursor: "pointer", width: "24px" }}
              onClick={() => onRowClick(data.linkId)}
            />
            <img
              src={deleteIcon}
              alt="deleteIcon"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => handleLinkDelete(data.linkId)}
            />
          </td>
        </tr>
      ))
    : type === "supplier"
    ? filteredData.map((data, index) => (
        <tr
          key={index}
          onClick={() => onRowClick(data.supplierID)}
          style={{ cursor: "pointer" }}
        >
          <td className={styles.dataT}>{data.supplierName}</td>
          <td className={styles.dataT}>{data.supplierNumber}</td>
          <td className={styles.dataT}>
            <span>{formattedDate(data.regDate)}</span>
          </td>
          <td className={styles.dataT}>
            <span>{data.status}</span>
          </td>
        </tr>
      ))
    : type === "buyers"
    ? filteredData.map((data, index) => (
        <tr
          key={index}
          onClick={() => onRowClick(data.buyerID)}
          style={{ cursor: "pointer" }}
        >
          <td className={styles.dataT}>{data.buyerName}</td>
          <td className={styles.dataT}>{data.buyerNumber}</td>
          <td className={styles.dataT}>
            <span>{formattedDate(data.regDate)}</span>
          </td>
          <td className={styles.dataT}>
            <span>{data.status}</span>
          </td>
        </tr>
      ))
    : type == "mandi" &&
      filteredData?.map((data, index) => (
        <tr key={index} style={{ cursor: "pointer" }}>
          {/* <td className={styles.dataT}>{index+1}</td> */}
          <td className={styles.dataT}>{data?.botanicalName}</td>
          <td className={styles.dataT}>{data?.tradeName}</td>
          <td className={styles.dataT}>{data?.parts.join(', ')}</td>
          <td className={styles.dataT}>{data?.amritsar}</td>
          <td className={styles.dataT}>{data?.delhi}</td>
          <td className={styles.dataT}>{data?.saharanpur}</td>
          <td className={styles.dataT}  style={{
            minWidth:"120px",
            overflow:"auto",
            textAlign:"right",
          }}>
            <img
              src={editIcon}
              alt="editIcon"
              style={{ cursor: "pointer", width: "24px" }}
              onClick={() => onRowClick(data?.mandiId)}
              // onClick={() => onRowClick(data?.BotanicalName)}
            />
            <img
              src={deleteIcon}
              alt="deleteIcon"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => handleLinkDelete(data?.mandiId)}
              // onClick={() => handleLinkDelete(data?.botanicalName)}
            />
          </td>
        </tr>
      ))}
</tbody>

      </Table> 
    </>
  );
};

export default UsersCustomTable;

  // <tbody> old
  //         {type === "links"
  //           ? filteredData.map((data, index) => (
  //               <tr key={index} style={{ cursor: "pointer" }}>
  //                 <td className={styles.dataT}>{data.linkName}</td>
  //                 <td className={styles.dataT}>
  //                   {data.type == "whatsNew" ? "Whats New" : "Related Links"}
  //                 </td>
  //                 <td className={styles.dataT}>
  //                   <span>{data.status ? "Active" : "Inactive"}</span>
  //                 </td>
  //                 <td className={styles.dataT}>
  //                   <span
  //                     onClick={() => {
  //                       onRowClick(data.linkId);
  //                     }}
  //                     className="w-2 mx-2"
  //                     style={{
                      
  //                     }}
  //                   >
  //                     <img
  //                       src={editIcon}
  //                       alt="editIcon"
  //                       style={{ cursor: "pointer",   width: "24px", }}
  //                     />
  //                   </span>
  //                   <span
  //                     onClick={() => {
  //                       handleLinkDelete(data.linkId);
  //                     }}
  //                     className=" mx-2"
  //                   >
  //                     <img
  //                       src={deleteIcon}
  //                       alt="deleteIcon"
  //                       style={{ cursor: "pointer" }}
  //                     />
  //                   </span>
  //                 </td>
  //               </tr>
  //             ))   
  //           : type === "supplier"       
  //           ? filteredData.map((data, index) => (
  //               <tr
  //                 key={index}
  //                 onClick={() => {
  //                   onRowClick(data.supplierID);
  //                 }}
  //                 style={{ cursor: "pointer" }}
  //               >
  //                 <td className={styles.dataT}>{data.supplierName}</td>
  //                 <td className={styles.dataT}>{data.supplierNumber}</td>
  //                 <td className={styles.dataT}>
  //                   <span>{formattedDate(data.regDate)}</span>
  //                 </td>
  //                 {/* {dropDownValue === "Approved" ? (
  //                   <td className={styles.dataT}>
  //                     <span>{data.noOfAuctions}</span>
  //                   </td>
  //                 ) : (
  //                   <td className={styles.dataT}>
  //                     <span>{data.participatedAuctions}</span>
  //                   </td>
  //                 )} */}
  //                 <td className={styles.dataT}>
  //                   <span>{data.status}</span>
  //                 </td>
  //               </tr>
  //             ))
  //           : type === "buyers" &&
  //             filteredData.map((data, index) => (
  //               <tr
  //                 key={index}
  //                 onClick={() => {
  //                   onRowClick(data.buyerID);
  //                 }}
  //                 style={{ cursor: "pointer" }}
  //               >
  //                 <td className={styles.dataT}>
  //                   <span>{data.buyerName}</span>
  //                 </td>
  //                 <td className={styles.dataT}>
  //                   <span>{data.buyerNumber}</span>
  //                 </td>
  //                 <td className={styles.dataT}>
  //                   <span>{formattedDate(data.regDate)}</span>
  //                 </td>
  //                 {/* {dropDownValue === "Approved" ? (
  //                   <td className={styles.dataT}>
  //                     <span>{data.noOfAuctions}</span>
  //                   </td>
  //                 ) : (
  //                   <td className={styles.dataT}>
  //                     <span>{data.participatedAuctions}</span>
  //                   </td>
  //                 )} */}
  //                 <td className={styles.dataT}>
  //                   <span>{data.status}</span>
  //                 </td>
  //               </tr>
  //             ))
  //             }
  //       </tbody>
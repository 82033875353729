import React from "react";
import styles from "./heading.module.css";

const Heading= (props) => {
    return (
        <>
            <h1 className={styles.textHeading}>{props.text}</h1>
        </>
    );
};

export default Heading;
import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../services/apis";
import { toastSuccess } from "../../utils/toastHelper";



export function* handleFetchHederaAuction({ payload }) {
  try {
    const res = yield call(API.fetchHederaAuctionDataDetail, payload); 
    const parsedObject = JSON.parse(res.data);   
    const { data } = parsedObject;
    yield put(actions.fetchHederaAuctionSuccess(data));
    toastSuccess('Fetch Auction from Hedera successfull.');
  } catch (err) {
    yield put(actions.fetchHederaAuctionFailure(err?.response?.data));
  }
}

export function* handleFetchHederaHistoryAuction({ payload }) {
  try {
    const res = yield call(API.fetchHederaHistoryAuctionDataDetail, payload); 
    const parsedObject = JSON.parse(res.data);   
    const { data } = parsedObject;
    yield put(actions.fetchHederaHistoryAuctionSuccess(data));    
  } catch (err) {
    yield put(actions.fetchHederaHistoryAuctionFailure(err?.response?.data));
  }
}

export function* handleFetchHederaBids({ payload }) {
  try {
    const res = yield call(API.fetchHederaAuctionBidData, payload);      
    const parsedObject = JSON.parse(res.data);        
    const { data } = parsedObject;    
    yield put(actions.fetchHederaAuctionBidsSuccess(data));
    toastSuccess('Fetch Bids from Hedera successfull.');
  } catch (err) {
    yield put(actions.fetchHederaAuctionBidsFailure(err?.response?.data));
  }
}

export function* handleFetchHederaHistoryBids({ payload }) {
  try {
    const res = yield call(API.fetchHederaHistoryAuctionBidData, payload);      
    const parsedObject = JSON.parse(res.data);        
    const { data } = parsedObject;    
    yield put(actions.fetchHederaHistoryAuctionBidsSuccess(data));
  } catch (err) {
    yield put(actions.fetchHederaHistoryAuctionBidsFailure(err?.response?.data));
  }
}



/**
 * Root saga manages watcher lifecycle
 */
export function* hederaDataSaga() {
  yield takeLatest(actions.fetchHederaAuction.type, handleFetchHederaAuction);
  yield takeLatest(actions.fetchHederaHistoryAuction.type, handleFetchHederaHistoryAuction);  
  yield takeLatest(actions.fetchHederaAuctionBids.type, handleFetchHederaBids);
  yield takeLatest(actions.fetchHederaHistoryAuctionBids.type, handleFetchHederaHistoryBids);
}

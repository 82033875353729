import React from "react";
import styles from "./styles.module.css";
import hederaLogo from "../../../../assets/icons/Hedera logo.svg";
import backIcon from "../../../../assets/icons/backward.svg";
import { formattedDate, formattedDateForTrace } from "../../../utils/helper";

export default function TraceabilityDetails({close,auction}) {
  let data = auction.auctionDetail.trakingDetails;
  console.log({'qualityResultdata':data});
  return (
    <div className={styles.traceabilityDetails}>
      <div className={styles.header}>
        <div className={styles.left}>
          <button onClick={close}>
            <img src={backIcon} style={{ height: "16px" }} alt="go back" />
          </button>
          <h2>Traceability</h2>
        </div>
        <div className={styles.right}>
          <img src={hederaLogo} alt="Hedera Logo" />
          <div className={styles.textContainer}>
            <span className={styles.lightText}>Powered by</span>
            <span className={styles.darkText}>Hedera Blockchain</span>
          </div>
        </div>
      </div>
      <main className={styles.mainContent}>
        <section className={styles.data}>
            <div className={styles.dataLeft}>
            { data.map((q)=>{
                  return <span key={q._id} >{q.detail}</span>
                })}
                {/* <span>Approx date of sowing</span>
                <span>Approx date of sowing</span>
                <span>Approx date of sowing</span>
                <span>Approx date of sowing</span>
                <span>Approx date of sowing</span>
                <span>Approx date of sowing</span> */}
            </div>
            <div className={styles.dataRight}>
            { data.map((q)=>{
                  return <span key={q._id} >{formattedDateForTrace(q.date)}</span>
                })}
                {/* <span>02 Jan 2023</span>
                <span>02 Jan 2023</span>
                <span>02 Jan 2023</span>
                <span>02 Jan 2023</span>
                <span>02 Jan 2023</span>
                <span>02 Jan 2023</span> */}
            </div>
        </section>
      </main>
    </div>
  );
}

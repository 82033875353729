import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  countries: null,
  states: null,
  cities: null,
};

const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    fetchCountries(state, action) {
      state.loading = true;
    },
    fetchCountriesSuccess(state, action) {
      state.loading = false;
      state.countries = action.payload;
    },
    fetchCountriesFailure(state, action) {
      state.loading = false;
    },
    fetchStates(state, action) {
      state.loading = true;
    },
    fetchStatesSuccess(state, action) {
      state.loading = false;
      state.states = action.payload;
    },
    fetchStatesFailure(state, action) {
      state.loading = false;
    },
    fetchCities(state, action) {
      state.loading = true;
    },
    fetchCitiesSuccess(state, action) {
      state.loading = false;
      state.cities = action.payload;
    },
    fetchCitiesFailure(state, action) {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = regionSlice;
